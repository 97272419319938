export enum LicenseType {
  ESG_FREE_LICENSE = "ESG_FREE_LICENSE",
  ESG_SMALL_LICENSE = "ESG_SMALL_LICENSE",
  ESG_MEDIUM_LICENSE = "ESG_MEDIUM_LICENSE",
  ESG_LARGE_LICENSE = "ESG_LARGE_LICENSE",
  LKSG_LICENSE = "LKSG_LICENSE",
  CSRD_LICENSE = "CSRD_LICENSE",
  CSRD_FREEMIUM_LICENSE = "CSRD_FREEMIUM_LICENSE",
  CCF_LICENSE = "CCF_LICENSE",
  DFL_LICENSE = "DFL_LICENSE",
}

export function supportsDateRange(type: LicenseType): boolean {
  return [
    LicenseType.CCF_LICENSE,
    LicenseType.LKSG_LICENSE,
    LicenseType.CSRD_LICENSE,
    LicenseType.CSRD_FREEMIUM_LICENSE,
    LicenseType.DFL_LICENSE,
  ].includes(type);
}

export interface License {
  type: LicenseType;
  validUntil: number;
}
