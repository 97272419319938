/* eslint-disable */
// prettier-ignore

export const messages = {
  sco2pes_checklist_company_codes: "Códigos de empresa",
  sco2pes_checklist_company_codes_create: "Crear códigos de empresa",
  sco2pes_checklist_company_codes_update: "(A partir del 2º año) Actualizar códigos de empresa si se han añadido nuevas unidades de negocio",
  sco2pes_checklist_suppliers_add_new: "Añadir nuevos proveedores usando la plantilla de carga",
  sco2pes_checklist_suppliers: "Proveedores",
  sco2pes_checklist_suppliers_review: "(A partir del 2º año) Revisar proveedores existentes y actualizar si es necesario",
  sco2pes_checklist_financial_models: "Modelos financieros",
  sco2pes_checklist_financial_models_create: "Si es necesario, crear un modelo financiero",
  sco2pes_checklist_financial_models_lock: "(A partir del 2º año) Bloquear la versión actual y crear una nueva",
  sco2pes_checklist_financial_models_adjust: "(A partir del 2º año, si es necesario) Ajustar porcentajes/participaciones",
  sco2pes_checklist_co2_prices_check: "Verificar precios de tipos de CO2:",
  sco2pes_checklist_co2_prices_managed: "Si está gestionado: una nueva versión se actualiza en segundo plano por GC",
  sco2pes_checklist_co2_prices_unmanaged: "Si no está gestionado: los precios unitarios pueden necesitar ajuste a partir del 2º año",
  sco2pes_checklist_supply_chain_link: "Si es necesario, vincular proveedores y rutas de transporte en cadenas de suministro",
  sco2pes_checklist_co2_database: "Base de datos de CO<sub>2</sub>",
  sco2pes_checklist_co2_database_add: 'Si es necesario, añadir factores de emisión personalizados en "TIPOS DE CO2 PROPIOS" mediante entrada manual',
  sco2pes_checklist_co2_database_lock: '(A partir del 2º año) Si es necesario, bloquear la versión actual y añadir una nueva versión de factores de emisión personalizados en "TIPOS DE CO2 PROPIOS"',
  sco2pes_checklist_unit_bookings: "Reservas de unidades",
  sco2pes_checklist_unit_bookings_create: "Crear y cargar reservas de unidades usando la plantilla de carga",
  sco2pes_checklist_financial_bookings: "Reservas financieras",
  sco2pes_checklist_financial_bookings_options: "Hay dos opciones para crear reservas financieras en el software:",
  sco2pes_checklist_financial_bookings_financial_models: 'En la sección "Modelos financieros"',
  sco2pes_checklist_financial_bookings_financial_bookings: "En Reservas > Reservas financieras",
  sco2pes_checklist_commuter_surveys: "Encuestas de desplazamientos",
  sco2pes_checklist_commuter_surveys_design: "Diseñar y lanzar encuestas para los códigos de empresa deseados",
  sco2pes_checklist_commuter_surveys_check: "Verificar advertencias y excluir entradas si es necesario",
  sco2pes_checklist_commuter_surveys_close: "Cerrar las encuestas para cada código de empresa",
  sco2pes_checklist_reports: "Informes",
  sco2pes_checklist_reports_plausibility_check: "Verificación de plausibilidad: Revisar todas las reservas en la sección de reservas del software para asegurar que el informe puede completarse",
  sco2pes_checklist_reports_finalize: "Finalizar el informe de emisiones en Excel (bloquear todas las reservas)",
  sco2pes_checklist_reports_submit_data: "Si la elaboración de informes está acordada contractualmente, enviar datos específicos de la empresa para evaluación de KPI del año de informe mediante ticket de soporte:",
  sco2pes_checklist_reports_total_employee_count: "Número total de empleados (plantilla) para la entidad del informe",
  sco2pes_checklist_reports_total_office_space: "Superficie total de oficinas (m²)",
  sco2pes_checklist_reports_total_revenue: "Ingresos totales del año del informe, incluyendo la moneda",
  sco2pes_checklist_reports_submit_company_description: "Si la elaboración de informes está acordada contractualmente, enviar la descripción de la empresa y cualquier medida de reducción para comparación interanual mediante ticket de soporte",
};
