/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_tag_definitions: "Definiciones de Etiquetas",
  system_admin_tag_definition: "Definición de Etiqueta",
  system_admin_tag_definition_all_except: "Todo excepto",
  system_admin_tag_definition_only_for: "Solo para",
  system_admin_tag_definition_deleted: "Definición de Etiqueta eliminada con éxito",
  system_admin_tag_definition_deletion_confirmation: "¿Está seguro de que desea eliminar esta definición de etiqueta permanentemente?",
  system_admin_tag_definition_info: "Información de la Definición de Etiqueta",
  system_admin_tag_definition_name: "Nombre de la Definición de Etiqueta",
  system_admin_tag_definition_entities: "Entidades",
  system_admin_tag_definition_for_entities: "Para Entidades",
  system_admin_tag_definition_for_companies: "Para Empresas",
  system_admin_tag_definition_validation_regex: "Regex de Validación",
  system_admin_tag_definition_created: "Definición de Etiqueta creada con éxito.",
  system_admin_tag_definition_updated: "Definición de Etiqueta actualizada con éxito.",
  system_admin_tag_definition_selection: "Selección",
  system_admin_tag_definition_managed_description: "Cuando está activado, solo los usuarios de soporte o los administradores del sistema tendrán la capacidad de crear etiquetas. Esta configuración restringe la creación de etiquetas al personal autorizado, asegurando un proceso de etiquetado controlado y gestionado.",
  system_admin_tag_definition_entities_definition: "Este campo le permite seleccionar el tipo de entidad aplicable para la etiqueta.",
  system_admin_tag_definition_companies_definition: "Este campo le permite especificar las empresas para las cuales la etiqueta es aplicable, proporcionando flexibilidad en la definición del alcance de la etiqueta en función de las empresas seleccionadas.",
};
