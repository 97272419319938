/* eslint-disable */
// prettier-ignore

export const messages = {
  sco2pes_checklist_company_codes: "Company Codes",
  sco2pes_checklist_company_codes_create: "Create Company Codes",
  sco2pes_checklist_company_codes_update: "(Starting from the 2nd year onwards) Update company codes if new business units have been added",
  sco2pes_checklist_suppliers_add_new: "Add new suppliers using the upload template",
  sco2pes_checklist_suppliers: "Suppliers",
  sco2pes_checklist_suppliers_review: "(Starting from the 2nd year onwards) Review existing suppliers and update if necessary",
  sco2pes_checklist_financial_models: "Financial Models",
  sco2pes_checklist_financial_models_create: "If needed, create a financial model",
  sco2pes_checklist_financial_models_lock: "(Starting from the 2nd year onwards) Lock the current version and create a new one",
  sco2pes_checklist_financial_models_adjust: "(Starting from the 2nd year onwards, if needed) Adjust percentages/shares",
  sco2pes_checklist_co2_prices_check: "Check CO2 type prices:",
  sco2pes_checklist_co2_prices_managed: "If managed: a new version is updated in the background by GC",
  sco2pes_checklist_co2_prices_unmanaged: "If unmanaged: unit prices may need to be adjusted from the 2nd year onwards",
  sco2pes_checklist_supply_chain_link: "If required, link suppliers and transport routes into supply chains",
  sco2pes_checklist_co2_database: "CO<sub>2</sub> Database",
  sco2pes_checklist_co2_database_add: "If required, add custom emission factors in \"OWN CO2 TYPES\" via manual data entry",
  sco2pes_checklist_co2_database_lock: "(Starting from the 2nd year onwards) If required, lock the current version and add a new version of custom emission factors in \"OWN CO2 TYPES\"",
  sco2pes_checklist_unit_bookings: "Unit Bookings",
  sco2pes_checklist_unit_bookings_create: "Create and upload unit bookings using the upload template",
  sco2pes_checklist_financial_bookings: "Financial Bookings",
  sco2pes_checklist_financial_bookings_options: "There are two options to create financial bookings in the software:",
  sco2pes_checklist_financial_bookings_financial_models: "In the \"Financial Models\" section",
  sco2pes_checklist_financial_bookings_financial_bookings: "Under Bookings > Financial Bookings",
  sco2pes_checklist_commuter_surveys: "Commuter Surveys",
  sco2pes_checklist_commuter_surveys_design: "Design and launch surveys for desired company codes",
  sco2pes_checklist_commuter_surveys_check: "Check for warnings and exclude entries if necessary",
  sco2pes_checklist_commuter_surveys_close: "Close the surveys for each company code",
  sco2pes_checklist_reports: "Reports",
  sco2pes_checklist_reports_plausibility_check: "Plausibility check: Review all bookings in the software's booking section to ensure the report can be completed",
  sco2pes_checklist_reports_finalize: "Finalize the emissions Excel report (lock all bookings)",
  sco2pes_checklist_reports_submit_data: "If reporting is contractually agreed, submit company-specific data for KPI evaluation for the reporting year via a support ticket:",
  sco2pes_checklist_reports_total_employee_count: "Total employee count (headcount) for the reporting entity",
  sco2pes_checklist_reports_total_office_space: "Total office space (m²)",
  sco2pes_checklist_reports_total_revenue: "Total revenue for the reporting year, including the currency",
  sco2pes_checklist_reports_submit_company_description: "If reporting is contractually agreed, submit the company description and any reduction measures for year-over-year comparison via a support ticket"
}
