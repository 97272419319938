export enum ModuleVideo {
  LANDING_PAGE = "LANDING_PAGE",
  COMPANY_CODE = "COMPANY_CODE",
  FINANCIAL_MODEL = "FINANCIAL_MODEL",
  CO2_DATABASE = "CO2_DATABASE",
  SUPPLIER = "SUPPLIER",
  UNIT_BOOKING = "UNIT_BOOKING",
  FINANCIAL_BOOKING = "FINANCIAL_BOOKING",
  COMMUTER_SURVEY = "COMMUTER_SURVEY",
  COMPANY_DASHBOARD = "COMPANY_DASHBOARD",
}
