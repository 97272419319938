/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_user_add_company: "Agregar Empresa",
  system_admin_user_companies: "Empresas",
  system_admin_user_companies_info: "Debe haber al menos una empresa.<br />En modo de edición, la eliminación de empresas aún no es posible.",
  system_admin_user_company_and_roles: "Empresa y Roles",
  system_admin_user_company_name: "Nombre de la Empresa",
  system_admin_user_company_user: "Usuario de la Empresa",
  system_admin_user_auditor: "Auditor",
  system_admin_user_company_users: "Usuarios de la Empresa",
  system_admin_user_create_company_user: "Crear Usuario de la Empresa",
  system_admin_user_create_system_user: "Crear Usuario del Sistema",
  system_admin_user_created: "Creado",
  system_admin_user_deactivated: "Desactivado",
  system_admin_user_completed: "Completado",
  system_admin_user_expired: "Expirado",
  system_admin_user_edit_company_user: "Editar Usuario de la Empresa",
  system_admin_user_edit_system_user: "Editar Usuario del Sistema",
  system_admin_user_english: "Inglés",
  system_admin_user_german: "Alemán",
  system_admin_user_profile_state: "Estado del Perfil",
  system_admin_user_roles: "Conjunto de Roles",
  system_admin_user_company_admin: "Administrador",
  system_admin_user_basic_access: "Básico",
  system_admin_user_ccf_user: "Usuario de GLOBAL FOOTPRINT",
  system_admin_user_esg_user: "Usuario de GLOBAL COMPLIANCE",
  system_admin_user_system_admin: "Administrador del Sistema",
  system_admin_user_system_admin_user: "Usuario Administrador del Sistema",
  system_admin_user_system_roles: "Roles del Sistema",
  system_admin_user_system_user: "Usuario del Sistema",
  system_admin_user_user_data: "Datos del Usuario",
  system_admin_user_registration_link_description: "El usuario puede completar el perfil de usuario en los siguientes enlaces:",
  system_admin_user_registration_link_valid_until: "Válido Hasta",
  system_admin_user_registration_links: "Enlaces de Registro",
  system_admin_user_update_user_warning: "Los usuarios deben volver a iniciar sesión antes de que los cambios en los roles tengan efecto visualmente",
  system_admin_user_deactivate_warning: "El usuario no podrá iniciar sesión si desactiva al usuario",
  system_admin_user_attention: "Atención",
  system_admin_user_email_dialog_title: "Enviar correo electrónico",
  system_admin_user_email_dialog_alert: "Atención: Se enviará un correo electrónico. Por favor, use esta funcionalidad con cuidado para evitar demasiados correos electrónicos.",
};
