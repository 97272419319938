/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_partners_partner_companies: "Empresas Socias",
  customer_partners_connect_with_partners: "Conectar con socios",
  customer_partners_connected: "Conectado",
  customer_partners_pending: "Pendiente",
  customer_partners_receive_invitation: "He <b>recibido</b> un código de invitación de una organización socia.",
  customer_partners_send_invitation: "Quiero <b>invitar</b> a una organización socia enviando un código de invitación.",
  customer_partners_receive_invitation_1: "Para conectar su organización con otra, por favor inserte el código de invitación que recibió por correo electrónico:",
  customer_partners_invitation_code: "Código de Invitación",
  customer_partners_receive_invitation_2: "La organización ha sido añadida a sus organizaciones socias.",
  customer_partners_receive_invitation_3: "Puede añadir un nombre para la organización y agregar más datos aquí:",
  customer_partners_next: "Siguiente",
  customer_partners_partner_information: "Detalles del Socio",
  customer_partners_at_least_one_product_selected: "Debe seleccionarse al menos un producto.",
  customer_partners_company_name: "Nombre de la Empresa",
  customer_partners_product: "Producto",
  customer_partners_organisation_name: "Nombre de la Organización",
  customer_partners_invitation_title: "Proceso de invitación de socios",
  customer_partners_invitation_point_1: "1. Invita a socios mediante la carga de un archivo Excel",
  customer_partners_invitation_point_2: "2. GLOBAL SUITE envía correos electrónicos de invitación a los socios",
  customer_partners_invitation_point_3: "3. Los socios se registran en GLOBAL SUITE (si no están ya registrados) o aceptan la invitación",
  customer_partners_invitation_point_4: "4. Se establece la asociación y puede comenzar a compartir datos",
  customer_partners_received_information: "¿Recibió una invitación?",
  customer_partners_import_partners: "Importar Socios",
  customer_partners_import_partners_description: "Los socios pueden ser importados mediante un archivo Excel. Use esta plantilla de Excel para la carga del archivo:",
  customer_partners_copy_registration_url: "Copiar enlace de registro",
  customer_partners_copied_registration_url: "Enlace de registro copiado",
  customer_partners_unique_id: "ID Único",
  customer_partners_invitation_information: "Información de la Invitación",
  customer_partners_dashboard: "Paneles de Socios",
  customer_partners_upload_success: "Datos importados con éxito",
  customer_partners_search: "Buscar Nombre de Empresa, ID Único, Correo Electrónico, Persona de Contacto",
};
