/* eslint-disable */
// prettier-ignore

export const messages = {
  profile_edit_profile: "Profil bearbeiten",
  profile_change_password: "Passwort Ändern",
  profile_current_password: "Aktuelles Passwort",
  profile_new_password: "Neues Passwort",
  profile_new_password_repeat: "Neues Passwort wiederholen",
  profile_password_successfully_changed: "Das Passwort wurde erfolgreich geändert.",
  account_selection_title: "Kontoauswahl",
  account_selection_desc: "Bitte wählen Sie das Konto aus, mit dem Sie sich anmelden möchten:",
  profile_2fa: "Zwei-Faktor-Authentifizierung",
  profile_activation: "Aktivierung",
  profile_2fa_activation_description: "Die Zwei-Faktor-Authentifizierung (2FA) ist ein Sicherheitsprotokoll, das einen zusätzlichen Schutz für Online-Konten bietet. Anstatt sich ausschließlich auf ein Passwort zu verlassen, erfordert 2FA, dass Benutzer eine zweite Form der Identifikation bereitstellen.",
  profile_2fa_activate: "2FA aktivieren",
  profile_2fa_activated_successfully: "Die Zwei-Faktor-Authentifizierung wurde erfolgreich aktiviert.",
  profile_2fa_disable: "2FA deaktivieren",
  profile_2fa_reset: "2FA zurücksetzen",
  profile_2fa_reset_description: "Das Zurücksetzen Ihrer Zwei-Faktor-Authentifizierung (2FA) ermöglicht es Ihnen, die Sicherheit Ihres Kontos zu stärken, ohne die zusätzliche Schutzschicht zu deaktivieren. Erwägen Sie das Zurücksetzen der 2FA in den folgenden Situationen:",
  profile_2fa_reset_desc_list_item_1: "Verlorenes oder nicht zugängliches Authentifizierungsgerät:",
  profile_2fa_reset_desc_list_item_1_desc: "Wenn das für 2FA verwendete Gerät verloren, gestohlen oder nicht zugänglich ist, gewährleistet das Zurücksetzen, dass nur autorisierte Geräte Zugriff auf Ihr Konto haben.",
  profile_2fa_reset_desc_list_item_2: "Änderung der Authentifizierungs-App:",
  profile_2fa_reset_desc_list_item_2_desc: "Wenn Sie Ihre Authentifizierungs-App geändert haben, setzen Sie 2FA zurück, um die neue Anwendung zu aktualisieren und zu sichern, die mit Ihrem Konto verknüpft ist.",
  profile_2fa_please_check: "Bitte überprüfen Sie Ihren 6-stelligen Code in Ihrer Authenticator-App.",
  profile_2fa_disable_2fa: "Zwei-Faktor-Authentifizierung deaktivieren",
  profile_2fa_reset_2fa: "Zwei-Faktor-Authentifizierung zurücksetzen",
  profile_2fa_reset_2fa_description: "Diese Aktion macht Ihren aktuellen Wiederherstellungscode und Ihr 2FA-Gerät ungültig. Sie werden aufgefordert, die 2FA-Aktivierung von vorne zu beginnen. Sind Sie sicher?",
  profile_2fa_enter_recovery_code: "Bitte geben Sie den Wiederherstellungscode ein, den Sie an einem sicheren Ort aufbewahrt haben.",
  profile_2fa_input_recovery_code: "Wiederherstellungscode eingeben",
  profile_2fa_disable_confirmation: "Diese Aktion kann nicht rückgängig gemacht werden. Sind Sie sicher?",
  profile_2fa_disabled_successfully: "Die Zwei-Faktor-Authentifizierung wurde erfolgreich von Ihrem Profil deaktiviert.",
  profile_2fa_method: "Methode",
  profile_2fa_scan: "Scannen",
  profile_2fa_verify: "Überprüfen",
  profile_2fa_verify_description: "Geben Sie den Einmalcode aus Ihrer Authenticator-App ein.",
  profile_2fa_backup_codes: "Backup-Codes",
  profile_2fa_choose_verification_method: "Wählen Sie die Verifizierungsmethode",
  profile_2fa_choose_verification_method_description: "Bitte wählen Sie die unten stehende Verifizierungsmethode aus. Wenn Sie die App nicht haben, können Sie sie im App Store oder Play Store auf Ihrem Mobilgerät herunterladen.",
  profile_2fa_scan_qr_code: "QR-Code scannen",
  profile_2fa_scan_qr_code_description: "Verwenden Sie Ihr Mobilgerät, um den QR-Code unten mit Ihrer bevorzugten Authentifizierungs-App zu scannen. Dadurch wird ein 6-stelliger Code für die Verifizierung generiert. Falls Sie keine Authentifizierungs-App haben, können Sie eine im App Store Ihres Geräts herunterladen. Dieser zusätzliche Schritt gewährleistet zusätzliche Sicherheit für Ihr Konto. Vielen Dank, dass Sie die Sicherheit Ihrer Informationen priorisieren!",
  profile_2fa_recovery_code: "Wiederherstellungscode",
  profile_2fa_recovery_code_description: "Verwenden Sie diesen Wiederherstellungscode, wenn Sie Ihr Gerät verlieren. Speichern Sie diesen Code oder drucken Sie ihn aus und bewahren Sie ihn an einem sicheren Ort auf. <b>Sie können diesen Wiederherstellungscode nur einmal anzeigen</b>.",
  profile_2fa_recovery_code_check: "Ich habe den Wiederherstellungscode bereits an einem sicheren Ort gespeichert.",
  profile_2fa_recovery_code_copied_success: "Wiederherstellungscode kopiert!",
  profile_2fa_confirm_exit: "Möchten Sie wirklich die Einrichtung der Zwei-Faktor-Authentifizierung verlassen?",
};
