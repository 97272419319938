/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_risk_analyses_configuration: "Configuración",
  system_admin_risk_analyses: "Análisis de Riesgos ESG",
  system_admin_risk_analysis: "Análisis de Riesgo ESG",
  system_admin_risk_analysis_invitation_message: "Mensaje de Invitación",
  system_admin_risk_analyses_create: "Crear Análisis de Riesgo ESG",
  system_admin_risk_analyses_edit: "Editar Análisis de Riesgo ESG",
  system_admin_risk_analyses_evaluation_period: "Período de Evaluación",
  system_admin_risk_analyses_deadline: "Fecha Límite",
  system_admin_risk_assessment_template: "Plantilla de Encuesta (Encuesta ESG)",
  system_admin_risk_assessment_short_template: "Plantilla de Encuesta (Encuesta Corta)",
};
