/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_units_search: "Buscar por nombre / símbolos / descripción",
  system_admin_units_only_global_units: "Solo Unidades Globales",
  system_admin_units_administration: "Administración",
  system_admin_units_display_name: "Nombre para Mostrar",
  system_admin_units_symbol: "Símbolo",
  system_admin_units_symbols: "Símbolos",
  system_admin_units_removable: "Removible",
  system_admin_units_unit_deleted: "Unidad eliminada con éxito",
  system_admin_units_deletion_confirmation: "¿Está seguro de que desea eliminar esta unidad permanentemente?",
  system_admin_units_deletion_disabled_desc: "Esta unidad no se puede eliminar ya que el interruptor 'eliminable' está desactivado.",
  system_admin_units_company_info: "Información de la Empresa",
  system_admin_units_removable_desc: "Si el interruptor 'eliminable' está activado, puede eliminar la unidad. Si está desactivado, no puede eliminar la unidad.",
  system_admin_units_unit_info: "Información de la Unidad",
  system_admin_units_conversion: "Conversión",
  system_admin_units_company_info_must_specified: "La información de la empresa debe especificarse (solo si el interruptor global está desactivado)",
  system_admin_units_unit_name_must_specified: "El nombre de la unidad en ambos idiomas debe especificarse",
  system_admin_units_at_least_symbol_must_specified: "Se debe especificar al menos 1 símbolo",
  system_admin_units_conversion_requirements_are_satisfied: "Se cumplen los requisitos de conversión",
  system_admin_units_unit_name: "Nombre de la Unidad",
  system_admin_units_symbols_description: "Los símbolos no pueden estar vacíos, y la longitud máxima para cada símbolo es de 32 caracteres.",
  system_admin_units_conversion_description: "Esta es una lista de conversiones para esta unidad. Un ejemplo de conversión sería: Si la unidad actual es 'kg' (unidad objetivo) y selecciona 'gr' (unidad fuente), entonces el coeficiente es 1000. Esto significa que 1 kg = 1000 x 1 gr.",
  system_admin_units_conversion_to: "Seleccionar unidad fuente",
  system_admin_units_coefficient: "Coeficiente",
  system_admin_units_conversion_deletion_confirmation: "¿Está seguro de que desea eliminar esta conversión permanentemente?",
  system_admin_units_symbol_deletion_confirmation: "¿Está seguro de que desea eliminar este símbolo permanentemente?",
  system_admin_units_symbol_tooltip_info: "Por favor, ingrese un texto de 1 a 32 caracteres de longitud, que puede contener letras (A-Z, a-z), dígitos (0-9), caracteres especiales (/ . - _ ( ) espacio), y varios caracteres no ASCII de todo el mundo.",
};
