/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_risk_analyses_configuration: "Konfiguration",
  system_admin_risk_analyses: "ESG Risko Analysen",
  system_admin_risk_analysis: "ESG Risko Analyse",
  system_admin_risk_analysis_invitation_message: "Einladungs-Nachricht",
  system_admin_risk_analyses_create: "ESG Risk Analyse erstellen",
  system_admin_risk_analyses_edit: "ESG Risk Analyse bearbeiten",
  system_admin_risk_analyses_evaluation_period: "Analyse-Zeitraum",
  system_admin_risk_analyses_deadline: "Deadline",
  system_admin_risk_assessment_template: "Umfrage Template (ESG Fragebogen)",
  system_admin_risk_assessment_short_template: "Umfrage Template (Verkürzter Fragebogen)",
};
