/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_news_news: "Noticias",
  system_admin_news_news_management: "Gestión de Noticias",
  system_admin_news_info: 'En esta página, puedes gestionar el contenido de la sección "Noticias" para los clientes. Esta sección se encuentra en el lado del cliente de la página principal.',
  system_admin_news_publish_date: "Fecha de Publicación",
  system_admin_news_title: "Título",
  system_admin_news_creation_date: "Fecha de Creación",
  system_admin_news_creation_status: "Estado",
  system_admin_news_publish: "Publicar",
  system_admin_news_draft: "Borrador",
  system_admin_news_do_you_really_want_to_delete_the_news_item: "¿Realmente quieres eliminar el elemento de noticias?",
  system_admin_news_item_successfully_created: "Elemento de noticias creado con éxito",
  system_admin_news_item_successfully_edited: "Elemento de noticias editado con éxito",
  system_admin_news_item_successfully_deleted: "Elemento de noticias eliminado con éxito",
  system_admin_news_create_news: "Crear Elemento de Noticias",
  system_admin_news_edit_news: "Editar Elemento de Noticias",
  system_admin_news_editor: "Editor",
  system_admin_news_preview: "Vista Previa",
  system_admin_news_title_in_english: "Título en Inglés",
  system_admin_news_title_in_german: "Título en Alemán",
  system_admin_news_select_tags: "Seleccionar Etiquetas",
  system_admin_news_type_the_description_in_english_here: "Escribe la descripción en inglés aquí",
  system_admin_news_type_the_description_in_german_here: "Escribe la descripción en alemán aquí",
  system_admin_news_display_news_to_customers: "Mostrar Noticias a los Clientes",
  system_admin_news_as_long_as_this_checkbox_is_not_checked: "Mientras esta casilla no esté marcada, el elemento de noticias estará en modo borrador y no se mostrará a los clientes.",
  system_admin_news_news_can_only_be_published_if: "Las noticias solo se pueden publicar si se han completado todos los campos obligatorios.",
  system_admin_news_display_to_the_customers: "Publicar elemento de noticias",
  system_admin_news_new_update: "Nueva Actualización",
};
