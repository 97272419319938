/* eslint-disable */
// prettier-ignore

export const messages = {
  auth_back_to_login: "Volver al inicio de sesión",
  auth_login_success: "Inicio de sesión exitoso",
  auth_new_password: "Nueva contraseña",
  auth_new_password_repeat: "Repetir nueva contraseña",
  auth_reset_password: "Restablecer contraseña",
  auth_forgot_password: "Olvidé mi contraseña",
  auth_forgot_password_page_description: "Por favor, proporcione la dirección de correo electrónico que utilizó cuando se registró para la cuenta de GLOBAL CLIMATE.\n\n Le enviaremos un correo electrónico que le permitirá restablecer su contraseña.",
  auth_login: "Iniciar sesión",
  auth_reset_password_email_sent: "Se ha enviado un correo electrónico para restablecer la contraseña a su bandeja de entrada. Por favor, siga los pasos en ese correo.",
  auth_reset_your_password: "Restablecer contraseña",
  auth_reset_your_password_description: "El enlace para restablecer la contraseña no es válido. Haga clic aquí para solicitar un nuevo enlace para restablecer la contraseña.",
  auth_password_changed: "La contraseña se ha cambiado con éxito",
  auth_complete_registration: "Completar registro",
  auth_complete_registration_description: "El enlace de registro ha caducado. Por favor, contacte a su administrador de cuenta o al soporte de GLOBAL CLIMATE para solicitar un nuevo enlace de registro.",
  auth_confirm_password: "Confirmar contraseña",
  auth_registration_completed: "Registro completado",
  auth_invitation_email_sent: "Correo de invitación enviado al usuario.",
  auth_company_registration: "Registro",
  auth_company_information: "Información de la empresa",
  auth_company_name: "Nombre de la empresa",
  auth_agree_with: "He leído y entiendo los {0}",
  auth_terms_conditions: "Términos y condiciones",
  auth_data_conditions: "Condiciones de seguridad de datos",
  auth_agreement_warning: "Se deben aceptar las condiciones para registrarse",
  auth_not_registered: "¿No estás registrado aún?",
  auth_register: "Registrarse",
  auth_register_and_login: "Registrarse e iniciar sesión",
  auth_invitation_information: "Información de la invitación",
  auth_invitation_code: "Código de invitación",
  auth_invitation_information_description: "Para poder registrarse en GLOBAL SUITE, debe proporcionar el código de invitación que recibió en su correo electrónico de invitación.",
  auth_personal_data: "Datos personales",
  auth_lost_my_device: "Perdí mi dispositivo",
  auth_lost_device: "¿Perdiste tu dispositivo? Usa el código de recuperación",

  auth_sales_information_form_title: "El uso de GLOBAL SUITE es gratuito para usted como proveedor. Sin embargo, las empresas a menudo están sujetas a obligaciones de informes en las que podemos apoyarlo.",
  auth_sales_information_form_body: "¿Sabía que las empresas de Alemania con más de 250 empleados están obligadas a crear un balance de emisiones de CO<sub>2</sub>? Si está interesado en nuestros productos, le pedimos amablemente que responda las siguientes preguntas:",
  auth_sales_information_form_question_1: "¿Está interesado en un balance de gases de efecto invernadero?",
  auth_sales_information_form_question_2: "¿Está interesado en una solución para cumplir con las obligaciones de informes del LkSG?",
  auth_sales_information_form_question_3: "Por favor, complete su número de teléfono, le informaremos sobre nuestros productos",
  auth_sales_information_telephone_number: "Número de teléfono",
};
