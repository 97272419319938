import { RouteConfig } from "vue-router";

export const systemAdminRoutes: Array<RouteConfig> = [
  {
    path: "",
    component: () => import("@/views/welcome/index.vue"),
  },
  {
    path: `profile`,
    component: () => import("@/views/profile/index.vue"),
  },
  {
    path: `support`,
    component: () => import("@/views/support/index.vue"),
  },
  {
    path: `support/:id`,
    component: () => import("@/views/support/index.vue"),
  },
  {
    path: `companies`,
    component: () => import("@/views/companies/index.vue"),
  },
  {
    path: `companies/:id`,
    component: () => import("@/views/companies/company-details/index.vue"),
  },
  {
    path: `company-groups`,
    component: () => import("@/views/company-group/index.vue"),
  },
  {
    path: `company-groups/:id`,
    component: () => import("@/views/company-group/details/index.vue"),
  },
  {
    path: `users`,
    component: () => import("@/views/users/index.vue"),
  },
  {
    path: `co2-database`,
    component: () => import("@/views/co2-database/index.vue"),
  },
  {
    path: `esg-risk-analyses`,
    component: () => import("@/views/esg-risk-analysis/index.vue"),
  },
  {
    path: `esg-risk-analyses/:esgRiskAnalysisId`,
    component: () => import("@/views/esg-risk-analysis/details/index.vue"),
  },
  {
    path: `surveys`,
    component: () => import("@/views/surveys/table/index.vue"),
  },
  {
    path: `surveys/:id`,
    component: () => import("@/views/surveys/details/index.vue"),
  },
  {
    path: `survey-templates`,
    component: () => import("@/views/survey-templates/table/index.vue"),
  },
  {
    path: `companies/:companyId/:esgRiskAnalysisId`,
    component: () => import("@/views/esg-risk-analysis/details/index.vue"),
  },
  {
    path: `co2-type-prices`,
    component: () => import("@/views/co2-type-prices/index.vue"),
  },
  {
    path: "esg-self-assessments",
    component: () => import("@/views/esg-self-assessment/index.vue"),
  },
  {
    path: "esg-self-assessments/:id",
    component: () => import("@/views/surveys/details/index.vue"),
    props: { sourceIsEsgSelfAssessment: true },
  },
  {
    path: `units`,
    component: () => import("@/views/units/index.vue"),
  },
  {
    path: `integration`,
    component: () => import("@/views/integration/index.vue"),
  },
  {
    path: `distance-calc`,
    component: () => import("@/views/distance-calc/index.vue"),
  },
  {
    path: `news`,
    component: () => import("@/views/news/index.vue"),
  },
];
