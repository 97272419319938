/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_esg_self_assessment: "Autoevaluación ESG",
  system_admin_esg_self_assessments: "Autoevaluaciones ESG",
  system_admin_esg_self_assessment_details: "Detalles de la Autoevaluación ESG",
  system_admin_esg_self_assessment_body: "Utilice esta sección para iniciar una Autoevaluación ESG. Tenga en cuenta que para cada empresa solo se puede iniciar una autoevaluación ESG por período de evaluación. Después de la iniciación de una Autoevaluación ESG, los clientes podrán ver una encuesta en su plataforma de encuestas.",
  system_admin_esg_self_assessment_survey_template: "Plantilla de Encuesta",
  system_admin_esg_self_assessment_survey_template_name: "Nombre de la plantilla de encuesta",
  system_admin_create_esg_self_assessment: "Crear Autoevaluación ESG",
  system_admin_create_esg_self_assessment_success: "Autoevaluación ESG creada",
  system_admin_esg_self_assessment_validation_one_plus_year: "El período de evaluación no debe ser superior a {0}",
};
