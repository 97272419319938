/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_sco2pes_financial_scope: "Alcance",
  system_admin_sco2pes_financial_co2_type: "Tipo de CO<sub>2</sub>",
  system_admin_sco2pes_financial_co2_type_price: "Precio del Tipo de CO<sub>2</sub>",
  system_admin_sco2pes_financial_co2_type_prices: "Precios del Tipo de CO<sub>2</sub>",
  system_admin_sco2pes_financial_co2_type_prices_import_title: "Importar Precios de Tipos de CO<sub>2</sub>",
  system_admin_sco2pes_financial_co2_type_prices_import_description: "Los precios de los tipos de CO<sub>2</sub> se pueden importar mediante un archivo de Excel. Utilice esta plantilla de Excel para cargar el archivo:",
  system_admin_sco2pes_co2_type_price_do_you_really_want_to_delete_the_co2_type_price: "¿Está seguro de que desea eliminar el precio del Tipo de CO<sub>2</sub>?",
  system_admin_sco2pes_co2_type_price_deleted: "Precio del Tipo de CO<sub>2</sub> eliminado",
  system_admin_sco2pes_co2_type_price_versions_add_version: "Agregar Versión",
  system_admin_sco2pes_co2_type_price_version_validation_1: "<i>Desde</i> y <i>Hasta</i> deben estar establecidos",
  system_admin_sco2pes_co2_type_price_version_validation_2: "<i>Hasta</i> debe ser después de <i>Desde</i>",
  system_admin_sco2pes_co2_type_price_successfully_edited: "Precio del Tipo de CO<sub>2</sub> editado con éxito",
  system_admin_sco2pes_co2_type_price_successfully_created: "Precio del Tipo de CO<sub>2</sub> creado con éxito",
  system_admin_sco2pes_co2_type_price_validations: "Validaciones",
  system_admin_sco2pes_co2_type_price_validation_1: "Ambos nombres están proporcionados y se ha seleccionado un Tipo de CO<sub>2</sub>.",
  system_admin_sco2pes_co2_type_price_validation_3: "Todos los precios son positivos.",
  system_admin_sco2pes_co2_type_price_meta_data: "Metadatos",
  system_admin_sco2pes_co2_type_price_versions: "Versiones",
  system_admin_sco2pes_co2_type_price_validity: "Validez",
  system_admin_sco2pes_co2_type_price_delete_version: "Eliminar versión",
  system_admin_sco2pes_co2_type_price_price: "Precio",
  system_admin_sco2pes_co2_type_price_booking_warning: "Actualizar un precio de tipo de CO2 no tendrá efecto en las reservas bloqueadas y los informes finalizados.",

  system_admin_sco2pes_affected_title: "Estas empresas se verán afectadas",
  system_admin_sco2pes_affected_subtitle: "Antes de guardar, por favor verifique las siguientes empresas que se verán afectadas por estos cambios.",
  system_admin_sco2pes_affected_none_title: "Ninguna empresa se verá afectada por esta actualización",
  system_admin_sco2pes_affected_none_subtitle: "Puede proceder haciendo clic en el botón de guardar",
  system_admin_sco2pes_affected_company_name: "Empresa",
  system_admin_sco2pes_affected_financial_models: "Modelos financieros",
  system_admin_sco2pes_affected_financial_bookings: "Reservas financieras",

  system_admin_sco2pes_financial_category: "Categoría de CO<sub>2</sub>",
  system_admin_sco2pes_financial_versions: "Versiones",
  system_admin_sco2pes_financial_price_per_unit: "Precio por Unidad",
  system_admin_sco2pes_financial_price_per_unit_selected: "Precio por {unit}",
  system_admin_sco2pes_financial_co2_type_deactivated: "Tipo de CO2 desactivado",
  system_admin_sco2pes_financial_co2_type_active: "Tipo de CO2 activo",
  system_admin_sco2pes_financial_co2_type_open: "Abrir diálogo de tipo de CO2",
};
