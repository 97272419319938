import { countries } from "@gc/models";
import { i18n } from "@gc/i18n";

const countryArray: any = {};

["en", "de", "es"].forEach((locale) => {
  countryArray[locale] = [];
  Object.keys(countries).forEach((countryCode) => {
    countryArray[locale].push({ code: countryCode, name: i18n.t(countryCode, locale) });
  });
});

export default {
  countries,
  install(Vue: any) {
    Vue.prototype.$countries = countryArray;
  },
};
