/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_company_companies: "Empresas",
  system_admin_company_login: "Iniciar Sesión",
  system_admin_company_login_desc: "Iniciar Sesión como Usuario de Soporte",
  system_admin_company_company: "Empresa",
  system_admin_company_company_groups: "Grupo de Empresas",
  system_admin_company_company_group: "Grupo de Empresas",
  system_admin_company_create_company_group: "Crear Grupo de Empresas",
  system_admin_company_create_company: "Crear Empresa",
  system_admin_company_add_company: "Agregar Empresa",
  system_admin_company_delete_company: "Eliminar Empresa",
  system_admin_company_holdings: "Participaciones",
  system_admin_company_holding: "Participación",
  system_admin_company_add_holding: "Agregar Participación",
  system_admin_company_edit_holding: "Editar Participación",
  system_admin_company_delete_holding_confirmation: "¿Realmente desea eliminar la participación?",
  system_admin_company_delete_company_confirmation: "¿Realmente desea eliminar la empresa del grupo de empresas?<br> Al eliminar una empresa de un grupo de empresas, todas las participaciones relacionadas también se eliminarán.",
  system_admin_company_parent: "Matriz",
  system_admin_company_subsidiary: "Subsidiaria",
  system_admin_company_logo_description: "El logotipo debe ser un archivo PNG. La resolución preferida es 360*120 píxeles",
  system_admin_company_company_name: "Nombre de la Empresa",
  system_admin_company_company_logo: "Logotipo de la Empresa",
  system_admin_company_company_successfully_created: "Empresa creada con éxito",
  system_admin_company_company_details: "Detalles de la Empresa",
  system_admin_company_users: "Usuarios",
  system_admin_company_user: "Usuario",
  system_admin_company_bookings: "Reservas",
  system_admin_company_supplier: "Proveedores",
  system_admin_company_transport_routes: "Rutas de Transporte",
  system_admin_company_active_co2_types: "Tipos de CO<sub>2</sub> Activos",
  system_admin_company_inactive_co2_types: "Tipos de CO<sub>2</sub> Inactivos",
  system_admin_company_upload_logo: "Subir Logotipo de la Empresa",
  system_admin_company_upload: "Subir",
  system_admin_company_update_logo_description: "Si selecciona un nuevo logotipo, el existente será reemplazado.",
  system_admin_company_logo_successfully_uploaded: "Logotipo subido con éxito",
  system_admin_company_connected_partners: "Socios Conectados",
  system_admin_company_pending_partners: "Socios Pendientes",
  system_admin_company_external_esg_ratings: "Calificaciones ESG Externas",
  system_admin_company_edit_company: "Editar Empresa",
  system_admin_company_successfully_edited: "Empresa editada con éxito",
  system_admin_company_edit_user: "Editar Usuario",
  system_admin_company_licenses: "Licencias",
  system_admin_company_add_license: "Agregar Licencia",
  system_admin_company_edit_license: "Editar Licencia",
  system_admin_company_delete_license: "Eliminar Licencia",
  system_admin_company_delete_license_title: "¿Desea eliminar la licencia?",
  system_admin_company_delete_license_message: "Se eliminará permanentemente",
  system_admin_company_license_type: "Tipo de Licencia",
  system_admin_company_valid_from: "Válido desde",
  system_admin_company_valid_until: "Válido hasta",
  system_admin_company_percentage_validation: "El valor debe estar entre 0 y 100",
  system_admin_company_circular_validation: "No es posible crear una participación entre la Empresa y sí misma",
  system_admin_company_active: "Activo",
  system_admin_company_ordered: "Ordenado",
  system_admin_company_void: "Nulo",
  system_admin_company_open: "Abierto",
  system_admin_company_paid: "Pagado",
  system_admin_company_download_invoice: "Descargar Factura",
  system_admin_company_license_status: "Estado de la Licencia",
  system_admin_company_requested_at: "Fecha de Solicitud",
  system_admin_company_license_info: "Información de la Licencia",
  system_admin_company_ordered_by: "Ordenado por",
  system_admin_company_invoice_status: "Estado de la Factura",
  system_admin_company_no_Licenses: "Sin Licencias",
  system_admin_company_group_diagram: "Diagrama",
};
