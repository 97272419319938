/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_co2_database_title: "CO<sub>2</sub> Types",
  system_admin_co2_database_freetext_search: "Freetext Search (ID/Name)",
  system_admin_co2_database_type_description: "Type Description",
  system_admin_co2_database_unit: "Unit",
  system_admin_co2_database_co2_equivalent: "CO<sub>2</sub> Equivalent",
  system_admin_co2_database_kg_equivalent: "Kg Equivalent", //
  system_admin_co2_database_description_in_english: "Description in English",
  system_admin_co2_database_description_in_german: "Description in German",
  system_admin_co2_database_co2_type: "CO<sub>2</sub> Type",
  system_admin_co2_database_quantity_type: "Quantity Type",
  system_admin_co2_database_type_active_checkbox: "CO<sub>2</sub>-Type active",
  system_admin_co2_database_type_valid_versions: "Validity period of versions have no gap and no overlap",
  system_admin_co2_database_type_valid: "All required data provided",
  system_admin_co2_database_co2_type_successfully_created: "CO<sub>2</sub> Type successfully created",
  system_admin_co2_database_co2_type_successfully_edited: "CO<sub>2</sub> Type successfully edited",
  system_admin_co2_database_co2_type_successfully_deleted: "CO<sub>2</sub> Type successfully deleted",
  system_admin_co2_database_co2_categories: "CO<sub>2</sub> Categories",
  system_admin_co2_database_co2_category_created: "CO<sub>2</sub> Category successfully created",
  system_admin_co2_database_co2_category_edited: "CO<sub>2</sub> Category successfully edited",
  system_admin_co2_database_co2_category_deleted: "CO<sub>2</sub> Category successfully deleted",
  system_admin_co2_database_do_you_really_want_to_delete_the_co2_category: "Do you really want to delete the CO<sub>2</sub> category",
  system_admin_co2_database_new_co2_category: "New CO<sub>2</sub> Category",
  system_admin_co2_database_edit_co2_category: "Edit CO<sub>2</sub> Category",
  system_admin_co2_database_do_you_really_want_to_delete_the_co2_type: "Do you really want to delete the CO<sub>2</sub> Type",
  system_admin_co2_database_duplicate_type_title: "Duplicate the CO<sub>2</sub> Type?",
  system_admin_co2_database_duplicate_type_subtitle: "Details can be edited before saving of the duplicated CO<sub>2</sub> Type",
  system_admin_co2_database_origin_database_reference: "Origin Database Reference",
  system_admin_co2_database_origin_database: "Origin Database",
  system_admin_co2_database_co2_category: "CO<sub>2</sub> Category",
  system_admin_co2_database_versions: "Versions",
  system_admin_co2_database_version: "Version",
  system_admin_co2_database_from: "From",
  system_admin_co2_database_until: "Until",
  system_admin_co2_database_dialog_date_not_valid: "<strong>From</strong> must be before or the same as <strong>Until</strong>",
  system_admin_co2_database_dialog_kg_not_valid: "Kg Equivalent must be 1 if the unit is <strong>kg</strong>",
  system_admin_co2_database_version_delete_confirmation_title: "Delete Version",
  system_admin_co2_database_version_delete_confirmation_message: "Are you sure you want to delete this version?",
  system_admin_co2_database_no_version_message: "At least one version is required",
  system_admin_co2_database_versions_not_valid: "There must always be exactly one version per order period (no overlapping, no gaps). The first version must be valid from 0000-01, the last version must be valid until 9999-12.",
  system_admin_co2_database_versions_kg_not_valid: "Kg Equivalent must be 1 if the unit is <strong>kg</strong>",
  system_admin_co2_database_new_version: "New Version",
  system_admin_co2_database_edit_version: "Edit Version",
  system_admin_co2_database_administration: "Administration",
  system_admin_co2_database_created_at: "Created At",
  system_admin_co2_database_versions_count: "Versions Count",
  system_admin_co2_database_only_global: "Only global CO<sub>2</sub> Types",
  system_admin_co2_database_selected_co2_types_updated: "Selected CO<sub>2</sub> Types Successfully Updated",
  system_admin_co2_database_confirm_activating_co2_types: "Do you really want to activate the {selectedCo2TypesCount} selected CO<sub>2</sub> type(s)?",
  system_admin_co2_database_confirm_deactivating_co2_types: "Do you really want to deactivate the {selectedCo2TypesCount} selected CO<sub>2</sub> type(s)?",
  system_admin_co2_database_co2_usage_title: "Companies that utilize this CO<sub>2</sub> type",
  system_admin_co2_database_co2_usage_tagged_count: "Tagged count",
  system_admin_co2_database_co2_usage_unit_booking_count: "Unit Booking count",
  system_admin_co2_database_co2_usage_supplier_count: "Supplier count",
  system_admin_co2_database_co2_usage_financial_model_count: "Financial Model count",
  system_admin_co2_database_co2_usage_commuter_survey_count: "Commuter Survey count",

  system_admin_co2_database_affected_title: "These companies will be affected",
  system_admin_co2_database_affected_subtitle: "Before you save, please check the following companies that will be affected by this changes.",
  system_admin_co2_database_affected_none_title: "No companies will be affected by this update",
  system_admin_co2_database_affected_none_subtitle: "You can proceed by clicking on save button",
  system_admin_co2_database_affected_company: "Company",
  system_admin_co2_database_affected_co2_type_prices: "CO<sub>2</sub> Type Prices",
  system_admin_co2_database_affected_financial_models: "Financial models",
  system_admin_co2_database_affected_transport_routes: "Transport routes",
  system_admin_co2_database_affected_commuter_surveys: "Commuter surveys",
  system_admin_co2_database_affected_unit_bookings: "Unit bookings",

  //External Databases
  system_admin_co2_database_external_databases: "External Scientific Databases",

  system_admin_co2_database_database_label: "Database",
  system_admin_co2_database_valid_from: "Valid From",
  system_admin_co2_database_valid_until: "Valid Until",
  system_admin_co2_database_geography: "Geography",
  system_admin_co2_database_actions: "Actions",
  system_admin_co2_database_name: "Name",
  system_admin_co2_database_valid_period: "Valid Period",
  system_admin_co2_database_no_data: "No data available",
  system_admin_co2_database_edit: "Edit",
  system_admin_co2_database_create_unit_booking: "Create Unit Booking",
  system_admin_co2_database_copy_id: "Copy ID",
  system_admin_co2_database_select_co2_type: "Select CO₂ Type",
  system_admin_co2_database_delete: "Delete",
  system_admin_co2_database_delete_confirmation: "Do you really want to delete the database ({name})?",
  system_admin_co2_database_delete_success: "Database deleted successfully.",
  system_admin_co2_database_delete_error: "Failed to delete the database.",
  system_admin_co2_database_loading: "Loading databases...",
  system_admin_co2_database_error_fetch: "Failed to fetch databases",
  system_admin_co2_database_no_results: "No results found.",
  system_admin_co2_database_property: "Property",
  system_admin_co2_database_name_en: "Name (EN)",
  system_admin_co2_database_name_de: "Name (DE)",
  system_admin_co2_database_attributes: "Attributes",
  system_admin_co2_database_searchable: "Searchable",
  system_admin_co2_database_required: "Required",
  system_admin_co2_database_in_display_name: "In Display Name",
  system_admin_co2_database_has_ccf_licence: "CCF License",
};
