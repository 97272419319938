/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_news_news: "Nachricht",
  system_admin_news_news_management: "Nachrichtenmanagement",
  system_admin_news_info: "Auf dieser Seite können Sie die Inhalte für den Bereich „Neuigkeiten“ für Kunden verwalten. Dieser Bereich befindet sich auf der Kundenseite der Homepage.",
  system_admin_news_publish_date: "Veröffentlichungsdatum",
  system_admin_news_title: "Titel",
  system_admin_news_creation_date: "Erstellungsdatum",
  system_admin_news_creation_status: "Status",
  system_admin_news_publish: "Veröffentlicht",
  system_admin_news_draft: "Entwurf",
  system_admin_news_do_you_really_want_to_delete_the_news_item: "Möchten Sie den News Beitrag wirklich löschen?",
  system_admin_news_item_successfully_created: "News Beitrag erfolgreich erstellt",
  system_admin_news_item_successfully_edited: "News Beitrag erfolgreich bearbeitet",
  system_admin_news_item_successfully_deleted: "News Beitrag erfolgreich gelöscht",
  system_admin_news_create_news: "News Beitrag erstellen",
  system_admin_news_edit_news: "News Beitrag bearbeiten",
  system_admin_news_editor: "Editor",
  system_admin_news_preview: "Vorschau",
  system_admin_news_title_in_english: "Titel auf Englisch",
  system_admin_news_title_in_german: "Titel auf Deutsch",
  system_admin_news_select_tags: "Wählen Sie Tags aus",
  system_admin_news_type_the_description_in_english_here: "Geben Sie hier die Beschreibung auf Englisch ein",
  system_admin_news_type_the_description_in_german_here: "Geben Sie hier die Beschreibung auf Deutsch ein",
  system_admin_news_display_news_to_customers: "Zeigen Sie Ihren Kunden Neuigkeiten an",
  system_admin_news_as_long_as_this_checkbox_is_not_checked: "Solange diese Checkbox nicht aktiviert ist, befindet sich die Neuigkeit im Entwurfsmodus und wird den Kunden nicht angezeigt.",
  system_admin_news_news_can_only_be_published_if: "Eine Veröffentlichung der News ist nur möglich, wenn alle Pflichtfelder ausgefüllt sind.",
  system_admin_news_display_to_the_customers: "News Beitrag veröffentlichen",
  system_admin_news_new_update: "Neues Update",
};
