/* eslint-disable */
// prettier-ignore

export const messages = {
  // Common navigation items
  common_navigation_integration: "Integración",
  common_navigation_show_pending_booking_pages: "Mostrar páginas de reservas pendientes",

  // Customer navigation items
  customer_navigation_global_footprint: "HUELLA GLOBAL",
  customer_navigation_global_footprint_ccf: "Herramienta CCF",
  customer_navigation_global_footprint_pcf: "Herramienta PCF",
  customer_navigation_global_footprint_sbt: "Herramienta SBT",
  customer_navigation_sco2pes_bookings: "Reservas",
  customer_navigation_sco2pes_base_data: "Datos Base",
  customer_navigation_sco2pes_financial_models: "Modelos Financieros",
  customer_navigation_sco2pes_pending_financial_bookings: "Reservas Financieras Pendientes",
  customer_navigation_sco2pes_financial_bookings: "Reservas Financieras",
  customer_navigation_sco2pes_unit_bookings: "Reservas de Unidades",
  customer_navigation_sco2pes_pending_unit_bookings: "Reservas de Unidades Pendientes",
  customer_navigation_sco2pes_finalised_reports: "Informes Finalizados",
  customer_navigation_sco2pes_suppliers: "Proveedores",
  customer_navigation_sco2pes_company_codes: "Códigos de Empresa",
  customer_navigation_sco2pes_commuter_surveys: "Encuestas de Viajeros",
  customer_navigation_sco2pes_co2_database: "Base de Datos de CO<sub>2</sub>",
  customer_navigation_sco2pes_reporting: "Informes",
  customer_navigation_sco2pes_company: "Empresa",
  customer_navigation_sco2pes_company_group: "Grupo Empresarial",
  customer_navigation_sco2pes_co2_type_tags: "Etiquetas de Tipo de CO<sub>2</sub>",
  customer_navigation_global_compliance: "CUMPLIMIENTO GLOBAL",
  customer_navigation_esg_ratings: "Calificaciones",
  customer_navigation_esg_risk_analyses: "Análisis de Riesgo",
  customer_navigation_esg_administration: "Administración",
  customer_navigation_esg_administration_users: "Usuarios",
  customer_navigation_esg_administration_company: "Empresa",
  customer_navigation_esg_administration_licenses: "Licencias",
  customer_navigation_esg_support: "Soporte",
  customer_navigation_esg_profile: "Perfil",
  customer_navigation_esg_account_selection: "Selección de Cuenta",
  customer_navigation_esg_logout: "Cerrar Sesión",
  customer_navigation_esg_esg: "Herramienta ESG",
  customer_navigation_esg_lksg: "Herramienta LkSG",
  customer_navigation_esg_csrd: "Herramienta CSRD",
  customer_navigation_esg_dfl: "Herramienta DFL",
  customer_navigation_products: "Productos",
  customer_navigation_other: "Otros",
  customer_navigation_back_to_main_navigation: "Volver a la navegación principal",
  customer_navigation_upload_section: "Sección de Carga",
  customer_navigation_navigation_surveys: "ENCUESTAS",
  customer_navigation_configuration: "Configuración",

  // System admin navigation items
  system_admin_navigation_co2_database: "Base de Datos de CO<sub>2</sub>",
  system_admin_navigation_prices: "Precios de Tipos de CO<sub>2</sub>",
  system_admin_navigation_sco2pes: "HUELLA GLOBAL",
  system_admin_navigation_compliance: "CUMPLIMIENTO GLOBAL",
  system_admin_navigation_risk_analyses: "Análisis de Riesgo ESG",
  system_admin_navigation_surveys: "Encuestas",
  system_admin_navigation_survey_templates: "Plantillas de Encuestas",
  system_admin_navigation_support: "Soporte",
  system_admin_navigation_users_and_companies: "Usuarios y Empresas",
  system_admin_navigation_users: "Usuarios",
  system_admin_navigation_companies: "Empresas",
  system_admin_navigation_company_groups: "Grupos Empresariales",
  system_admin_navigation_units: "Unidades",
  system_admin_navigation_distance_calc: "Cálculo de Distancia",
  system_admin_navigation_news_management: "Gestión de noticias",
};
