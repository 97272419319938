/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_esg_ratingRequest_title: "ESG Rating Request",
  customer_esg_ratingRequest_back_to_survey_page: "Back to survey page",
  customer_esg_ratingRequest_overview: "Overview",
  customer_esg_ratingRequest_survey: "Survey",
  customer_esg_ratingRequest_result: "Result",
  customer_esg_ratingRequest_progress: "Progress",
  customer_esg_ratingRequest_open_survey: "Open survey",
  customer_esg_ratingRequest_rating_information_title: "Rating Information",
  customer_esg_ratingRequest_rating_information_1: "The ESG-Rating has established itself around the world as the quality index for sustainable supply chains and company ratings. {companyName} asks your company to be assessed by GLOBAL CLIMATE. You must answer the survey in the next tab, which includes questions on environmental, social and governance aspects. Since answering some of the questions may require research at your company, progress is saved after answering each question and you can continue to complete the questionnaires at a later time.<br/><br/>The answers will be scored automatically after submitting the survey and the score result will be shared with <b>{companyName}</b>.",
  customer_esg_ratingRequest_rating_information_2: "Your company can get a detailed analysis of your score, KPIs and a Scorecard when buying a license. Our expert team will then further analyse your results e.g. validate the documents and certificates that have been provided for some questions. After the review process you will get informed by GLOBAL CLIMATE.",
  customer_esg_ratingRequest_sender_information_title: "Sender Information",
  customer_esg_ratingRequest_sender: "Sender",
  customer_esg_ratingRequest_invitation_message: "Invitation Message",
  customer_esg_ratingRequest_survey_questionnaires: "Survey Questionnaires",
  customer_esg_ratingRequest_submit_info: "You need to answer all required questions (no validation errors) before being able to submit the survey.",
  customer_esg_ratingRequest_submit_valid: "All questions are answered and valid - you need to actively submit the survey for getting the results.",
  customer_esg_ratingRequest_submit_warning: "You can not modify the answers anymore because the survey is submitted.",
  customer_esg_ratingRequest_result_in_review_title: "Result still on the analysis",
  customer_esg_ratingRequest_result_in_review_subtitle: "Our expert team is still analysing your answers. You will receive an E-Mail notification once the results are available.",
  customer_esg_ratingRequest_result_answer_survey: "Answer survey",
  customer_esg_ratingRequest_upgrade_license: "Upgrade license",
  customer_esg_ratingRequest_banner_text: "Benefit from a detailed scoring,<br />a shareable Scorecard, KPIs and more",
  customer_esg_ratingRequest_license_package_1: "License Package",
  customer_esg_ratingRequest_license_package_2: "Upgrade your license",
  customer_esg_ratingRequest_license_package_description: "Without a license your overall score will be shared with <strong>{0}</strong >, but you’ll not be able to use the achieved score on your own. For getting a detailed scoring, a shareable Scorecard and KPIs of your ESG Rating results you need to buy a license. Our expert team will analyse your answers in detail e.g. by validating all documents and certificates that have been provided by you.",
  customer_esg_ratingRequest_rating_license: "ESG Rating License",
  customer_esg_ratingRequest_plus_vat: "plus VAT",
  customer_esg_ratingRequest_small_companies: "Small Companies",
  customer_esg_ratingRequest_small_companies_1: "Detailed analysis of the answers of one ESG Rating",
  customer_esg_ratingRequest_small_companies_2: "Shareable Scorecard",
  customer_esg_ratingRequest_small_companies_3: "KPIs",
  customer_esg_ratingRequest_medium_companies: "Medium Companies",
  customer_esg_ratingRequest_medium_companies_1: "Detailed analysis of the answers of one ESG Rating",
  customer_esg_ratingRequest_medium_companies_2: "Shareable Scorecard",
  customer_esg_ratingRequest_medium_companies_3: "KPIs",
  customer_esg_ratingRequest_large_companies: "Large Companies",
  customer_esg_ratingRequest_large_companies_1: "Detailed analysis of the answers of one ESG Rating",
  customer_esg_ratingRequest_large_companies_2: "Shareable Scorecard",
  customer_esg_ratingRequest_large_companies_3: "KPIs",
  customer_esg_ratingRequest_small_license: "GLOBAL COMPLIANCE Small License",
  customer_esg_ratingRequest_medium_license: "GLOBAL COMPLIANCE Medium License",
  customer_esg_ratingRequest_large_license: "GLOBAL COMPLIANCE Large License",
  customer_esg_ratingRequest_license_description_1: "Please validate and adapt the billing information:",
  customer_esg_ratingRequest_license_description_2: "By continuing an invoice will be send to the provided email address.<br/><br/>You can either pay via our payment service provider Stripe (credit card) or by bank transfer. If you transfer the amount directly, the license will be unlocked as soon as your payment is booked.",
  customer_esg_ratingRequest_finish_license_title_1: "Thank you for ordering a GLOBAL COMPLIANCE license!",
  customer_esg_ratingRequest_finish_license_title_2: "Finish your payment to activate the license",
  customer_esg_ratingRequest_finish_license_description_1_1: "The invoice has been send via E-mail to your E-mail address ({0}). The invoice can also be downloaded here.",
  customer_esg_ratingRequest_finish_license_description_1_2: "We will send you the invoice to the given email ({0}), but you can also download it here.",
  customer_esg_ratingRequest_finish_license_description_2: "Your license will be activated immediately if payment is made via our payment service provider Stripe",
  customer_esg_ratingRequest_finish_license_description_3: "If you transfer the amount directly to the bank account specified in the invoice, the license will be unlocked as soon as your payment is received.",
  customer_esg_ratingRequest_invoice: "Invoice",
  customer_esg_ratingRequest_finish_payment_banner: "Finish your payment to activate the license",
  customer_esg_ratingRequest_finish_payment: "Finish payment",
  customer_esg_ratingRequest_start_survey: "Start survey",

  customer_esg_selfAssessment_title: "ESG Self Assessment",
  customer_esg_selfAssessment_overview_title: "Overview",
  customer_esg_selfAssessment_overview_info1: "The ESG-Rating has established itself around the world as the quality index for sustainable supply chains and company ratings.",
  customer_esg_selfAssessment_overview_info2: "To be assessed by GLOBAL CLIMATE, your company must answer the survey in the next tab, which includes questions on environmental, social and governance aspects. Since answering some of the questions may require research at your company, progress is saved after answering each question and you can continue to complete the questionnaires at a later time.",
  customer_esg_selfAssessment_overview_info3: "After submitting the answers of the survey it will be reviewed by GLOBAL CLIMATE. Global Climate will also validate the documents and certificates that have to be provided for some questions. After the review process you will get informed by GLOBAL CLIMATE about your final rating.",
  customer_esg_selfAssessment_overview_information: "Information",
  customer_esg_selfAssessment_overview_process: "Process",
  customer_esg_selfAssessment_overview_step1: "Rating initiated",
  customer_esg_selfAssessment_overview_step2: "Survey in progress",
  customer_esg_selfAssessment_overview_step3: "Answers in review",
  customer_esg_selfAssessment_overview_step4: "Results received",
  customer_esg_selfAssessment_survey_title: "Survey",
  customer_esg_selfAssessment_survey_alert1: "All required questions need to be answered (no validation errors) before being able to submit the survey.",
  customer_esg_selfAssessment_survey_alert2: "All questions are answered and valid - the survey needs to be actively submitted for getting the results.",
  customer_esg_selfAssessment_result_title: "Result",
  customer_esg_selfAssessment_result_title1: "Result not available",
  customer_esg_selfAssessment_result_subtitle1: "The result not available because the survey is not completed. Our expert team will analyse your results after you’ve submitted the survey.",
  customer_esg_selfAssessment_result_title2: "Ongoing expert analysis",
  customer_esg_selfAssessment_result_subtitle2: "Our expert team is analysing the answers. You will receive an E-Mail notification once the results are available.",
  customer_esg_selfAssessment_result_subtitle3: "Rating Result",
  customer_esg_selfAssessment_result_scorecard: "Scorecard",
  customer_esg_selfAssessment_submitDialog_title: "Submit survey",
  customer_esg_selfAssessment_submitDialog_desc1: "After submitting the survey you will be not able to modify the answers. Do you want to submit the survey?",
  customer_esg_selfAssessment_submitDialog_desc2: "Your answers have been submitted. You will receive an E-Mail once the results are available.",

  customer_surveys_deadline: "Deadline",
  customer_surveys_goToSurvey: "Go To Survey",
  customer_surveys_initiator: "Initiator",
  customer_surveys_noDeadline: "No deadline",
  customer_surveys_selfInitiated: "Self initiated",
  customer_surveys_subtitle: "The GLOBAL CLIMATE Survey Platform allows organisation to answer surveys for self assessments and surveys requested by their partner organisations.<br>Partners request your organisation to answer a survey for being able to fulfil their legal LkSG requirements or when performing an ESG Risk Analysis.<br>Please answer the surveys before the deadline. You can pause answering the surveys at any time, the progress will be stored.",
  customer_surveys_title: "Survey Platform",

  // LkSG Rating Request
  customer_lksgRatingRequest_title: "LkSG Rating Request",
  customer_lksgRatingRequest_back_to_survey_page: "Back to survey page",
  customer_lksgRatingRequest_overview: "Overview",
  customer_lksgRatingRequest_survey: "Survey",
  customer_lksgRatingRequest_progress: "Progress",
  customer_lksgRatingRequest_open_survey: "Open survey",
  customer_lksgRatingRequest_rating_information_title: "Information",
  customer_lksgRatingRequest_rating_information_1: "{companyName} asks your company to answer several questions for enabling them to fulfil their legal LkSG requirements. You can answer the survey in the next tab. Since answering some of the questions may require research at your company, progress is saved after answering each question and you can continue to complete the questionnaires at a later time.<br/><br/>The answers will be scored automatically after submitting the survey and the score result will be shared with <b>{companyName}</b>.",
  customer_lksgRatingRequest_sender_information_title: "Sender Information",
  customer_lksgRatingRequest_sender: "Sender",
  customer_lksgRatingRequest_invitation_message: "Invitation Message",
  customer_lksgRatingRequest_survey_questionnaires: "Survey Questionnaires",
  customer_lksgRatingRequest_submit_info: "You need to answer all required questions (no validation errors) before being able to submit the survey.",
  customer_lksgRatingRequest_submit_valid: "All questions are answered and valid - you need to actively submit the survey for getting the results.",
  customer_lksgRatingRequest_submit_warning: "You can not modify the answers anymore because the survey is submitted.",
  customer_lksgRatingRequest_result_answer_survey: "Answer survey",
  customer_lksgRatingRequest_start_survey: "Start survey",
  customer_lksgRatingRequest_survey_status_1: "Survey initiated",
  customer_lksgRatingRequest_survey_status_2: "Survey started",
  customer_lksgRatingRequest_survey_status_3: "Survey submitted",
  customer_lksgRatingRequest_survey_start: "Start LkSG Survey",
  customer_lksgRatingRequest_survey_text_1: "When answering the survey questions, the progress will be saved automatically. Please answer all necessary questions.",
  customer_lksgRatingRequest_survey_text_2: "Your answers will be analyzed by GLOBAL CLIMATE at the end of the survey and the answers will be automatically forwarded to your partner.",
  customer_lksgRatingRequest_survey_text_3: "If you have any questions, you can contact us via the support ticket system.",
  customer_lksgRatingRequest_survey_text_4: "Do you want to start the survey? Your partner <u>{partnerName}</u> will be automatically informed that you are participating in the survey.",
  customer_lksgRatingRequest_survey_submit_survey: "Submit survey",
  customer_lksgRatingRequest_survey_submitted_message: "Your answers have been submitted.",
  customer_lksgRatingRequest_survey_submit_description: "After submitting the survey you will be not able to modify the answers. Do you want to submit the survey?",
  customer_lksgRatingRequest_survey_complete_survey_description_2: "To complete the survey, all questions must be answered without validation errors.",
  customer_lksgRatingRequest_survey_number_of_questions: "Number of questions",
  customer_lksgRatingRequest_survey_number_of_invalid_answered_questions: "Number of questions with validation error",
  customer_lksgRatingRequest_survey_and_more: "And {0} more...",
  customer_lksgRatingRequest_survey_uploaded_documents: "Uploaded Documents",
  customer_lksgRatingRequest_survey_question: "Question {0}",
}
