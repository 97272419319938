/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_co2_database_title: "Tipos de CO<sub>2</sub>",
  system_admin_co2_database_freetext_search: "Búsqueda de Texto Libre (ID/Nombre)",
  system_admin_co2_database_type_description: "Descripción del Tipo",
  system_admin_co2_database_unit: "Unidad",
  system_admin_co2_database_co2_equivalent: "Equivalente de CO<sub>2</sub>",
  system_admin_co2_database_kg_equivalent: "Equivalente en Kg",
  system_admin_co2_database_description_in_english: "Descripción en Inglés",
  system_admin_co2_database_description_in_german: "Descripción en Alemán",
  system_admin_co2_database_co2_type: "Tipo de CO<sub>2</sub>",
  system_admin_co2_database_quantity_type: "Tipo de Cantidad",
  system_admin_co2_database_type_active_checkbox: "Tipo de CO<sub>2</sub> activo",
  system_admin_co2_database_type_valid_versions: "El período de validez de las versiones no tiene huecos ni superposiciones",
  system_admin_co2_database_type_valid: "Todos los datos requeridos proporcionados",
  system_admin_co2_database_co2_type_successfully_created: "Tipo de CO<sub>2</sub> creado con éxito",
  system_admin_co2_database_co2_type_successfully_edited: "Tipo de CO<sub>2</sub> editado con éxito",
  system_admin_co2_database_co2_type_successfully_deleted: "Tipo de CO<sub>2</sub> eliminado con éxito",
  system_admin_co2_database_co2_categories: "Categorías de CO<sub>2</sub>",
  system_admin_co2_database_co2_category_created: "Categoría de CO<sub>2</sub> creada con éxito",
  system_admin_co2_database_co2_category_edited: "Categoría de CO<sub>2</sub> editada con éxito",
  system_admin_co2_database_co2_category_deleted: "Categoría de CO<sub>2</sub> eliminada con éxito",
  system_admin_co2_database_do_you_really_want_to_delete_the_co2_category: "¿Realmente desea eliminar la categoría de CO<sub>2</sub>?",
  system_admin_co2_database_new_co2_category: "Nueva Categoría de CO<sub>2</sub>",
  system_admin_co2_database_edit_co2_category: "Editar Categoría de CO<sub>2</sub>",
  system_admin_co2_database_do_you_really_want_to_delete_the_co2_type: "¿Realmente desea eliminar el Tipo de CO<sub>2</sub>?",
  system_admin_co2_database_duplicate_type_title: "¿Duplicar el Tipo de CO<sub>2</sub>?",
  system_admin_co2_database_duplicate_type_subtitle: "Los detalles se pueden editar antes de guardar el Tipo de CO<sub>2</sub> duplicado",
  system_admin_co2_database_origin_database_reference: "Referencia de la Base de Datos de Origen",
  system_admin_co2_database_origin_database: "Base de Datos de Origen",
  system_admin_co2_database_co2_category: "Categoría de CO<sub>2</sub>",
  system_admin_co2_database_versions: "Versiones",
  system_admin_co2_database_version: "Versión",
  system_admin_co2_database_from: "Desde",
  system_admin_co2_database_until: "Hasta",
  system_admin_co2_database_dialog_date_not_valid: "<strong>Desde</strong> debe ser antes o igual que <strong>Hasta</strong>",
  system_admin_co2_database_dialog_kg_not_valid: "El Equivalente en Kg debe ser 1 si la unidad es <strong>kg</strong>",
  system_admin_co2_database_version_delete_confirmation_title: "Eliminar Versión",
  system_admin_co2_database_version_delete_confirmation_message: "¿Está seguro de que desea eliminar esta versión?",
  system_admin_co2_database_no_version_message: "Se requiere al menos una versión",
  system_admin_co2_database_versions_not_valid: "Siempre debe haber exactamente una versión por período de orden (sin superposiciones, sin huecos). La primera versión debe ser válida desde 0000-01, la última versión debe ser válida hasta 9999-12.",
  system_admin_co2_database_versions_kg_not_valid: "El Equivalente en Kg debe ser 1 si la unidad es <strong>kg</strong>",
  system_admin_co2_database_new_version: "Nueva Versión",
  system_admin_co2_database_edit_version: "Editar Versión",
  system_admin_co2_database_administration: "Administración",
  system_admin_co2_database_created_at: "Creado en",
  system_admin_co2_database_versions_count: "Conteo de Versiones",
  system_admin_co2_database_only_global: "Solo Tipos de CO<sub>2</sub> globales",
  system_admin_co2_database_selected_co2_types_updated: "Tipos de CO<sub>2</sub> seleccionados actualizados con éxito",
  system_admin_co2_database_confirm_activating_co2_types: "¿Realmente desea activar los {selectedCo2TypesCount} tipo(s) de CO<sub>2</sub> seleccionados?",
  system_admin_co2_database_confirm_deactivating_co2_types: "¿Realmente desea desactivar los {selectedCo2TypesCount} tipo(s) de CO<sub>2</sub> seleccionados?",
  system_admin_co2_database_co2_usage_title: "Empresas que utilizan este tipo de CO<sub>2</sub>",
  system_admin_co2_database_co2_usage_tagged_count: "Conteo de Etiquetas",
  system_admin_co2_database_co2_usage_unit_booking_count: "Conteo de Reservas de Unidades",
  system_admin_co2_database_co2_usage_supplier_count: "Conteo de Proveedores",
  system_admin_co2_database_co2_usage_financial_model_count: "Conteo de Modelos Financieros",
  system_admin_co2_database_co2_usage_commuter_survey_count: "Conteo de Encuestas de Viajeros",

  system_admin_co2_database_affected_title: "Estas empresas se verán afectadas",
  system_admin_co2_database_affected_subtitle: "Antes de guardar, por favor verifique las siguientes empresas que se verán afectadas por estos cambios.",
  system_admin_co2_database_affected_none_title: "Ninguna empresa se verá afectada por esta actualización",
  system_admin_co2_database_affected_none_subtitle: "Puede proceder haciendo clic en el botón de guardar",
  system_admin_co2_database_affected_company: "Empresa",
  system_admin_co2_database_affected_co2_type_prices: "Precios del Tipo de CO<sub>2</sub>",
  system_admin_co2_database_affected_financial_models: "Modelos Financieros",
  system_admin_co2_database_affected_transport_routes: "Rutas de Transporte",
  system_admin_co2_database_affected_commuter_surveys: "Encuestas de Viajeros",
  system_admin_co2_database_affected_unit_bookings: "Reservas de Unidades",

  //External Databases
  system_admin_co2_database_external_databases: "Bases de Datos Científicas Externas",

  system_admin_co2_database_database_label: "Base de Datos",
  system_admin_co2_database_valid_from: "Válido Desde",
  system_admin_co2_database_valid_until: "Válido Hasta",
  system_admin_co2_database_geography: "Geografía",
  system_admin_co2_database_actions: "Acciones",
  system_admin_co2_database_name: "Nombre",
  system_admin_co2_database_valid_period: "Período de Validez",
  system_admin_co2_database_no_data: "No hay datos disponibles",
  system_admin_co2_database_edit: "Editar",
  system_admin_co2_database_create_unit_booking: "Crear Reserva de Unidad",
  system_admin_co2_database_copy_id: "Copiar ID",
  system_admin_co2_database_select_co2_type: "Seleccionar Tipo de CO₂",
  system_admin_co2_database_delete: "Eliminar",
  system_admin_co2_database_delete_confirmation: "¿Realmente desea eliminar la base de datos ({name})?",
  system_admin_co2_database_delete_success: "Base de datos eliminada con éxito.",
  system_admin_co2_database_delete_error: "Error al eliminar la base de datos.",
  system_admin_co2_database_loading: "Cargando bases de datos...",
  system_admin_co2_database_error_fetch: "Error al obtener bases de datos",
  system_admin_co2_database_no_results: "No se encontraron resultados.",
  system_admin_co2_database_property: "Propiedad",
  system_admin_co2_database_name_en: "Nombre (EN)",
  system_admin_co2_database_name_de: "Nombre (DE)",
  system_admin_co2_database_attributes: "Atributos",
  system_admin_co2_database_searchable: "Buscable",
  system_admin_co2_database_required: "Obligatorio",
  system_admin_co2_database_in_display_name: "En el Nombre Visible",
  system_admin_co2_database_has_ccf_licence: "Licencia CCF"
};
