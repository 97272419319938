/* eslint-disable */
// prettier-ignore

export const messages = {
  profile_edit_profile: "Editar Perfil",
  profile_change_password: "Cambiar Contraseña",
  profile_current_password: "Contraseña actual",
  profile_new_password: "Nueva contraseña",
  profile_new_password_repeat: "Repetir nueva contraseña",
  profile_password_successfully_changed: "Contraseña cambiada con éxito.",
  account_selection_title: "Selección de cuenta",
  account_selection_desc: "Por favor, seleccione la cuenta a la que desea iniciar sesión:",
  profile_2fa: "Autenticación de Dos Factores",
  profile_activation: "Activación",
  profile_2fa_activation_description: "La Autenticación de Dos Factores (2FA) es un protocolo de seguridad que añade una capa extra de protección a las cuentas en línea. En lugar de depender únicamente de una contraseña, 2FA requiere que los usuarios proporcionen una segunda forma de identificación.",
  profile_2fa_activate: "Activar 2FA",
  profile_2fa_activated_successfully: "Autenticación de Dos Factores activada con éxito.",
  profile_2fa_disable: "Desactivar 2FA",
  profile_2fa_reset: "Restablecer 2FA",
  profile_2fa_reset_description: "Restablecer su Autenticación de Dos Factores (2FA) le permite reforzar la seguridad de su cuenta sin desactivar la capa adicional de protección. Considere restablecer 2FA en las siguientes situaciones:",
  profile_2fa_reset_desc_list_item_1: "Dispositivo de Autenticación Perdido o Inaccesible:",
  profile_2fa_reset_desc_list_item_1_desc: "Si el dispositivo utilizado para 2FA se pierde, es robado o es inaccesible, el restablecimiento asegura que solo los dispositivos autorizados tengan acceso a su cuenta.",
  profile_2fa_reset_desc_list_item_2: "Cambio en la Aplicación de Autenticación:",
  profile_2fa_reset_desc_list_item_2_desc: "Si ha cambiado su aplicación de autenticación, restablezca 2FA para actualizar y asegurar la nueva aplicación vinculada a su cuenta.",
  profile_2fa_please_check: "Por favor, verifique su código de 6 dígitos en su aplicación de autenticación.",
  profile_2fa_disable_2fa: "Desactivar Autenticación de Dos Factores",
  profile_2fa_reset_2fa: "Restablecer Autenticación de Dos Factores",
  profile_2fa_reset_2fa_description: "Esta operación invalida su código de recuperación actual y el dispositivo 2FA. Se le pedirá que comience a activar 2FA desde el principio. ¿Está seguro?",
  profile_2fa_enter_recovery_code: "Por favor, ingrese el código de recuperación que guardó en un lugar seguro.",
  profile_2fa_input_recovery_code: "Ingresar código de recuperación",
  profile_2fa_disable_confirmation: "Esta operación no se puede deshacer. ¿Está seguro?",
  profile_2fa_disabled_successfully: "Autenticación de Dos Factores desactivada con éxito de su perfil.",
  profile_2fa_method: "Método",
  profile_2fa_scan: "Escanear",
  profile_2fa_verify: "Verificar",
  profile_2fa_verify_description: "Ingrese el código de un solo uso de su aplicación de autenticación.",
  profile_2fa_backup_codes: "Códigos de Respaldo",
  profile_2fa_choose_verification_method: "Elegir método de verificación",
  profile_2fa_choose_verification_method_description: "Por favor, seleccione el método de verificación a continuación, si no tiene la aplicación puede descargarla en la App Store o Play Store en su dispositivo móvil.",
  profile_2fa_scan_qr_code: "Escanear Código QR",
  profile_2fa_scan_qr_code_description: "Use su dispositivo móvil para escanear el Código QR a continuación con su aplicación de autenticación preferida. Esto generará un código de 6 dígitos para la verificación. Si no tiene una aplicación de autenticación, puede descargar una desde la tienda de aplicaciones de su dispositivo. Este paso adicional asegura una mayor seguridad para su cuenta. ¡Gracias por priorizar la seguridad de su información!",
  profile_2fa_recovery_code: "Código de Recuperación",
  profile_2fa_recovery_code_description: "Use este código de recuperación si pierde su dispositivo. Por favor, guarde este código o imprima este código de recuperación y guárdelo en un lugar seguro, <b>solo puede ver este código de recuperación una vez</b>.",
  profile_2fa_recovery_code_check: "Ya he copiado el código de recuperación en un lugar seguro.",
  profile_2fa_recovery_code_copied_success: "¡Código de Recuperación copiado!",
  profile_2fa_confirm_exit: "¿Está seguro de que desea salir de la configuración de Autenticación de Dos Factores?",
};
