/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_faq_title: "Preguntas Frecuentes y Glosario de Sostenibilidad",
  customer_faq_subtitle: "Encuentra la respuesta a tus necesidades",
  customer_faq_cs_title: "¿No encuentras tu respuesta?",
  customer_faq_cs_button: "Contactar soporte",
  customer_faq_popular_topics: "Temas Populares",
  customer_faq_topic_not_available: "No se encontró ningún tema coincidente",
  customer_faq_topic_not_available_description: "No se encontró ningún tema coincidente, por favor contacta a nuestro soporte para asistencia",
  customer_faq_topics: "Temas",
  customer_faq_keyword_search: "Búsqueda por palabra clave en FAQ",
  customer_faq_q_1: "ESG",
  customer_faq_a_1: "ESG (Medio Ambiente, Social y Gobernanza) se utiliza como un término extendido para RSE (Responsabilidad Social Corporativa) y se considera un criterio para la contribución de las empresas al desarrollo sostenible. Para muchos estándares de sostenibilidad, los criterios ESG forman la base",
  customer_faq_q_2: "Huella de CO<sub>2</sub>",
  customer_faq_a_2: "El enfoque de huella existente se centra en los impactos ecológicos negativos de individuos, organizaciones o países. La huella, por otro lado, está destinada a capturar, medir y evaluar el valor social agregado o los impactos positivos de sostenibilidad de los productos, e incluir las dimensiones sociales y económicas en el análisis.",
  customer_faq_q_3: "Alcance 1, Alcance 2, Alcance 3",
  customer_faq_a_3: "Clasificación de emisiones en emisiones directas e indirectas.<br><br>Alcance 1: Emisiones directas causadas por la combustión de combustibles fósiles en la producción o por procesos dentro de la propia empresa.<br><br>Alcance 2: Emisiones indirectas causadas por la compra de energía o electricidad.<br><br>Alcance 3: Emisiones indirectas causadas por procesos que tienen lugar fuera de la empresa pero que están relacionados con la producción y operación de la empresa. En comparación con los Alcances 1 y 2, las emisiones del Alcance 3 a menudo son más difíciles de medir y controlar.",
  customer_faq_q_4: "Huella de Carbono del Producto",
  customer_faq_a_4: "PCF (Huella de Carbono del Producto) representa las emisiones de gases de efecto invernadero causadas por un producto durante todo su ciclo de vida. Dependiendo del límite del balance, se distingue entre un enfoque de cuna a tumba y un enfoque de cuna a puerta. La Huella de Carbono del Producto no es un derivado directo de la Huella de Carbono Corporativa (CCF dividido por la cantidad producida no es igual a PCF).",
  customer_faq_q_5: "Emisiones de gases de efecto invernadero",
  customer_faq_a_5: "Los gases de efecto invernadero son aquellos gases en la atmósfera con una influencia en el balance energético de la tierra. Así, causan el llamado efecto invernadero. Los gases de efecto invernadero más conocidos, el dióxido de carbono (CO<sub>2</sub>), el metano y el óxido nitroso, están presentes de forma natural en la atmósfera en bajas concentraciones. Sin embargo, debido a diversas fuentes de origen humano, la proporción ha aumentado significativamente desde principios del siglo pasado",
  customer_faq_q_6: "Análisis del Ciclo de Vida",
  customer_faq_a_6: "LCA (Análisis del Ciclo de Vida) representa el análisis del ciclo de vida o la evaluación del ciclo de vida de un producto específico basado en su impacto ambiental total. A diferencia de la Huella de Carbono del Producto, los impactos ambientales se dividen en las llamadas categorías de impacto: » Cambio climático » Agotamiento del ozono » Acidificación » Eutrofización » Agotamiento de recursos » Toxicidad humana » Ecotoxicidad » Uso del suelo » Consumo de agua » Radiación ionizante » Emisiones de partículas La realización de un LCA es muy laboriosa. Un LCA puede utilizarse internacionalmente como una EPD (Declaración Ambiental de Producto) a través de la verificación de terceros.",
  customer_faq_q_7: "Equivalentes de CO<sub>2</sub>",
  customer_faq_a_7: 'CO<sub>2</sub>-eq son una unidad de medida para estandarizar el impacto climático de diferentes gases de efecto invernadero. Para hacer comparables los efectos de diferentes gases de efecto invernadero, se define el llamado "Potencial de Calentamiento Global". Este índice expresa el efecto de calentamiento de una cierta cantidad de un gas de efecto invernadero durante un período de tiempo definido (generalmente 100 años) en comparación con el de CO<sub>2</sub>. Por ejemplo, el metano tiene un impacto climático 28 veces mayor que el CO<sub>2</sub>, pero permanece en la atmósfera por menos tiempo. El impacto climático del óxido nitroso incluso supera al del CO<sub>2</sub> en casi 300 veces.',
  customer_faq_q_8: "Balance de gases de efecto invernadero",
  customer_faq_a_8: "El balance de gases de efecto invernadero es la asignación de los gases de efecto invernadero producidos según ciertos criterios de clasificación. A través de la estandarización de la contabilidad, como el Protocolo de Gases de Efecto Invernadero, los balances de gases de efecto invernadero pueden compararse entre empresas.",
  customer_faq_q_9: "Huella de Carbono Corporativa",
  customer_faq_a_9: "CCF (Huella de Carbono Corporativa) representa las emisiones totales de gases de efecto invernadero causadas por una empresa durante un cierto período de tiempo, expresadas en toneladas métricas de CO<sub>2</sub>-eq. La definición de los límites del balance determina qué emisiones se incluyen o no se incluyen en el balance de gases de efecto invernadero. La clasificación se hace en emisiones directas (Alcance 1) e indirectas (Alcance 2 y Alcance 3).",
  customer_faq_q_10: "Iniciativa de Objetivos Basados en la Ciencia",
  customer_faq_a_10: "La iniciativa de Objetivos Basados en la Ciencia se lanzó en 2015 para ayudar a las empresas a establecer objetivos de reducción de emisiones en línea con la ciencia climática y los objetivos del Acuerdo de París",
  customer_faq_q_11: "Pacto Verde de la UE",
  customer_faq_a_11: 'Como parte del Pacto Verde Europeo, la UE se ha fijado el objetivo vinculante de lograr la neutralidad climática para 2050 a través de la Ley de Cambio Climático Europea. El Pacto Verde apoya esta transición hacia una sociedad justa y próspera con una economía moderna y competitiva. El marco legal para el Pacto Verde lo proporciona el paquete "Fit for 55"',
  customer_faq_q_12: "Informes de Sostenibilidad Corporativa",
  customer_faq_a_12: 'La Directiva de Informes de Sostenibilidad Corporativa (CSRD) adoptada por el Parlamento de la UE en noviembre de 2022 cambiará profundamente el alcance y la naturaleza de los informes de sostenibilidad corporativa. La CSRD amplía significativamente las reglas existentes sobre informes no financieros. Todas las empresas que cotizan en un mercado regulado de la UE (con la excepción de las microempresas) están cubiertas por los nuevos requisitos de informes. Además, todas las empresas no orientadas al mercado de capitales están cubiertas por la CSRD si cumplen dos de los siguientes tres criterios: " Total del balance > 20 millones de euros " Ventas netas > 40 millones de euros " Número de empleados > 250 Se estima que esto afecta a unas 50,000 empresas en la UE',
  customer_faq_q_13: "EU ETS?",
  customer_faq_a_13: "El Esquema de Comercio de Emisiones de la Unión Europea (EU-ETS) se introdujo en 2005 para implementar el acuerdo internacional de protección climática de Kioto y es el instrumento central de protección climática europea. Además de los 27 estados miembros de la UE, Noruega, Islandia y Liechtenstein también se han unido al EU ETS (EU 30). El Reino Unido participó en el EU ETS hasta el 31.12.2020. Desde el 01.01.2021, un sistema nacional de comercio de emisiones está en vigor allí. El EU ETS cubre las emisiones de alrededor de 10,000 plantas en el sector energético y la industria intensiva en energía en toda Europa. Juntas, estas instalaciones causan alrededor del 36% de las emisiones de gases de efecto invernadero en Europa. Desde 2012, el tráfico aéreo intraeuropeo también se ha incluido en el EU ETS.",
  customer_faq_q_14: "Directiva de Reclamaciones Verdes",
  customer_faq_a_14: "La directiva tiene como objetivo eliminar las reclamaciones ambientales engañosas en los mercados de la UE y abordar las preocupaciones sobre el lavado verde estableciendo las primeras reglas detalladas de la UE sobre cómo las empresas deben comercializar sus impactos y desempeño ambiental. Los nuevos requisitos requerirán cambios significativos en la forma en que muchas empresas actualmente demuestran y comunican sus reclamaciones ambientales y gestionan la información sobre su desempeño ambiental. Una vez que la directiva entre en vigor, los estados miembros tendrán 18 meses para transponerla a la legislación nacional",
  customer_faq_q_15: "Taxonomía de la UE",
  customer_faq_a_15: 'Para lograr los objetivos climáticos y energéticos de la UE, las inversiones deben canalizarse hacia proyectos y actividades sostenibles. Por lo tanto, se requiere una definición clara del término "sostenible". El Reglamento de Taxonomía de la UE se creó para este propósito. Es el sistema de clasificación común para actividades económicas sostenibles',
  customer_faq_q_16: "Fit for 55 de la UE",
  customer_faq_a_16: "El paquete Fit for 55 es un paquete legislativo integral, que tiene como objetivo permitir a la Unión alcanzar el objetivo climático de al menos una reducción del 55% de las emisiones respecto a los niveles de 1990 para 2030 y la neutralidad climática para 2050. La CSRD, CSDDD, CBAM y otros son parte de este paquete.",
  customer_faq_q_17: "Ley climática de la UE",
  customer_faq_a_17: "La Ley de Cambio Climático establece el objetivo climático a largo plazo de la UE de neutralidad climática para 2050. Como objetivo intermedio, las emisiones netas de gases de efecto invernadero deben reducirse en un 55% para 2030. La UE y sus estados miembros deben tomar medidas para realizar estos objetivos climáticos y la contribución de la UE al Acuerdo de París",
  customer_faq_q_18: "CSDDD",
  customer_faq_a_18: "La Directiva de Diligencia Debida de Sostenibilidad Corporativa tiene como objetivo prevenir o mitigar los impactos negativos en los derechos humanos y el medio ambiente en las cadenas de valor corporativas. Esto está destinado a contribuir a la transición hacia una economía sostenible, particularmente bajo el principio rector del Pacto Verde. Las empresas deben identificar, prevenir y ser responsables de los impactos negativos causados por sus operaciones. Se deben establecer sistemas de gobernanza y gestión corporativa adecuados para cumplir con este propósito. Se establecerán remedios mejorados para las víctimas de impactos negativos en los derechos humanos y el medio ambiente",
  customer_faq_q_19: "CBAM",
  customer_faq_a_19: "CBAM es un instrumento de protección climática de la UE y es el contraparte del Esquema de Comercio de Emisiones de la UE (EU ETS). La primera obligación de reporte bajo el Reglamento CBAM comienza en octubre de 2023. A partir de 2026, se deberán comprar certificados CBAM para la importación de ciertos bienes cuya producción en terceros países emitió gases de efecto invernadero. La cantidad de certificados a comprar depende de las emisiones generadas durante la producción. El precio por certificado se basa en el precio de los certificados del EU ETS (precio de subasta de EUA).",
  customer_faq_q_20: "Huella de Carbono Corporativa (balance de CO<sub>2</sub>)",
  customer_faq_a_20: "La huella de carbono corporativa mide las emisiones totales de gases de efecto invernadero causadas directa e indirectamente por una persona, organización, evento o producto.",
  customer_faq_q_21: "Protocolo de Gases de Efecto Invernadero (GHG Protocol)",
  customer_faq_a_21: "El Protocolo de Gases de Efecto Invernadero es un estándar internacionalmente reconocido para la contabilidad de emisiones corporativas.",
  customer_faq_q_22: "Principios de la Huella de Carbono Corporativa (balance de CO<sub>2</sub>)",
  customer_faq_a_22: "Relevancia:<br>Se deben considerar todas las fuentes principales de emisiones al preparar una huella de carbono para una empresa. El informe debe presentar una imagen realista dentro y fuera de la empresa.<br><br>Integridad:<br>Se deben considerar todas las fuentes de emisiones relevantes dentro de los límites del sistema.<br><br>Transparencia:<br>Los resultados deben presentarse de manera transparente y claramente comprensible.<br><br>Consistencia:<br>Para la comparabilidad de los resultados a lo largo del período de varios años, se deben mantener las metodologías de contabilidad y los límites del sistema. Los cambios en la metodología o los límites del sistema deben justificarse y reportarse.<br><br>Precisión:<br>Los resultados deben presentarse libres de sesgos e incertidumbres en la medida de lo posible.",
  customer_faq_q_23: "¿Por qué la huella de carbono?",
  customer_faq_a_23: "El balance de CO<sub>2</sub> sirve para identificar las mayores fuentes de emisiones dentro de la empresa y a lo largo de las etapas ascendentes y descendentes de la cadena de valor. Así, forma la base para el desarrollo de una estrategia de protección climática en la que se definen objetivos, medidas y responsabilidades para reducir las emisiones de gases de efecto invernadero. En los años siguientes, se utiliza para verificar si se han alcanzado los objetivos, en qué áreas se ha progresado y en qué áreas hay necesidad de acción para reducir el CO<sub>2</sub>.",
  customer_faq_q_24: "¿Por qué los límites del sistema?",
  customer_faq_a_24: "Una huella de carbono requiere una definición clara de los límites del sistema a los que se refiere la huella de carbono. Esto incluye límites del sistema organizacional y operativo.",
  customer_faq_q_25: "Límites del sistema organizacional",
  customer_faq_a_25: "Describen la unidad organizativa y el período al que se refiere el balance de CO<sub>2</sub>. Los límites del sistema pueden definirse según el control operativo o financiero o según la participación de capital. El límite del sistema organizacional se definió como el alcance de consolidación del Grupo Klett bajo la ley comercial.",
  customer_faq_q_26: "Límites del sistema operativo",
  customer_faq_a_26: 'describen las fuentes de emisiones que se tienen en cuenta dentro de los límites organizacionales. Para delinear diferentes fuentes de emisiones, el Protocolo de Gases de Efecto Invernadero distingue entre tres categorías ("HUELLA GLOBAL").',
  customer_faq_q_27: "Emisiones del Alcance 1",
  customer_faq_a_27: "El ALCANCE 1 muestra todas las emisiones de CO<sub>2</sub> que pueden ser controladas directamente por la empresa que informa (emisiones directas de CO<sub>2</sub>). Estas incluyen la combustión de combustibles fósiles (móviles y estacionarios), las emisiones de CO<sub>2</sub> de procesos químicos y físicos, y las fugas de refrigerantes de los sistemas de aire acondicionado.",
  customer_faq_q_28: "Emisiones del Alcance 2",
  customer_faq_a_28: "En el ALCANCE 2, se informan las emisiones indirectas de CO<sub>2</sub> causadas por la combustión de combustibles fósiles durante la producción de electricidad, calefacción, refrigeración y vapor en proveedores de energía externos. Informar en una categoría separada evita la doble contabilidad al comparar las emisiones de CO<sub>2</sub> de diferentes empresas.",
  customer_faq_q_29: "Emisiones del Alcance 3",
  customer_faq_a_29: "Todas las demás emisiones de CO<sub>2</sub> que no están bajo el control directo de la empresa se informan en el ALCANCE 3 (otras emisiones indirectas de CO<sub>2</sub>). Estas incluyen, por ejemplo, las emisiones de CO<sub>2</sub> asociadas con productos y servicios utilizados o procesados por la empresa que informa. Además, hay emisiones de CO<sub>2</sub> asociadas con el uso de productos y servicios vendidos si se causan emisiones directas de CO<sub>2</sub> en el proceso.",
  customer_faq_q_30: "Adquisición de datos y cálculo",
  customer_faq_a_30: "El cálculo de las emisiones de CO<sub>2</sub> se basa en los datos financieros de la empresa. Estos datos se tomaron directamente de la contabilidad corporativa y se utilizaron como datos de actividad primaria para el cálculo de CO<sub>2</sub>. Además, se incluyeron en los cálculos registros internos y cálculos del cliente como datos secundarios y después de la verificación y comprobación de plausibilidad por parte de GLOBAL CLIMATE.",
  customer_faq_q_31: "Gases de efecto invernadero",
  customer_faq_a_31: "La métrica utilizada para la contabilidad de gases de efecto invernadero es el equivalente de CO<sub>2</sub> (también CO<sub>2</sub>e). Esto se utiliza para convertir no solo el gas CO<sub>2</sub> dañino para el clima, sino también las emisiones de otros gases de efecto invernadero dañinos para el clima en función de su GWP (Potencial de Calentamiento Global) en la cantidad equivalente de CO<sub>2</sub> y equilibrarlos.<br><br><ul><li>Metano (CH4),</li><li>Óxido nitroso (N2O),</li><li>Hexafluoruro de azufre (SF6).</li><li>Hidrofluorocarbonos (HFC y HFC).</li></ul>",
  customer_faq_q_32: "Valor GWP",
  customer_faq_a_32: "Potencial de Calentamiento Global - Potencial de calentamiento global.",
  customer_faq_q_33: "En qué apoya GLOBAL CLIMATE",
  customer_faq_a_33: "<ul><li>Preparación de un balance completo de CO<sub>2</sub> (balance de gases de efecto invernadero)</li><li>Identificación de puntos críticos de CO<sub>2</sub></li><li>Análisis y las emisiones de CO<sub>2</sub></li><li>Control dirigido de medidas de reducción de CO<sub>2</sub> así como en un</li><li>Monitoreo de sostenibilidad de las cadenas de suministro</li></ul>",
  customer_faq_q_34: "¿Qué obtengo al final?",
  customer_faq_a_34: "<ul><li>Balance de CO<sub>2</sub> a prueba de auditoría</li><li>Sello de aprobación de GLOBAL CLIMATE para el año de reporte respectivo</li></ul>",
};
