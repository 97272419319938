import { messages as administration } from "./administration/de";
import { messages as checklist } from "./checklist/de";
import { messages as partners } from "./partners/de";
import { messages as sco2pes } from "./sco2pes/de";
import { messages as esg } from "./esg/de";
import { messages as support } from "./support/de";
import { messages as welcome } from "./welcome/de";
import { messages as surveys } from "./surveys/de";
import { messages as faq } from "./faq/de";

export const messages = {
  ...administration,
  ...checklist,
  ...partners,
  ...sco2pes,
  ...esg,
  ...support,
  ...welcome,
  ...surveys,
  ...faq
};
