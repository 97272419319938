/* eslint-disable */
// prettier-ignore

export const messages = {
  auth_back_to_login: "Back to Login",
  auth_login_success: "Login Successful",
  auth_new_password: "New password",
  auth_new_password_repeat: "New password repeat",
  auth_reset_password: "Reset password",
  auth_forgot_password: "Forgot Password",
  auth_forgot_password_page_description: "Please provide the email address that you used when you signed up for GLOBAL CLIMATE account.\n\n We will send you an email that will allow you to reset your password.",
  auth_login: "Login",
  auth_reset_password_email_sent: "Reset password mail has been sent to you in your inbox. Please follow the steps in that mail.",
  auth_reset_your_password: "Reset Password",
  auth_reset_your_password_description: "The password reset link is invalid. Click here to request a new password reset link.",
  auth_password_changed: "The password has been changed successfully",
  auth_complete_registration: "Complete registration",
  auth_complete_registration_description: "The registration link is expired. Please contact your account admin or the GLOBAL CLIMATE support to request a new registration link.",
  auth_confirm_password: "Confirm password",
  auth_registration_completed: "Registration completed",
  auth_invitation_email_sent: "Invitation Email sent to the user.",
  auth_company_registration: "Registration",
  auth_company_information: "Company Information",
  auth_company_name: "Company Name",
  auth_agree_with: "I've read and understand the {0}",
  auth_terms_conditions: "Terms & Conditions",
  auth_data_conditions: "Data Security Conditions",
  auth_agreement_warning: "The conditions for registering must be agreed to",
  auth_not_registered: "Not registered yet?",
  auth_register: "Register",
  auth_register_and_login: "Register & Log In",
  auth_invitation_information: "Invitation Information",
  auth_invitation_code: "Invitation Code",
  auth_invitation_information_description: "To be able to register on the GLOBAL SUITE you have to provide the Invitation-Code you received in your invitation E-Email.",
  auth_personal_data: "Personal Data",
  auth_lost_my_device: "Lost My Device",
  auth_lost_device: "Lost your device? Use recovery code",

  auth_sales_information_form_title: "The use of the GLOBAL SUITE is free of charge for you as a supplier. However, companies are often subject to reporting obligations in which we can support you.",
  auth_sales_information_form_body: "Did you know that companies from Germany with more than 250 employees are obligated to create a CO<sub>2</sub> emissions balance? If you're interested in our products, we kindly ask you to answer the following questions:",
  auth_sales_information_form_question_1: "Are you insterested in a greenhouse gas balance?",
  auth_sales_information_form_question_2: "Are you interested in a solution to fulfill the LkSG reporting obligations?",
  auth_sales_information_form_question_3: "Please fill your telephone number we will inform you about our products",
  auth_sales_information_telephone_number: "Telephone number",
};
