/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_welcome_news: "Noticias",
  customer_welcome_news_filter: "Filtrar",
  customer_welcome_news_summary_title: "Hay algunas noticias y actualizaciones sobre nuestros productos desde su último inicio de sesión",
  customer_welcome_news_tags: {
    GENERAL: "General",
    MAINTENANCE: "Mantenimiento",
    ALERT: "Alerta",
    CCF: "CCF",
    CSRD: "CSRD",
    LKSG: "LKSG",
    ESG: "ESG",
    PCF: "PCF",
    AUTOMATION_API: "API de Automatización",
  },
  customer_welcome_benefit_when_using_sco2pes: "Contabilidad de Huella Global CO<sub>2</sub>",
  customer_welcome_sco2pes_benefit_1: "Contabilidad de CO<sub>2</sub> integrada en los procesos y basada en datos financieros según el protocolo GHG",
  customer_welcome_sco2pes_benefit_2: "Uso de valores reales de su empresa en lugar de estimaciones y estadísticas",
  customer_welcome_sco2pes_benefit_3: "Resultados en tiempo real",
  customer_welcome_sco2pes_benefit_4: "Balance completo de CO<sub>2</sub> (Alcance 1, Alcance 2 y Alcance 3)",
  customer_welcome_sco2pes_benefit_5: "Base de datos de CO<sub>2</sub> científicamente verificada",
  customer_welcome_sco2pes_benefit_6: "Contabilidad personalizada de transacciones comerciales relevantes para el CO<sub>2</sub>",
  customer_welcome_sco2pes_benefit_7: "Interfaz con SAP, Microsoft Dynamics y DATEV",
  customer_welcome_dialog_title: "Bienvenido a GLOBAL SUITE",
  customer_welcome_dialog_description: "Se ha registrado con éxito en GLOBAL SUITE.<br />Siéntase libre de explorar la plataforma. Puede encontrar sus encuestas abiertas en el menú de navegación bajo Encuestas.",
  customer_freemium_dialog_title: "¡Hola, es un placer tenerte aquí!",
  customer_freemium_dialog_description: "Descubra nuestra herramienta CSRD y obtenga una primera impresión de <br> GLOBAL SUITE.</br> <br /> <b>Así es como funciona:</b> <ol style='justify-items: left'><li>Haga clic en <b>GLOBAL COMPLIANCE</b> en el menú de navegación izquierdo.</li><li>Seleccione la <b>herramienta CSRD</b>.</li><li>Pruebe las tres funciones principales.</li></ol> <br/> ¡Disfrute explorando el software! 😊<br/> <b>¿Alguna pregunta o comentario?</b> <br/> ¡No dude en contactarnos! <br/> <br/> Su licencia es válida hasta {0}",
  customer_freemium_dialog_button_demo: "Reservar una demo",
  customer_freemium_dialog_go_to_csrd: "Ir a la herramienta CSRD",
  customer_welcome_news_update: "Nueva Actualización",
  customer_welcome_banner_title_1: "Bienvenido a",
  customer_welcome_banner_title_2: "GLOBAL SUITE",
  customer_welcome_banner_title_3: "La solución de software todo en uno para informes de sostenibilidad <b>eficientes, conformes y a prueba de auditorías</b>",
  customer_welcome_survey_title_1: "Tiene encuestas por completar",
  customer_welcome_survey_subtitle_plural: "Tiene <strong>{0}</strong> encuestas abiertas",
  customer_welcome_survey_subtitle_singular: "Tiene <strong>{0}</strong> encuesta abierta",
  customer_welcome_update_title: "La nueva actualización ha sido lanzada",
  customer_welcome_update_subtitle: "Mejoras de GLOBAL SUITE",
  customer_welcome_product_title: "Nuestra Solución",
  customer_welcome_product_subtitle: "Nuestro software cumple totalmente con los requisitos regulatorios ESG de CSRD y LkSG y aporta un máximo de eficiencia en su Contabilidad y Reportes de CO<sub>2</sub>.",
  customer_welcome_product_scopes_description: "Contabilidad de CO<sub>2</sub> totalmente automatizada en Alcance 1, 2, 3 y cálculo de Huellas de Carbono de Productos a lo largo de todo el ciclo de vida del producto.",
  customer_welcome_product_scores_description: "Cumpla con las obligaciones de informes legales de manera completa, legalmente conforme y a prueba de auditorías.",
  customer_welcome_ccf_product_link: "Herramienta-CCF",
  customer_welcome_pcf_product_link: "Herramienta-PCF",
  customer_welcome_sbt_product_link: "Herramienta-SBT",
  customer_welcome_esg_product_link: "Herramienta-CSRD",
  customer_welcome_lksg_product_link: "Herramienta-LkSG",
  customer_welcome_csrd_product_link: "Herramienta-ESG",
  customer_welcome_sales_title: "Contacte con el equipo de ventas para más información",
  customer_welcome_sales_subtitle: "Esperamos tener noticias suyas. Solo contáctenos por teléfono, correo electrónico o formulario de contacto o reserve una demo directamente. ¡Estamos listos! ",
  customer_welcome_sales_cta_1: "Contactar con ventas",
  customer_welcome_sales_cta_2: "Reservar una demo ahora",
  customer_welcome_sales_dialog_title: "Esperamos tener noticias suyas",
  customer_welcome_sales_dialog_description: "No dude en llamarnos o contactarnos por correo electrónico. Estamos aquí y esperamos una primera reunión para conocerle!",
  customer_welcome_sales_support_ticket: "Ticket de soporte",
};
