/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_esg_ratingRequest_title: "Solicitud de Calificación ESG",
  customer_esg_ratingRequest_back_to_survey_page: "Volver a la página de la encuesta",
  customer_esg_ratingRequest_overview: "Visión general",
  customer_esg_ratingRequest_survey: "Encuesta",
  customer_esg_ratingRequest_result: "Resultado",
  customer_esg_ratingRequest_progress: "Progreso",
  customer_esg_ratingRequest_open_survey: "Abrir encuesta",
  customer_esg_ratingRequest_rating_information_title: "Información de Calificación",
  customer_esg_ratingRequest_rating_information_1: "La calificación ESG se ha establecido en todo el mundo como el índice de calidad para cadenas de suministro sostenibles y calificaciones de empresas. {companyName} solicita que su empresa sea evaluada por GLOBAL CLIMATE. Debe responder la encuesta en la siguiente pestaña, que incluye preguntas sobre aspectos ambientales, sociales y de gobernanza. Dado que responder algunas de las preguntas puede requerir investigación en su empresa, el progreso se guarda después de responder cada pregunta y puede continuar completando los cuestionarios en un momento posterior.<br/><br/>Las respuestas se calificarán automáticamente después de enviar la encuesta y el resultado de la calificación se compartirá con <b>{companyName}</b>.",
  customer_esg_ratingRequest_rating_information_2: "Su empresa puede obtener un análisis detallado de su puntuación, KPIs y una tarjeta de puntuación al comprar una licencia. Nuestro equipo de expertos analizará sus resultados en detalle, por ejemplo, validando los documentos y certificados que se hayan proporcionado para algunas preguntas. Después del proceso de revisión, GLOBAL CLIMATE le informará.",
  customer_esg_ratingRequest_sender_information_title: "Información del Remitente",
  customer_esg_ratingRequest_sender: "Remitente",
  customer_esg_ratingRequest_invitation_message: "Mensaje de Invitación",
  customer_esg_ratingRequest_survey_questionnaires: "Cuestionarios de la Encuesta",
  customer_esg_ratingRequest_submit_info: "Debe responder todas las preguntas requeridas (sin errores de validación) antes de poder enviar la encuesta.",
  customer_esg_ratingRequest_submit_valid: "Todas las preguntas están respondidas y son válidas - debe enviar activamente la encuesta para obtener los resultados.",
  customer_esg_ratingRequest_submit_warning: "No puede modificar las respuestas porque la encuesta está enviada.",
  customer_esg_ratingRequest_result_in_review_title: "Resultado aún en análisis",
  customer_esg_ratingRequest_result_in_review_subtitle: "Nuestro equipo de expertos aún está analizando sus respuestas. Recibirá una notificación por correo electrónico una vez que los resultados estén disponibles.",
  customer_esg_ratingRequest_result_answer_survey: "Responder encuesta",
  customer_esg_ratingRequest_upgrade_license: "Actualizar licencia",
  customer_esg_ratingRequest_banner_text: "Benefíciese de una puntuación detallada,<br />una tarjeta de puntuación compartible, KPIs y más",
  customer_esg_ratingRequest_license_package_1: "Paquete de Licencia",
  customer_esg_ratingRequest_license_package_2: "Actualice su licencia",
  customer_esg_ratingRequest_license_package_description: "Sin una licencia, su puntuación general se compartirá con <strong>{0}</strong>, pero no podrá usar la puntuación obtenida por su cuenta. Para obtener una puntuación detallada, una tarjeta de puntuación compartible y KPIs de los resultados de su calificación ESG, debe comprar una licencia. Nuestro equipo de expertos analizará sus respuestas en detalle, por ejemplo, validando todos los documentos y certificados que haya proporcionado.",
  customer_esg_ratingRequest_rating_license: "Licencia de Calificación ESG",
  customer_esg_ratingRequest_plus_vat: "más IVA",
  customer_esg_ratingRequest_small_companies: "Pequeñas Empresas",
  customer_esg_ratingRequest_small_companies_1: "Análisis detallado de las respuestas de una calificación ESG",
  customer_esg_ratingRequest_small_companies_2: "Tarjeta de puntuación compartible",
  customer_esg_ratingRequest_small_companies_3: "KPIs",
  customer_esg_ratingRequest_medium_companies: "Empresas Medianas",
  customer_esg_ratingRequest_medium_companies_1: "Análisis detallado de las respuestas de una calificación ESG",
  customer_esg_ratingRequest_medium_companies_2: "Tarjeta de puntuación compartible",
  customer_esg_ratingRequest_medium_companies_3: "KPIs",
  customer_esg_ratingRequest_large_companies: "Grandes Empresas",
  customer_esg_ratingRequest_large_companies_1: "Análisis detallado de las respuestas de una calificación ESG",
  customer_esg_ratingRequest_large_companies_2: "Tarjeta de puntuación compartible",
  customer_esg_ratingRequest_large_companies_3: "KPIs",
  customer_esg_ratingRequest_small_license: "Licencia Pequeña de GLOBAL COMPLIANCE",
  customer_esg_ratingRequest_medium_license: "Licencia Mediana de GLOBAL COMPLIANCE",
  customer_esg_ratingRequest_large_license: "Licencia Grande de GLOBAL COMPLIANCE",
  customer_esg_ratingRequest_license_description_1: "Por favor, valide y adapte la información de facturación:",
  customer_esg_ratingRequest_license_description_2: "Al continuar, se enviará una factura a la dirección de correo electrónico proporcionada.<br/><br/>Puede pagar a través de nuestro proveedor de servicios de pago Stripe (tarjeta de crédito) o mediante transferencia bancaria. Si transfiere el monto directamente, la licencia se desbloqueará tan pronto como se registre su pago.",
  customer_esg_ratingRequest_finish_license_title_1: "¡Gracias por ordenar una licencia de GLOBAL COMPLIANCE!",
  customer_esg_ratingRequest_finish_license_title_2: "Complete su pago para activar la licencia",
  customer_esg_ratingRequest_finish_license_description_1_1: "La factura se ha enviado por correo electrónico a su dirección de correo electrónico ({0}). La factura también se puede descargar aquí.",
  customer_esg_ratingRequest_finish_license_description_1_2: "Le enviaremos la factura al correo electrónico proporcionado ({0}), pero también puede descargarla aquí.",
  customer_esg_ratingRequest_finish_license_description_2: "Su licencia se activará inmediatamente si el pago se realiza a través de nuestro proveedor de servicios de pago Stripe",
  customer_esg_ratingRequest_finish_license_description_3: "Si transfiere el monto directamente a la cuenta bancaria especificada en la factura, la licencia se desbloqueará tan pronto como se reciba su pago.",
  customer_esg_ratingRequest_invoice: "Factura",
  customer_esg_ratingRequest_finish_payment_banner: "Complete su pago para activar la licencia",
  customer_esg_ratingRequest_finish_payment: "Completar pago",
  customer_esg_ratingRequest_start_survey: "Iniciar encuesta",

  customer_esg_selfAssessment_title: "Autoevaluación ESG",
  customer_esg_selfAssessment_overview_title: "Visión general",
  customer_esg_selfAssessment_overview_info1: "La calificación ESG se ha establecido en todo el mundo como el índice de calidad para cadenas de suministro sostenibles y calificaciones de empresas.",
  customer_esg_selfAssessment_overview_info2: "Para ser evaluada por GLOBAL CLIMATE, su empresa debe responder la encuesta en la siguiente pestaña, que incluye preguntas sobre aspectos ambientales, sociales y de gobernanza. Dado que responder algunas de las preguntas puede requerir investigación en su empresa, el progreso se guarda después de responder cada pregunta y puede continuar completando los cuestionarios en un momento posterior.",
  customer_esg_selfAssessment_overview_info3: "Después de enviar las respuestas de la encuesta, GLOBAL CLIMATE las revisará. Global Climate también validará los documentos y certificados que deben proporcionarse para algunas preguntas. Después del proceso de revisión, GLOBAL CLIMATE le informará sobre su calificación final.",
  customer_esg_selfAssessment_overview_information: "Información",
  customer_esg_selfAssessment_overview_process: "Proceso",
  customer_esg_selfAssessment_overview_step1: "Calificación iniciada",
  customer_esg_selfAssessment_overview_step2: "Encuesta en progreso",
  customer_esg_selfAssessment_overview_step3: "Respuestas en revisión",
  customer_esg_selfAssessment_overview_step4: "Resultados recibidos",
  customer_esg_selfAssessment_survey_title: "Encuesta",
  customer_esg_selfAssessment_survey_alert1: "Todas las preguntas requeridas deben ser respondidas (sin errores de validación) antes de poder enviar la encuesta.",
  customer_esg_selfAssessment_survey_alert2: "Todas las preguntas están respondidas y son válidas - la encuesta debe ser enviada activamente para obtener los resultados.",
  customer_esg_selfAssessment_result_title: "Resultado",
  customer_esg_selfAssessment_result_title1: "Resultado no disponible",
  customer_esg_selfAssessment_result_subtitle1: "El resultado no está disponible porque la encuesta no está completada. Nuestro equipo de expertos analizará sus resultados después de que haya enviado la encuesta.",
  customer_esg_selfAssessment_result_title2: "Análisis de expertos en curso",
  customer_esg_selfAssessment_result_subtitle2: "Nuestro equipo de expertos está analizando las respuestas. Recibirá una notificación por correo electrónico una vez que los resultados estén disponibles.",
  customer_esg_selfAssessment_result_subtitle3: "Resultado de la Calificación",
  customer_esg_selfAssessment_result_scorecard: "Tarjeta de puntuación",
  customer_esg_selfAssessment_submitDialog_title: "Enviar encuesta",
  customer_esg_selfAssessment_submitDialog_desc1: "Después de enviar la encuesta, no podrá modificar las respuestas. ¿Desea enviar la encuesta?",
  customer_esg_selfAssessment_submitDialog_desc2: "Sus respuestas han sido enviadas. Recibirá un correo electrónico una vez que los resultados estén disponibles.",

  customer_surveys_deadline: "Fecha límite",
  customer_surveys_goToSurvey: "Ir a la encuesta",
  customer_surveys_initiator: "Iniciador",
  customer_surveys_noDeadline: "Sin fecha límite",
  customer_surveys_selfInitiated: "Auto iniciado",
  customer_surveys_subtitle: "La Plataforma de Encuestas GLOBAL CLIMATE permite a las organizaciones responder encuestas para autoevaluaciones y encuestas solicitadas por sus organizaciones asociadas.<br>Los socios solicitan a su organización que responda una encuesta para poder cumplir con sus requisitos legales de LkSG o al realizar un Análisis de Riesgo ESG.<br>Por favor, responda las encuestas antes de la fecha límite. Puede pausar la respuesta a las encuestas en cualquier momento, el progreso se guardará.",
  customer_surveys_title: "Plataforma de Encuestas",

  // LkSG Rating Request
  customer_lksgRatingRequest_title: "Solicitud de Calificación LkSG",
  customer_lksgRatingRequest_back_to_survey_page: "Volver a la página de la encuesta",
  customer_lksgRatingRequest_overview: "Visión general",
  customer_lksgRatingRequest_survey: "Encuesta",
  customer_lksgRatingRequest_progress: "Progreso",
  customer_lksgRatingRequest_open_survey: "Abrir encuesta",
  customer_lksgRatingRequest_rating_information_title: "Información",
  customer_lksgRatingRequest_rating_information_1: "{companyName} solicita a su empresa que responda varias preguntas para permitirles cumplir con sus requisitos legales de LkSG. Puede responder la encuesta en la siguiente pestaña. Dado que responder algunas de las preguntas puede requerir investigación en su empresa, el progreso se guarda después de responder cada pregunta y puede continuar completando los cuestionarios en un momento posterior.<br/><br/>Las respuestas se calificarán automáticamente después de enviar la encuesta y el resultado de la calificación se compartirá con <b>{companyName}</b>.",
  customer_lksgRatingRequest_sender_information_title: "Información del Remitente",
  customer_lksgRatingRequest_sender: "Remitente",
  customer_lksgRatingRequest_invitation_message: "Mensaje de Invitación",
  customer_lksgRatingRequest_survey_questionnaires: "Cuestionarios de la Encuesta",
  customer_lksgRatingRequest_submit_info: "Debe responder todas las preguntas requeridas (sin errores de validación) antes de poder enviar la encuesta.",
  customer_lksgRatingRequest_submit_valid: "Todas las preguntas están respondidas y son válidas - debe enviar activamente la encuesta para obtener los resultados.",
  customer_lksgRatingRequest_submit_warning: "No puede modificar las respuestas porque la encuesta está enviada.",
  customer_lksgRatingRequest_result_answer_survey: "Responder encuesta",
  customer_lksgRatingRequest_start_survey: "Iniciar encuesta",
  customer_lksgRatingRequest_survey_status_1: "Encuesta iniciada",
  customer_lksgRatingRequest_survey_status_2: "Encuesta comenzada",
  customer_lksgRatingRequest_survey_status_3: "Encuesta enviada",
  customer_lksgRatingRequest_survey_start: "Iniciar Encuesta LkSG",
  customer_lksgRatingRequest_survey_text_1: "Al responder las preguntas de la encuesta, el progreso se guardará automáticamente. Por favor, responda todas las preguntas necesarias.",
  customer_lksgRatingRequest_survey_text_2: "Sus respuestas serán analizadas por GLOBAL CLIMATE al final de la encuesta y las respuestas se enviarán automáticamente a su socio.",
  customer_lksgRatingRequest_survey_text_3: "Si tiene alguna pregunta, puede contactarnos a través del sistema de tickets de soporte.",
  customer_lksgRatingRequest_survey_text_4: "¿Desea iniciar la encuesta? Su socio <u>{partnerName}</u> será informado automáticamente de que está participando en la encuesta.",
  customer_lksgRatingRequest_survey_submit_survey: "Enviar encuesta",
  customer_lksgRatingRequest_survey_submitted_message: "Sus respuestas han sido enviadas.",
  customer_lksgRatingRequest_survey_submit_description: "Después de enviar la encuesta, no podrá modificar las respuestas. ¿Desea enviar la encuesta?",
  customer_lksgRatingRequest_survey_complete_survey_description_2: "Para completar la encuesta, todas las preguntas deben ser respondidas sin errores de validación.",
  customer_lksgRatingRequest_survey_number_of_questions: "Número de preguntas",
  customer_lksgRatingRequest_survey_number_of_invalid_answered_questions: "Número de preguntas con error de validación",
  customer_lksgRatingRequest_survey_and_more: "Y {0} más...",
  customer_lksgRatingRequest_survey_uploaded_documents: "Documentos Subidos",
  customer_lksgRatingRequest_survey_question: "Pregunta {0}",
};
