/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_scopes_suppliers_emission_relevant: "Emission Relevant",
  customer_scopes_suppliers_transport_routes: "Transport Routes",
  customer_scopes_suppliers_transport_route: "Transport Route",
  customer_scopes_suppliers_edit_details: "Edit Details",
  customer_scopes_suppliers_co2_relevant: "CO<sub>2</sub> Relevant",

  // Welcome
  customer_sco2pes_welcome: "Welcome to GLOBAL FOOTPRINT",
  customer_sco2pes_welcome_message: "Welcome to the GLOBAL FOOTPRINT module, the tool for calculating your corporate carbon footprint (CCF).<br/>With the help of GLOBAL FOOTPRINT, you can easily and instantly display and analyse data that you have already entered at the click of a button.<br/>In addition, you can independently enter data and make bookings for a desired reporting period.<br/>As a result of carbon accounting with GLOBAL FOOTPRINT, you receive a greenhouse gas balance report in accordance with the guidelines of the Greenhouse Gas Protocol Corporate Accounting and Reporting Standard (GHG Protocol).",
  customer_sco2pes_your_overview: "Your Overview",
  customer_sco2pes_your_feedback: "Your Feedback",
  customer_sco2pes_your_feedback_message: "We are constantly developing and improving our software. Therefore, we welcome any kind of feedback and suggestions for improvement and try to implement them as soon as possible.",
  customer_sco2pes_give_feedback: "Give Feedback",
  customer_sco2pes_type_feedback: "Type your feedback here...",
  customer_sco2pes_feedback_success: "Thank you for Sharing Your Insights!",

  // License info
  customer_ccf_license_required: "Access restricted: License required",
  customer_ccf_license_info: "Your company doesn't have a GLOBAL FOOTPRINT license.<br>If you are interested in the product don't hesitate to contact the GLOBAL CLIMATE Team.",
  customer_sco2pes_further_info: "Further information can be found here:",

  customer_sco2pes_scopes_info: "GLOBAL FOOTPRINT Info",
  customer_sco2pes_scope_1_description: "Scope 1 includes direct greenhouse gas emissions caused directly by an organization. This typically includes emissions from sources within the company's control. Typical Scope 1 emissions include combustion processes of fuels such as gas, oil or coal in company-owned facilities and vehicles. Other examples are emissions from industrial processes or from the incineration of waste. Scope 1 emissions are measured in tonnes of CO<sub>2</sub> equivalent (tCO<sub>2</sub>e).",
  customer_sco2pes_scope_2_description: "Scope 2 refers to indirect greenhouse gas emissions that arise from the procurement and consumption of electrical energy, steam or heat. These emissions are not generated directly on-site, but through the generation of the energy used by a company. Scope 2 emissions arise, for example, when fossil fuels are burned to generate electricity in power plants. Businesses measure these emissions by multiplying purchased energy use by specific emission factors provided by energy suppliers.",
  customer_sco2pes_scope_3_description: "Scope 3 includes all other indirect greenhouse gas emissions that result from a company's operations but are beyond its direct control. This scope covers the entire supply chain and other external activities, including emissions associated with the extraction and manufacture of materials, the transportation, use or disposal of products, and employees' business trips and daily commutes. Scope 3 emissions are often the largest source of a company's greenhouse gas emissions. Recording and evaluating Scope 3 emissions requires collaboration with suppliers and other relevant partners.",
  customer_sco2pes_summary_info: "By categorizing greenhouse gas emissions into these scopes, the GHG Protocol enables companies to comprehensively record and report their emissions. This enables companies to work specifically to reduce emissions by optimizing their internal processes, switching to renewable energy, using more efficient transport methods and building more sustainable supply chains. It contributes to the transparency and comparability of emission data and supports companies in the development and implementation of climate protection strategies.",

  // CO2 database
  customer_sco2pes_co2_database_title: "CO<sub>2</sub> Database",
  customer_sco2pes_co2_database_subtitle: "With your individual CO<sub>2</sub> database, you can create and manage your own CO<sub>2</sub> types. Furthermore, you will find all CO<sub>2</sub> types that have already been created for you.<br/>If you already know the details necessary for the creation of the CO<sub>2</sub> type (such as emissions per unit, weight per unit, origin), you can create your own CO<sub>2</sub> types via \"NEW CO<sub>2</sub> TYPE\".",
  customer_sco2pes_co2_database_new_co2_type: "New CO<sub>2</sub> Type",
  customer_sco2pes_co2_database_type_description: "Type Description",
  customer_sco2pes_co2_database_type_name: "CO<sub>2</sub> Type name",
  customer_sco2pes_co2_database_unit: "Unit",
  customer_sco2pes_co2_database_co2_equivalent: "CO<sub>2</sub> Equivalent",
  customer_sco2pes_co2_database_emissions_per_unit: "Emissions per Unit",
  customer_sco2pes_co2_database_weight_per_unit: "Weight (kg) per Unit",
  customer_sco2pes_co2_database_do_you_really_want_to_delete_the_co2_type: "Do you really want to delete the CO<sub>2</sub> Type",
  customer_sco2pes_co2_database_description_in_english: "Description in English",
  customer_sco2pes_co2_database_description_in_german: "Description in German",
  customer_sco2pes_co2_database_edit_co2_type: "Edit CO<sub>2</sub> Type",
  customer_sco2pes_co2_database_co2_type_successfully_created: "CO<sub>2</sub> Type successfully created",
  customer_sco2pes_co2_database_co2_type_successfully_deleted: "CO<sub>2</sub> Type successfully deleted",
  customer_sco2pes_co2_database_co2_type_successfully_edited: "CO<sub>2</sub> Type successfully edited",
  customer_sco2pes_co2_database_origin_database: "Origin Database",
  customer_sco2pes_co2_database_origin_database_reference: "Origin Database Reference",
  customer_sco2pes_co2_database_origin_database_description: "For maintenance of the CO<sub>2</sub> Types and later auditing it is necessary to store a reference to the origin of the CO<sub>2</sub> Equivalent.",
  customer_sco2pes_co2_database_versions: "Versions",
  customer_sco2pes_co2_database_version: "Version",
  customer_sco2pes_co2_database_from: "From",
  customer_sco2pes_co2_database_until: "Until",
  customer_sco2pes_co2_database_dialog_date_not_valid: "<strong>From</strong> must be before or the same as <strong>Until</strong>",
  customer_sco2pes_co2_database_dialog_kg_not_valid: "Kg Equivalent must be 1 if the unit is <strong>kg</strong>",
  customer_sco2pes_co2_database_version_delete_confirmation_title: "Delete Version",
  customer_sco2pes_co2_database_version_delete_confirmation_message: "Are you sure you want to delete this version?",
  customer_sco2pes_co2_database_no_version_message: "At least one version is required",
  customer_sco2pes_co2_database_versions_not_valid: "There must always be exactly one version per order period (no overlapping, no gaps). The first version must be valid from 0000-01, the last version must be valid until 9999-12.",
  customer_sco2pes_co2_database_versions_kg_not_valid: "Kg Equivalent must be 1 if the unit is <strong>kg</strong>",
  customer_sco2pes_co2_database_new_version: "New Version",
  customer_sco2pes_co2_database_edit_version: "Edit Version",
  customer_sco2pes_co2_database_created_at: "Created At",
  customer_sco2pes_co2_database_versions_count: "Versions Count",
  customer_sco2pes_co2_database_create_unit_booking: "Create Unit Booking",
  customer_sco2pes_co2_database_amount: "Amount",
  customer_sco2pes_co2_database_distance: "Distance",
  customer_sco2pes_co2_database_order_id: "Order Id",
  customer_sco2pes_co2_database_supplier: "Supplier",
  customer_sco2pes_co2_database_transport_route: "Transport route",
  customer_sco2pes_co2_database_booking_creation_success_title: "CO<sub>2</sub> Booking successfully created!",
  customer_sco2pes_co2_database_booking_creation_success_show_bookings: "Show Bookings",
  customer_sco2pes_co2_database_distance_multiply_description: "The total distance is the product of amount and distance",
  customer_sco2pes_co2_database_co2_type_desc: "Emission relevant data can be versioned over time. Versions can be created in the time resolution of months. Versions must cover all possible months, starting with \"Start\" to \"End\".",
  customer_sco2pes_co2_database_type_valid_versions: "Validity period of versions have no gap and no overlap",
  customer_sco2pes_co2_database_type_valid: "All required data provided",
  customer_sco2pes_co2_database_import_title: "Import CO<sub>2</sub> Types",
  customer_sco2pes_co2_database_import_subtitle: "CO<sub>2</sub> Types can be imported via excel file. Use this excel template for file upload:",
  customer_sco2pes_co2_database_co2_category: "CO<sub>2</sub> Category",
  customer_sco2pes_co2_database_freetext_search: "Freetext Search (ID/Name)",
  customer_sco2pes_co2_database_type_active_checkbox: "CO<sub>2</sub>-Type active",
  customer_sco2pes_co2_database_documentation: "Documentation",
  customer_sco2pes_co2_database_external_co2_emissions_factor: "Emissiondatabase",
  customer_sco2pes_co2_database_manual_data_entry: "Manual Data Entry",
  customer_sco2pes_co2_database_scale_factor: "Scale Factor",
  customer_sco2pes_co2_database_emission_factor_imported: "Emission factor imported from emission database",
  customer_sco2pes_co2_database_database: "Database",
  customer_sco2pes_co2_database_emission_factor_name: "Emission factor name",
  customer_sco2pes_co2_database_emission_factor_value: "Emission factor value",
  customer_sco2pes_co2_database_scale_factor_warning: "Ensure that the value for Scale Factor is properly set. Set the value with regards to the units.",
  customer_sco2pes_co2_database_scale_factor_description: "If the unit of the CO<sub>2</sub> type does not match the emission database, the scale factor can be applied to adjust the emission factor accordingly.",
  customer_sco2pes_co2_database_change_emission_factor: "Change emission factor",
  customer_sco2pes_co2_database_version_emission_dialog_heading: "C0<sub>2</sub> Emission Factors",
  customer_sco2pes_co2_database_scope_warning: "Please note that when calculating emissions in Scope 1 and/or Scope 2, you should also create a CO<sub>2</sub> Type for Scope 3.3 in order to calculate the corresponding upstream chain emissions separately. Please create another CO<sub>2</sub> Type for this purpose.",
  customer_sco2pes_co2_database_data_origin: "Data Origin",
  customer_sco2pes_co2_database_view_co2_type: "View CO<sub>2</sub> Type",

  customer_sco2pes_co2_type_tags: "CO<sub>2</sub> Type Tags",
  customer_sco2pes_co2_type_tags_subtitle: "CO<sub>2</sub> Type Tags are a way to add custom information to each CO<sub>2</sub> Type. It empowers you to connect/integrate CO<sub>2</sub> Types of Global Climate database to your own system.",
  customer_sco2pes_co2_type_tags_tagging_unavailable: "Tagging is not available to your company yet. Please first define some tags.",
  customer_sco2pes_co2_type_tags_add_tag: "Add tag",
  customer_sco2pes_co2_type_tags_enter_edit_mode: "Enter edit mode",
  customer_sco2pes_co2_type_tags_enter_edit_mode_tooltip: "Your changes will save automatically when you start typing on the input field.",
  customer_sco2pes_co2_type_tags_exit_edit_mode: "Exit edit mode",
  customer_sco2pes_co2_type_tags_edit_mode_info: "Your changes will save automatically as you are typing inside the table.",
  customer_sco2pes_co2_type_tags_deletion_confirmation: "Are you sure want to delete this CO<sub>2</sub> Type Tag permanently?",
  customer_sco2pes_co2_type_tags_edit_tag: "Edit Tag",
  customer_sco2pes_co2_type_tags_search: "Search (ID, Name, Tag)",

  customer_sco2pes_configuration_information: "Information",
  customer_sco2pes_configuration_contact_support: "If there is not data shown please contact the support team.",
  customer_sco2pes_configuration_tag_definitions: "Tag Definitions",
  customer_sco2pes_configuration_composite_keys: "Composite Keys",
  customer_sco2pes_configuration_selection_policies: "Selection Policies",
  customer_sco2pes_configuration_column_index: "Column Index",
  customer_sco2pes_configuration_unique: "Unique",
  customer_sco2pes_configuration_key_chain: "Key chain",
  customer_sco2pes_configuration_key_type: "Key type",
  customer_sco2pes_configuration_key_name: "Key name",
  customer_sco2pes_configuration_required_tag_defs: "Required Tag Definitions",
  customer_sco2pes_configuration_tags: "Tags",
  customer_sco2pes_configuration_json_key: "JSON key",
  customer_sco2pes_configuration_in_use_in_automation_api: "in use in Automation API",
  customer_sco2pes_configuration_esrs_mapping: "ESRS Mapping",
  customer_sco2pes_configuration_esrs_mapping_description_1: "With ESRS mapping, bookings from the CCF can be enriched with ESRS metrics for the CSRD, which are made available via the Automation API V5.",
  customer_sco2pes_configuration_esrs_mapping_description_2: "For each ESRS metric, one or more scopes, CO<sub>2</sub> categories, and/or CO<sub>2</sub> types can be configured. All bookings that match these configurations will be enriched with the corresponding ESRS metric.",
  customer_sco2pes_configuration_esrs_mapping_description_3: "The mapping configuration can be downloaded and edited as an Excel file. After editing, the file can be uploaded again. During the upload, the columns are validated, and the configuration is saved. In the event of validation errors, the configuration will not be saved, and the previous configuration will remain in place.",
  customer_sco2pes_configuration_esrs_mapping_description_4: "Please note: For better readability, the downloaded Excel file is enriched with additional columns. These columns are not relevant for the configuration and can be ignored during upload.",
  customer_sco2pes_configuration_esrs_mapping_download: "Download ESRS Mapping Configuration",
  customer_sco2pes_configuration_esrs_mapping_upload: "Upload ESRS Mapping Configuration",
  customer_sco2pes_configuration_esrs_mapping_subtitle: "The ESRS mapping configuration can be uploaded via Excel file. Use this excel template for file upload:",

   //External Databases
  customer_sco2pes_co2_database_own_emission_factors: "Own CO<sub>2</sub> Types",
  customer_sco2pes_co2_database_own_co2_type: "Own CO<sub>2</sub> Type",
  customer_sco2pes_co2_database_global_emission_factors: "Managed CO<sub>2</sub> Types",
  customer_sco2pes_co2_database_external_databases: "Emissiondatabases",
  customer_sco2pes_co2_database_external_databases_search: "Freetext Search",
  customer_sco2pes_co2_database_database_label: "Database",
  customer_sco2pes_co2_database_valid_from: "Valid From",
  customer_sco2pes_co2_database_valid_until: "Valid Until",
  customer_sco2pes_co2_database_name_description: "Name/Description",
  customer_sco2pes_co2_database_valid_period: "Validity (from - until)",
  customer_sco2pes_co2_database_geography: "Geography",
  customer_sco2pes_co2_database_actions: "Actions",
  customer_sco2pes_co2_database_details: "Details",
  customer_sco2pes_co2_database_delete: "Delete",
  customer_sco2pes_co2_database_ecoinvent_licensing: "Ecoinvent factors are not shown for licensing reasons",

  // Bookings
  customer_sco2pes_booking_successfully_canceled: "Booking successfully cancelled",
  customer_sco2pes_cancel_bookings: "Cancel bookings",
  customer_sco2pes_confirm_canceling_selected_bookings: "Do you really want to cancel the {selectedBookingsCount} selected booking(s)?",
  customer_sco2pes_bookings_successfully_canceled: "{selectedBookingCount} booking(s) successfully canceled.",

  customer_sco2pes_pending_unit_bookings: "Pending Unit Bookings",
  customer_sco2pes_pending_unit_bookings_details: "Pending Unit Bookings Details",
  customer_sco2pes_pending_financial_bookings: "Pending Financial Bookings",
  customer_sco2pes_pending_financial_bookings_details: "Pending Financial Bookings Details",
  customer_sco2pes_pending_unit_bookings_search_fields: "You can search within the following fields:<br/><ul><li>Tag Value</li><li>Order ID</li><li>Company Code Name and Code</li><li>CO<sub>2</sub> Type Name</li><li>CO<sub>2</sub> Category Name</li><li>Supplier Name</li><li>Creditor ID</li></ul>",
  customer_sco2pes_pending_financial_bookings_search_fields: "You can search within the following fields:<br/><ul><li>Origin</li><li>Company Code Name and Code</li><li>Financial Model Name</li><li>Financial Model Account Number and Internal Order Number</li><li>Supplier Name</li><li>Creditor ID</li></ul>",
  customer_sco2pes_pending_bookings_error_filter: "Error",
  customer_sco2pes_pending_bookings_error_filter_no_error: "Pending Processing (No errors yet)",
  customer_sco2pes_pending_bookings_error_filter_all_errors: "Processed with Errors (Includes all errors)",
  customer_sco2pes_pending_bookings_error_filter_error_code: "Booking including error:",

  // Unit bookings
  customer_sco2pes_unit_bookings_unit_bookings: "Unit Bookings",
  customer_sco2pes_unit_bookings_subtitle: "In this area you can create bookings based on units of measure (for example: kWh, kg, l, ...). To do this, you can use the \"Upload function\" to export the Excel table provided, fill it in and upload it to the software. Alternatively, you can enter the booking amounts manually via \"+ UNIT BOOKING\".",
  customer_sco2pes_unit_bookings_unit_booking: "Unit Booking",
  customer_sco2pes_unit_bookings_creation_dialog_title: "Create Unit Booking",
  customer_sco2pes_unit_bookings_all_categories: "All Categories",
  customer_sco2pes_unit_bookings_select_a_category: "Please select a category",
  customer_sco2pes_unit_bookings_amount: "Amount",
  customer_sco2pes_unit_bookings_cancel_booking_question: "Do you really want to cancel the booking?",
  customer_sco2pes_unit_bookings_show_only_my_bookings: "Show only my bookings",
  customer_sco2pes_unit_bookings_order_id: "Order Id",
  customer_sco2pes_unit_bookings_type: "Type",
  customer_sco2pes_unit_bookings_supplier: "Supplier",
  customer_sco2pes_unit_bookings_category: "Category",
  customer_sco2pes_unit_bookings_unit_booking_details: "Unit Booking Details",
  customer_sco2pes_unit_bookings_cancel_booking: "Cancel Booking",
  customer_sco2pes_unit_bookings_details: "Details",
  customer_sco2pes_unit_bookings_not_set: "Not set",
  customer_sco2pes_unit_bookings_meta_data: "Meta Data",
  customer_sco2pes_unit_bookings_co2_values: "CO<sub>2</sub> Values",
  customer_sco2pes_unit_bookings_all: "All",
  customer_sco2pes_unit_bookings_created_at: "Created At",
  customer_sco2pes_unit_bookings_financial_bookings: "Financial Bookings",
  customer_sco2pes_unit_bookings_co2_emissions_in_kg: "Emissions in kg CO<sub>2</sub>e",
  customer_sco2pes_unit_bookings_financial_booking: "Financial Booking",
  customer_sco2pes_unit_bookings_financial_booking_details: "Financial Booking Details",
  customer_sco2pes_unit_bookings_model_description: "Model Description",
  customer_sco2pes_unit_bookings_cancel_booking_warning: "This booking is related to the financial booking {0}.<br> It can be cancelled from the financial booking details.",
  customer_sco2pes_unit_bookings_co2_category: "CO<sub>2</sub> Category",
  customer_sco2pes_unit_bookings_booking_creation_success_title: "CO<sub>2</sub> Booking successfully created!",
  customer_sco2pes_unit_bookings_booking_creation_success_next_booking: "Next Booking",
  customer_sco2pes_unit_bookings_booking_creation_success_show_bookings: "Show Bookings",
  customer_sco2pes_unit_bookings_co2_type: "CO<sub>2</sub> Type",
  customer_sco2pes_unit_bookings_auto_generated: "Auto Generated",
  customer_sco2pes_unit_bookings_auto_generated_by_financial_booking: "By financial booking",
  customer_sco2pes_unit_bookings_auto_generated_by_commuter_survey: "By commuter survey",
  customer_sco2pes_unit_bookings_advance_search: "Advance Search",
  customer_sco2pes_unit_bookings_distance_multiply_description: "The total distance is the product of amount and distance",
  customer_sco2pes_unit_bookings_copy_co2_type: "Click to copy CO<sub>2</sub> Type ID",
  customer_sco2pes_unit_bookings_fill_the_details: "Fill the details",
  customer_sco2pes_unit_bookings_select_co2_type_desc: "Select the CO<sub>2</sub> Type that you want to create the booking, and after that continue to fill the booking details.",
  customer_sco2pes_unit_bookings_cancel_creating_booking: "Cancel booking creation",
  customer_sco2pes_unit_bookings_cancel_creating_booking_question: "Do you really want to cancel the booking creation?",
  customer_sco2pes_unit_bookings_select_an_order_period: "Select an order period",
  customer_sco2pes_unit_bookings_create: "Create",
  customer_sco2pes_unit_bookings_clone_dialog_title: "Clone unit bookings",
  customer_sco2pes_unit_bookings_clone_dialog_order_id_prefix: "Order ID prefix",
  customer_sco2pes_unit_bookings_clone_dialog_order_id_prefix_desc: `Order ID will not be cloned! instead we generate new ones for you. All cloned bookings will start with this order ID prefix. For example if you enter "clone-" then order ID of cloned bookings will be: <b>clone-01, clone-02, ..., clone-124</b>`,
  customer_sco2pes_unit_bookings_clone_dialog_increase_order_period: "Increase order period",
  customer_sco2pes_unit_bookings_clone_dialog_increase_order_period_desc: `
  If you want to clone the bookings in the future, enter a value bigger than or equal to 0. This is the number
  of months we add to order period of each booking.
  `,
  customer_sco2pes_unit_bookings_clone_dialog_count: "Unit bookings found according to applied filtering",
  customer_sco2pes_unit_bookings_clone_dialog_confirmation: "Do you want to clone {count} unit bookings?",
  customer_sco2pes_unit_bookings_clone_dialog_success: "Cloned {count} unit bookings successfully",
  customer_sco2pes_unit_bookings_created_after: "Created after",
  customer_sco2pes_unit_bookings_created_before: "Created before",
  customer_sco2pes_unit_bookings_scope_warning: "Please note that when calculating emissions in Scope 1 and/or Scope 2, the calculation of emissions for Scope 3.3 should also be considered in order to separately calculate the corresponding upstream emissions. For this purpose, please add an additional entry that records the identical amount when selecting the corresponding CO2 type from Scope 3.3.",

  // Unit bookings upload
  customer_sco2pes_unit_bookings_import: "Import Unit Bookings",
  customer_sco2pes_unit_bookings_tag_description: "Tagging column names - only required if columns used in excel upload:",
  customer_sco2pes_unit_bookings_tag_tooltip: "The use of tagging within unit bookings allows you to add additional information to bookings. This information has no impact on the calculation of emissions. The use of tags is not mandatory for calculating emissions within the CCF tool.",
  customer_sco2pes_unit_bookings_tag_1: "Tag 1",
  customer_sco2pes_unit_bookings_tag_2: "Tag 2",
  customer_sco2pes_unit_bookings_tag_3: "Tag 3",
  customer_sco2pes_unit_bookings_limitations: "Limitations",
  customer_sco2pes_unit_bookings_files_maximum_size: "Files should not be greater than 5MB",
  customer_sco2pes_unit_bookings_required_file_type: "Must be of type xlsx (Excel).",
  customer_sco2pes_unit_bookings_required_sheet_details: "Excel should be only one sheet.",
  customer_sco2pes_unit_bookings_upload_can_not_be_cancelled: "Upload process can't be cancelled.",
  customer_sco2pes_unit_bookings_upload_file_multiple_times: "You can upload te same file multiple times, already existing bookings will be ignored.",
  customer_sco2pes_unit_bookings_upload_file_not_processed: "The file could not be processed. The following error(s) occurred:",
  customer_sco2pes_unit_bookings_upload_output_file_name: "unit_booking_upload_results",
  customer_sco2pes_unit_bookings_upload_output_sheet_name: "Results",
  customer_sco2pes_unit_bookings_import_subtitle: "Unit Bookings can be imported via excel file. Use this excel template for file upload:",

  // Financial bookings
  customer_sco2pes_financial_bookings_financial_booking: "Financial Booking",
  customer_sco2pes_financial_bookings_subtitle: "In this section you can make entries based on financial data according to your financial models. To do this, you can use \"+ FINANCIAL BOOKING\" to manually enter the amounts according to your totals and balances list.",
  customer_sco2pes_financial_bookings_description: "Please enter the total amount that has been booked to the account <b>{0}</b> (account number: <b>{1} / {2}</b>) in the period under consideration according to your trial balance / profit and loss account.",
  customer_sco2pes_financial_bookings_description_2: "The Booking ID used to uniquely identify the booking.<br /> It is recommended to use e.g. an invoice number.",
  customer_sco2pes_financial_bookings_id: "Booking ID",
  customer_sco2pes_financial_bookings_accounting_period: "Accounting Period",
  customer_sco2pes_financial_bookings_creation_dialog_title: "Create Financial Booking",
  customer_sco2pes_financial_bookings_amount: "Amount",
  customer_sco2pes_financial_bookings_detailed: "Detailed",
  customer_sco2pes_financial_bookings_form_description: "Please enter the expenses for the different categories in the accounting period you specified, <strong>excluding sales tax </strong>.",
  customer_sco2pes_financial_bookings_general_information: "General Information",
  customer_sco2pes_financial_bookings_relevant_information: "relevant information",
  customer_sco2pes_financial_bookings_creation_success_title: "Booking successfully created.",
  customer_sco2pes_financial_bookings_creation_success_next_booking: "Next Booking",
  customer_sco2pes_financial_bookings_creation_success_show_bookings: "Show Bookings",
  customer_sco2pes_financial_bookings_search_by_origin: "Search by origin",
  customer_sco2pes_financial_bookings_cancel_booking: "Cancel Booking",
  customer_sco2pes_financial_bookings_cancel_booking_question: "Do you really want to cancel the booking?",
  customer_sco2pes_financial_bookings_booking_successfully_canceled: "Booking successfully cancelled",
  customer_sco2pes_financial_bookings_duplicate_tooltip: "Duplicate",
  customer_sco2pes_financial_bookings_import_title: "Import Financial Bookings",
  customer_sco2pes_financial_bookings_import_subtitle: "Financial Bookings can be imported via excel file. Use this excel template for file upload:",
  customer_sco2pes_financial_bookings_company_code_not_found: "Company Code not found!",
  customer_sco2pes_financial_bookings_create_company_code_warning: "Please create a company code first to enable financial bookings.",
  customer_sco2pes_financial_bookings_create_financial_model_warning: "Please create a <a href='{link}'>financial model</a> first to be able to add financial bookings.",

  // Checklist
  customer_sco2pes_checklist_title: "Checklist",
  customer_sco2pes_handbook_title: "Handbook",
  customer_sco2pes_handbook_description: "Here you can download a detailed guide to the GC software for your seamless integration and calculation of your Corporate Carbon Footprint (CCF). This handbook provides clarity and hints for a smooth transition of independently working with the platform to create your next CO<sub>2</sub> balance report.",
  customer_sco2pes_checklist_information_description: "This checklist is prepared to provide assistance for the Company’s Corporate Carbon Footprint (CCF) for the reporting year. The following steps gives an easy transition to software use, especially for clients from 2<sup>nd</sup> year of reporting and onwards.",
  customer_sco2pes_checklist_more_info: "More info: Chapter {chapterNumber} Handbook",
  customer_sco2pes_checklist_base_data: "Base Data",
  customer_sco2pes_checklist_bookings: "Bookings",
  customer_sco2pes_checklist_commuter_survey: "Commuter Survey",
  customer_sco2pes_checklist_reporting: "Reporting",

  // Suppliers
  customer_sco2pes_suppliers_transport_routes: "Transport routes",
  customer_sco2pes_suppliers_supplier: "Supplier",
  customer_sco2pes_suppliers_suppliers: "Suppliers",
  customer_sco2pes_suppliers_subtitle: "In the context of CO<sub>2</sub> emissions, the analysis of supply chains plays an important role. Therefore, in order to fully capture a company's CO<sub>2</sub> emissions, supplier emissions must be included in the calculations. Subsequently, transport routes are created with the help of your supplier data in order to look at the emissions along your upstream and downstream supply chain.<br /><br /> For the calculation of emissions from the transport of products / materials / raw materials, please upload your supplier data according to the template, which is stored in the import dialog. Alternatively, you can also enter your suppliers individually in the system. To do this, please use the button \"+ SUPPLIER\". To make it easier for you to specify the distance, it is calculated automatically.",
  customer_sco2pes_suppliers_supplier_details: "Supplier details",
  customer_sco2pes_suppliers_transport_type: "Transport type",
  customer_sco2pes_suppliers_distance: "Distance",
  customer_sco2pes_suppliers_transport_route_segment: "transport-route segment",
  customer_sco2pes_suppliers_transport_route: "Transport route",
  customer_sco2pes_suppliers_transport_route_description: "Transport routes are used to calculate the emissions for transportation. A transport route can consist of multiple segments of different transportation types. Example: A product might be transported a certain distance from location A via ship to location B and from there on via truck to location C.",
  customer_sco2pes_transport_route_documentation: "Documentation / Notes",
  customer_sco2pes_suppliers_transport_route_segment_deactivated_type: "This CO2 type is deactivated. For new bookings a different CO2 type should be selected.",
  customer_sco2pes_suppliers_transport_route_successfully_created: "Transport route successfully created",
  customer_sco2pes_suppliers_transport_route_successfully_edited: "Transport route successfully edited",
  customer_sco2pes_suppliers_transport_route_successfully_deleted: "Transport route successfully deleted",
  customer_sco2pes_suppliers_creditor_number: "Creditor Number",
  customer_sco2pes_suppliers_new_supplier: "Create Supplier",
  customer_sco2pes_suppliers_supplier_successfully_created: "Supplier successfully created",
  customer_sco2pes_suppliers_supplier_successfully_edited: "Supplier successfully edited",
  customer_sco2pes_suppliers_supplier_successfully_deleted: "Supplier successfully deleted",
  customer_sco2pes_suppliers_do_you_really_want_to_delete_the_supplier: "Do you really want to delete the supplier",
  customer_sco2pes_suppliers_edit_supplier: "Edit Supplier",
  customer_sco2pes_suppliers_all: "All",
  customer_sco2pes_suppliers_add_address: "Add address",
  customer_sco2pes_suppliers_remove_address: "Remove address",
  customer_sco2pes_suppliers_data_completed: "Data complete",
  customer_sco2pes_suppliers_data_uncompleted: "Data incomplete",
  customer_sco2pes_suppliers_uncompleted_warning: "This supplier has been created automatically with a CO<sub>2</sub> Booking. Please fill in the missing information.",
  customer_sco2pes_suppliers_edit: "Edit",
  customer_sco2pes_suppliers_selection_rule: "Selection Rule",
  customer_sco2pes_suppliers_new_selection_rule: "Create Selection Rule",
  customer_sco2pes_suppliers_default_transport_route_for: "Default transport route for {0}",
  customer_sco2pes_transport_route_version_validity: "Validity",
  customer_sco2pes_transport_route_version: "Version",
  customer_sco2pes_transport_route_versions: "Versions",
  customer_sco2pes_transport_route_versions_add_version: "Add version",
  customer_sco2pes_transport_route_delete_version: "Delete version",
  customer_sco2pes_transport_route_distance: "Distance",
  customer_sco2pes_transport_route_start_address_not_completed: "Start address data not completed",
  customer_sco2pes_transport_route_destination_address_not_completed: "Destination address data not completed",
  customer_transport_route_validation_1: "Transport Route name and type are provided",
  customer_transport_route_validation_2: "Allowed value for Transport Route status is provided",
  customer_transport_route_validation_3: "Required data for all versions provided (country, city for all addresses required, transport mode and distance between all addresses required)",
  customer_transport_route_version_validation_1: "<i>From</i> and <i>Until</i> must be set",
  customer_transport_route_version_validation_2: "<i>Until</i> must be after <i>From</i>",
  customer_transport_route_add_transit: "Add transit",
  customer_transport_route_auto_calculate_distance: "Calculate Distance",
  customer_transport_route_auto_calculate_distance_tooltip: "Auto calculation is only possible if <i>country</i> and <i>city</i> of origin and destination and transportation <i>category</i> and <i>type</i> is set.",
  customer_transport_route_number_of_versions: "Number of versions",
  customer_transport_route_versions: "Versions",
  customer_transport_route_initial_version: "Initial version",
  customer_transport_route_set_default: "Set as Default",
  customer_transport_route_unset_default: "Unset as Default",
  customer_transport_route_distance_could_not_be_calculated_title: "Distance could not be calculated",
  customer_transport_route_distance_could_not_be_calculated_desc: "The distance could not be calculated. The following errors occurred:",
  customer_sco2pes_suppliers_import_title: "Import Suppliers",
  customer_sco2pes_suppliers_import_subtitle: "Suppliers can be imported via excel file. Use this excel template for file upload:",
  customer_sco2pes_suppliers_co2_relevant_description: "If a supplier is marked as not CO2 relevant only emissions of transportation will be taken into account, but the emissions for the materials delivered will be neglected. This configuration makes sense if the supplier is a subsidiary of your company and if the supplier already takes the material emissions into account.",
  customer_sco2pes_suppliers_upload_output_file_name: "supplier_upload_results",
  customer_sco2pes_transport_route_reset_to_default_name: "Reset to default name",

  // Transport Routes
  customer_sco2pes_transport_routes_supplier_name: "Supplier Name",
  customer_sco2pes_transport_routes_supplier_creditor_number: "Supplier Creditor Number",
  customer_sco2pes_transport_routes_route_name: "Transport Route Name",

  // Financial models
  customer_sco2pes_financial_model: "Financial Model",
  customer_sco2pes_financial_models: "Financial Models",
  customer_sco2pes_financial_models_creation_success_title: "Financial Model successfully created.",
  customer_sco2pes_financial_models_update_success_title: "Financial Model successfully updated.",
  customer_sco2pes_financial_models_subtitle: "In order to create your financial data based CO<sub>2</sub> balance, you need enter your CO<sub>2</sub> relevant financial data in this step. For this purpose, you need information from your accounting and bookkeeping.<br/>Our approach is based on a mathematical model for calculating the emissions of the business transactions to be taken into account, based on your company's financial accounting. To generate the mathematical models (financial model), this step identifies your CO<sub>2</sub>-relevant accounts and the business transactions posted to them.<br/><br/>Financial models can be created for individual accounts using the \"+ FINANCIAL MODEL\" button.<br/><br/>Please note that a separate financial model must be created for each account and processing of all accounts / transactions is necessary to complete this step. For more information, please refer to the respective detailed views.",
  customer_sco2pes_financial_model_model_info: "Model Info",
  customer_sco2pes_financial_model_account_info: "Account Info",
  customer_sco2pes_financial_model_account: "Account",
  customer_sco2pes_financial_model_supplier: "Supplier",
  customer_sco2pes_financial_model_transport_route: "Transport Route",
  customer_sco2pes_financial_models_suppliers_or_transport_routes: "Suppliers / Transport Routes",
  customer_sco2pes_financial_model_percentage: "Percentage",
  customer_sco2pes_financial_model_co2_type_price: "CO<sub>2</sub> Type Price (Price per Unit)",
  customer_sco2pes_financial_model_price_per_unit: "Price per Unit",
  customer_sco2pes_financial_account_number: "Account Number",
  customer_sco2pes_financial_internal_order_number: "Internal Order Number",
  customer_sco2pes_financial_model_versions: "Versions",
  customer_sco2pes_financial_model_version_hint: "For a financial model, emission types can be versioned over time. Versions can be created in the time resolution of months. Versions must cover all possible months, starting with \"Start\" to \"End\".",
  customer_sco2pes_financial_emission_type: "Emission Types",
  customer_sco2pes_financial_validations: "Validations",
  customer_sco2pes_financial_validation_1: "Company Code, names for all selected languages and currency are specified",
  customer_sco2pes_financial_validation_2: "Order periods of versions without gap and / or overlap starting from 0000-01 until 9999-12",
  customer_sco2pes_financial_validation_3: "Every version has at least one emission type",
  customer_sco2pes_financial_validation_4: "Every emission type is completely specified (positive percentage, positive price, a CO<sub>2</sub> Type selected)",
  customer_sco2pes_financial_edit_warning: "Bookings which have been created with this configuration will be recalculated and updated according to the new configuration.",
  customer_sco2pes_financial_edit__locking_warning: "Updating a financial model will have no effect on locked bookings and finalised reports.",
  customer_sco2pes_financial_model_total_percentage_over_100: "Total percentage is over 100%",
  customer_sco2pes_financial_model_co2_type_weightless: "Selected CO<sub>2</sub> Type does not have a weight, so selecting a supplier would have an affect in CO<sub>2</sub> emission calculations.",
  customer_sco2pes_financial_model_documentation_description: "The financial models you have created require a justification of the selected CO2 types as well as a corresponding justification of the chosen percentage distribution in the event of an audit. For complete and easily accessible documentation (even in the event of an audit), you can store them here in the form of documents and free text.",
  customer_sco2pes_financial_model_delete: "Delete",
  customer_sco2pes_financial_model_activation_status: "Activation Status",
  customer_sco2pes_financial_model_scope_warning: "Please note that when calculating emissions in Scope 1 and/or Scope 2, you should also take into account the calculation of emissions for Scope 3.3 in order to calculate the corresponding upstream chain emissions separately. Please add another line with identical percentage distribution for this purpose. Please note that in this case the total percentage of the financial model may exceed 100%.",

  // CO2 Type selection
  customer_sco2pes_co2_type_selection_deactivated: "Deactivated CO<sub>2</sub> Type",
  customer_sco2pes_co2_type_selection_active: "Active CO<sub>2</sub> Type",
  customer_sco2pes_co2_type_selection_open: "CO<sub>2</sub> Type Selection Open",
  customer_sco2pes_co2_type_selection: "CO<sub>2</sub> Type selection",

  // Company codes
  customer_sco2pes_company_codes_company_codes: "Company Codes",
  customer_sco2pes_company_codes_subtitle: "Company codes are organisational units for which self-contained accounting can be created. Units can be for example: locations, plants, cost centers. From this, it can be derived which data can be evaluated in the dashboard in the future. This gives you the opportunity to monitor results and to develop targeted measures. Each company code is identified by a unique booking code. The booking code can either be taken from your accounting systems (SAP or similar) or assigned manually. This can be done, for example, using sequential numbering.<br/><br/>Example company code:<br/>Name: Headquarter / Code: 01",
  customer_sco2pes_company_codes_create_company_code: "Create Company Code",
  customer_sco2pes_company_codes_successfully_created: "Company code successfully created",
  customer_sco2pes_company_codes_successfully_deleted: "Company code successfully deleted.",
  customer_sco2pes_company_codes_started_at: "Started At",
  customer_sco2pes_company_codes_description: "Description",
  customer_sco2pes_company_codes_configuration: "Configuration",
  customer_sco2pes_company_codes_type: "Type",
  customer_sco2pes_company_codes_configurations: "Configurations",
  customer_sco2pes_company_codes_import_title: "Import Company Codes",
  customer_sco2pes_company_codes_import_subtitle: "Company codes can be imported via excel file. Use this excel template for file upload:",

  // Commuter surveys
  customer_sco2pes_commuter_surveys_commuter_surveys: "Commuter Surveys",
  customer_sco2pes_commuter_surveys_commuter_survey: "Commuter Survey Details",
  customer_sco2pes_commuter_surveys_surveys: "Commuter Surveys",
  customer_sco2pes_commuter_surveys_survey: "Commuter Survey",
  customer_sco2pes_commuter_surveys_survey_gender_neutral: "Commuter Survey",
  customer_sco2pes_commuter_surveys_created_at: "Created At",
  customer_sco2pes_commuter_surveys_year: "Year",
  customer_sco2pes_commuter_surveys_number_of_answers: "Number of answers",
  customer_sco2pes_commuter_surveys_open: "Open",
  customer_sco2pes_commuter_surveys_cancelled: "Cancelled",
  customer_sco2pes_commuter_surveys_finalized: "Finalized",
  customer_sco2pes_commuter_surveys_max_distance_filter: "Max Distance Filter",
  customer_sco2pes_commuter_surveys_overview_table_description: "Once a year, you can use an employee survey to record the CO<sub>2</sub> emissions of your company's employees that are generated by commuting to the company location (Scope 3.7). After starting a survey you will receive a link which you can share within your company. All employee responses are stored anonymously.<br>You can view the progress of your survey at any time in the survey details. If there are enough responses, you can end the survey. The results are then extrapolated and the resulting CO<sub>2</sub> emissions are automatically assigned to the company code for which you started the survey.",
  customer_sco2pes_commuter_surveys_start_dialog_description: "After starting the survey, a link will be generated, which you can share with your company's employees.",
  customer_sco2pes_commuter_surveys_included_transportation_types_description: "Decide yourself which transport types you want to include in the survey.<br>For example, if your company provides a shuttle bus for employees, you can create a separate CO<sub>2</sub> Type for Scope 3.7 in the CO<sub>2</sub> database. This will then also be available to you here when you start a survey. By default, all the 3.7 CO<sub>2</sub> Types are selected.",
  customer_sco2pes_commuter_surveys_co2_type: "CO<sub>2</sub> Type",
  customer_sco2pes_commuter_surveys_warnings: "Warnings",
  customer_sco2pes_commuter_surveys_filter_answers_with_warning: "Filter answers with warnings",
  customer_sco2pes_commuter_surveys_answer: "Answer",
  customer_sco2pes_commuter_surveys_answers: "Answers",
  customer_sco2pes_commuter_surveys_co2_emissions_in_kg: "Emissions in kg CO<sub>2</sub>e",
  customer_sco2pes_commuter_surveys_exclude: "Exclude",
  customer_sco2pes_commuter_surveys_include: "Include",
  customer_sco2pes_commuter_surveys_answer_warning: "Answer exceeds maximum distance",
  customer_sco2pes_commuter_surveys_add_answer_to_survey: "Add Answer to Survey",
  customer_sco2pes_commuter_surveys_distance: "Distance",
  customer_sco2pes_commuter_surveys_cancel: "Cancel Survey",
  customer_sco2pes_commuter_surveys_finalize: "Finalize Survey",
  customer_sco2pes_commuter_surveys_cancel_commuter_survey: "Cancel Commuter Survey",
  customer_sco2pes_commuter_surveys_finalize_commuter_survey: "Finalize Commuter Survey",
  customer_sco2pes_commuter_surveys_cancel_commuter_survey_confirmation: "Do you really want to cancel the commuter survey?<br> Users will not be able to answer the commuter survey anymore. No CO<sub>2</sub> emissions are calculated.",
  customer_sco2pes_commuter_surveys_finalize_commuter_survey_confirmation: "Do you really want to finalize the commuter survey?",
  customer_sco2pes_commuter_surveys_finalize_commuter_survey_warning: "Users will not be able to answer the commuter survey anymore. CO<sub>2</sub> emissions are calculated and missing results are extrapolated.",
  customer_sco2pes_commuter_surveys_data: "Data",
  customer_sco2pes_commuter_surveys_submitted_answers: "Submitted Answers",
  customer_sco2pes_commuter_surveys_expected_answers: "Expected Answers",
  customer_sco2pes_commuter_surveys_co2_emissions: "CO<sub>2</sub> emissions",
  customer_sco2pes_commuter_surveys_submitted_co2_emissions: "Submitted CO<sub>2</sub> emissions",
  customer_sco2pes_commuter_surveys_extrapolated_co2_emissions: "Extrapolated CO<sub>2</sub> emissions",
  customer_sco2pes_commuter_surveys_commuting_type: "Commuting Type",
  customer_sco2pes_commuter_surveys_distance_submitted: "Submitted distance",
  customer_sco2pes_commuter_surveys_distance_extrapolated: "Extrapolated distance",
  customer_sco2pes_commuter_surveys_public_answer_page_description: "Thanks for participating in the commuter survey by <b>{companyName}</b> for the year <b>{year}</b>.",
  customer_sco2pes_commuter_surveys_working_days_per_week: "Working days per week",
  customer_sco2pes_commuter_surveys_thank_you: "Thank You!",
  customer_sco2pes_commuter_surveys_public_answering_message: "Thank you for answering the commuter survey.<br>Your commuting CO<sub>2</sub> footprint is {0} kg in {1}.<br><br><b>You can close this page now.</b>",
  customer_sco2pes_commuter_surveys_survey_is_not_available: "Survey is not available",
  customer_sco2pes_commuter_surveys_copy_to_clipboard: "Copy to Clipboard",
  customer_sco2pes_commuter_surveys_combined_commuter_survey_warning: "Commuter surveys which are part of a combined commuter survey can be finalized / cancelled with the combined commuter survey.",
  customer_sco2pes_commuter_surveys_link_copied: "Link Copied",
  customer_sco2pes_commuter_surveys_create_a_commuter_survey: "Create Commuter Survey",
  customer_sco2pes_commuter_surveys_max_distance_filter_km: "Max distance Filter (km)",
  customer_sco2pes_commuter_surveys_included_transportation_types: "Included Transportation Types",
  customer_sco2pes_commuter_surveys_commuter_survey_answered_warning: "The commuter survey has already been answered on this browser.<br> Please be aware that you can not update your answer, instead a new answer will be submitted.<br>If you have noticed that you have entered incorrect data, please contact your system administrator.",
  customer_sco2pes_commuter_surveys_continue_to_survey: "Continue to Survey",
  customer_sco2pes_commuter_surveys_created_by: "Created by",
  customer_sco2pes_commuter_surveys_with_warning: "With warning",
  customer_sco2pes_commuter_surveys_without_warning: "Without warning",
  customer_sco2pes_commuter_surveys_included: "Included",
  customer_sco2pes_commuter_surveys_excluded: "Excluded",
  customer_sco2pes_commuter_surveys_anonymous: "Anonymous",
  customer_sco2pes_commuter_surveys_admins: "Admins",
  customer_sco2pes_commuter_surveys_exclude_answer_confirm: "Do you really want to exclude the answer from the survey?",
  customer_sco2pes_commuter_surveys_include_answer_confirm: "Do you really want to include the answer in the survey?",
  customer_sco2pes_commuter_surveys_max_distance_filter_tooltip: "Maximum single distance for commuting. If this distance is exceeded, the responses are automatically marked with a warning.",
  customer_sco2pes_commuter_surveys_go_to_global_climate: "Go to GLOBAL CLIMATE",
  customer_sco2pes_commuter_surveys_days: "Working Days",
  customer_sco2pes_commuter_surveys_included_remote_working_type: "Included Remote Working Type",
  customer_sco2pes_commuter_surveys_included_remote_working_type_description: "Decide yourself which remote working CO<sub>2</sub> Type you want to include in the survey.<br>You can create an own CO<sub>2</sub> Type for remote working with Scope 3.7 in the CO<sub>2</sub> database and it will then be selectable here.",
  customer_sco2pes_commuter_surveys_remote_working_co2_type: "Remote Working CO<sub>2</sub> Type",
  customer_sco2pes_commuter_surveys_remote_working_type: "Remote Working",
  customer_sco2pes_commuter_surveys_days_submitted: "Submitted days",
  customer_sco2pes_commuter_surveys_days_extrapolated: "Extrapolated days",
  customer_sco2pes_commuter_surveys_on_site: "On Site",
  customer_sco2pes_commuter_surveys_remote_work: "Remote Work",
  customer_sco2pes_commuter_surveys_daily_one_way_distance: "Daily one way commuting distance",
  customer_sco2pes_commuter_surveys_one_way_distance: "One way distance (km)",
  customer_sco2pes_commuter_surveys_what_means_of_transportation: "What means of transportation do you use on average?",
  customer_sco2pes_commuter_surveys_transportation_type_info: "You can use the sliders to specify the percentage of your transport usage.",
  customer_sco2pes_commuter_surveys_transportation_type_example_1: "15 km one way distance to work site <ul><li>3 months a year by bicycle</li><li>9 months a year by car</li></ul> results in bicycle = 25%, car = 75%",
  customer_sco2pes_commuter_surveys_transportation_type_example_2: "30 km one way distance to work site <ul><li>of which 12 km by bus</li><li>of which 18 km by train</li></ul> results in bus = 40 %, train = 60 %",
  customer_sco2pes_commuter_surveys_transportation_type_example_3: "10 km one way distance to work site <ul><li>3 months full distance in a year by bike</li><li>9 months a year<ul><li>2 km by foot</li><li>8 km by bus</li></ul></li></ul> results in bicycle = 25 %, walking = 15 %, bus = 60 %.",
  customer_sco2pes_commuter_surveys_show_examples: "Show Examples",
  customer_sco2pes_commuter_surveys_examples: "Examples",
  customer_sco2pes_commuter_surveys_one_way_distance_warning: "One way commuting distance must be greater 0 when working on site.",
  customer_sco2pes_commuter_surveys_slider_values_warning: "The sum of the distance percentages must be 100%. Current value: {0}%.",
  customer_sco2pes_commuter_surveys_combined: "Combined",
  customer_sco2pes_commuter_surveys_organisation_unit_selection: "Organisation Unit Selection",
  customer_sco2pes_commuter_surveys_commuter_behaviour: "Commuter Behaviour",
  customer_sco2pes_commuter_surveys_welcome_text: "Welcome to the commuter survey of <b>{name}</b> for the <b>{year}</b>.",
  customer_sco2pes_commuter_surveys_select_organisation_unit: "Please select the organisation unit you belong to:",
  customer_sco2pes_commuter_surveys_select_organisation: "Select organisation",
  customer_sco2pes_commuter_surveys_please_indicate: "Please indicate your commuting behaviour for the year <b>{year}</b> and the selected organisation unit <b>{unitName}</b>",
  customer_sco2pes_commuter_surveys_expand_all: "Expand all",
  customer_sco2pes_commuter_surveys_collapse_all: "Collapse all",

  // Dashboard
  customer_sco2pes_dashboard_alert_1: "Attention! Emissions are recalculated every fifteen minutes.",
  customer_sco2pes_dashboard_alert_2: "Current changes to the base data and booking data may not be displayed immediately on the dashboard.",
  customer_sco2pes_dashboard_description: "Description",
  customer_sco2pes_dashboard_category_name: "Category",
  customer_sco2pes_dashboard_type_name: "Type",
  customer_sco2pes_dashboard_total_scope: "Total Scope",
  customer_sco2pes_dashboard_emissions: "emissions",
  customer_sco2pes_dashboard_detailed_co2_emissions_in_kg: "Emissions per Scope (detailed) in kg CO<sub>2</sub>e",
  customer_sco2pes_dashboard_total_CO2_emissions_per_scope_in_kg: "Emissions per Scope in kg CO<sub>2</sub>e",
  customer_sco2pes_dashboard_comparison: "Comparison",
  customer_sco2pes_dashboard_co2_in_kg: "kg CO<sub>2</sub>e",
  customer_sco2pes_dashboard_kg: "kg",
  customer_sco2pes_dashboard_start_of_month: "Start of month",
  customer_sco2pes_dashboard_end_of_month: "End of month",
  customer_sco2pes_dashboard_supplier: "Supplier Filter",
  customer_sco2pes_dashboard_company_code: "Company Code Filter",
  customer_sco2pes_dashboard_delete_chart: "Delete",
  customer_sco2pes_dashboard_chart: "Chart",
  customer_sco2pes_dashboard_chart_select_first_1000_company_codes: "Select First 1000 Company Codes",
  customer_sco2pes_dashboard_delete_chart_question: "Do you really want to delete the chart?",
  customer_sco2pes_dashboard_chart_added_successfully: "Chart is added successfully",
  customer_sco2pes_dashboard_chart_updated_successfully: "Chart is updated successfully",
  customer_sco2pes_dashboard_chart_deleted_successfully: "Chart was deleted successfully",
  customer_sco2pes_dashboard_chart_title: "Title",
  customer_sco2pes_dashboard_chart_legend_position: "Legend Position",
  customer_sco2pes_dashboard_chart_legend_position_top: "Top",
  customer_sco2pes_dashboard_chart_legend_position_bottom: "Bottom",
  customer_sco2pes_dashboard_chart_legend_position_left: "Left",
  customer_sco2pes_dashboard_chart_legend_position_right: "Right",
  customer_sco2pes_dashboard_chart_move_up: "Move up",
  customer_sco2pes_dashboard_chart_move_down: "Move down",
  customer_sco2pes_dashboard_chart_save_required: "Please provide all values",
  customer_sco2pes_dashboard_chart_type: "Chart type",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY: "Emissions per company",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY_PER_ORDER_PERIOD: "Emissions over time",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE_CATEGORY: "Emissions per GHG Scope Category",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE_CATEGORY_PER_ORDER_PERIOD: "Emissions per GHG Scope Category over time",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE: "Emissions per GHG Scope",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE_PER_ORDER_PERIOD: "Emissions per GHG Scope over time",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY_CODE: "Emissions per Company Code",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY_CODE_PER_ORDER_PERIOD: "Emissions per Company Code over time",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SUPPLIER: "Emissions per Supplier",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SUPPLIER_PER_ORDER_PERIOD: "Emissions per Supplier over time",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_CATEGORY: "Emissions per CO2 Category",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_CATEGORY_PER_ORDER_PERIOD: "Emissions per CO2 Category over time",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_TYPE: "Emissions per CO2 Type",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_TYPE_PER_ORDER_PERIOD: "Emissions per CO2 Type over time",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_TRANSPORT_ROUTE: "Emissions per Transport Route",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_TRANSPORT_ROUTE_PER_ORDER_PERIOD: "Emissions per Transport Route over time",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_UNIT: "Emissions per Unit",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_UNIT_PER_ORDER_PERIOD: "Emissions per Unit over time",
  customer_sco2pes_dashboard_create_chart: "Create chart",
  customer_sco2pes_dashboard_edit_chart: "Edit chart",
  customer_sco2pes_dashboard_all: "All",
  customer_sco2pes_dashboard_export_as_excel_file: "Export as Excel File",
  customer_sco2pes_dashboard_co2_relevant_warning: "The selected supplier has been explicitly excluded from the CO<sub>2</sub> report. This means that only the transport route relevant emissions are taken into account in the CO2 report.<br>The supplier can be included completely in the CO<sub>2</sub> report by marking it as CO<sub>2</sub> relevant.",
  customer_sco2pes_dashboard_no_data_available: "There are no CO<sub>2</sub> Bookings for the applied filters",
  customer_sco2pes_dashboard_customizable: "Dashboard",
  customer_sco2pes_dashboard_company: "Company",
  customer_sco2pes_dashboard_holdings: "Holdings",
  customer_sco2pes_dashboard_holding_direct_indirect: "Holding (Direct/Indirect)",
  customer_sco2pes_dashboard_no_parent_companies: "No parent companies",
  customer_sco2pes_dashboard_no_subsidiary_companies: "No subsidiary companies",
  customer_sco2pes_dashboard_subsidiary_emissions: "Emissions reported by subsidiary companies",
  customer_sco2pes_dashboard_parent_emissions: "Emissions reported to parent companies",
  customer_sco2pes_dashboard_not_part_of_group: "Your company is not part of a company group, therefore no CO<sub>2</sub> emissions can be displayed.",
  customer_sco2pes_dashboard_show_detailed_emissions: "Show detailed emissions",
  customer_sco2pes_dashboard_dashboard_configuration: "Dashboard Configuration",
  customer_sco2pes_dashboard_data_series: "Data Series",
  customer_sco2pes_dashboard_series_name: "Series Name",
  customer_sco2pes_dashboard_series_colour: "Series Colour",
  customer_sco2pes_dashboard_apply: "Apply",
  customer_sco2pes_company_group_export_excel: "Export Simplified Excel",
  customer_sco2pes_company_group_compare_all: "Compare All",
  customer_sco2pes_company_group: "Company Group",
  customer_sco2pes_company_group_emission_details: "Emission Details",
  customer_sco2pes_company_group_export_including_own_company: "Export including own company",
  customer_sco2pes_company_group_export_without_own_company: "Export without own company",
  customer_sco2pes_company_group_select_all_subsidiaries: "Select All Subsidiaries",
  customer_sco2pes_company_group_own_company: "Own Company",
  customer_sco2pes_company_group_subsidiary: "Subsidiary",
  customer_sco2pes_filter_results_by_category: "Filter results by category",
  customer_sco2pes_financial_model_amount: "Amount",
  customer_sco2pes_materials_scopes_info_tooltip: "According to the GHG Protocol, there are three different scopes used to categorize and report on a company's greenhouse gas emissions. These scopes are referred to as Scope 1, Scope 2 and Scope 3 and include different emission sources and control areas.<br>",
  customer_sco2pes_materials_scopes_info_tooltip_more_details: "For more details click on info icon.",
  customer_sco2pes_no_emission_equivalent_data_found: "No emissions per unit found",
  customer_sco2pes_suppliers_transport_route_info: "Transport Route Info",

  // Finalised reports
  customer_sco2pes_finalised_reports_title: "Reports",
  customer_sco2pes_finalised_reports_subtitle: "All reports are listed here. You can download the reports and emissions calculations.",
  customer_sco2pes_finalised_report: "Report",
  customer_sco2pes_finalised_reports_finalise_report: "Finalise report",
  customer_sco2pes_finalised_reports_successfully_created: "Report is successfully created",
  customer_sco2pes_finalised_reports_start_of_month: "Start of month",
  customer_sco2pes_finalised_reports_end_of_month: "End of month",
  customer_sco2pes_finalised_reports_add_dialog_title: "Report finalisation",
  customer_sco2pes_finalised_reports_add_dialog_text: `
    Please select a date range. All unit bookings and financial bookings will be locked. They will not
    be modifiable anymore. All changes made in CCF, will have no effect on the report.
    <b>Please verify all bookings in the bookings section of the software, to make sure the report can be finalised.</b>
  `,
  customer_sco2pes_finalised_reports_add_dialog_alert_title: "Note about pending unit bookings and pending financial bookings",
  customer_sco2pes_finalised_reports_add_dialog_alert_subtitle: "Please make sure that you activate all possible pending unit and financial bookings before finalising the report, because after this process, no pending booking from the selected date range is allowed to be activated. In addition, the system will NOT store related information for pending bookings",
  customer_sco2pes_finalised_reports_add_dialog_add_emission_export_files: "Add emission Excel report for the selected date range to additional files (optional)",
  customer_sco2pes_finalised_reports_add_dialog_terms: "I have read and verified all bookings and would like to proceed.",
  customer_sco2pes_finalised_reports_delete_dialog_title: "Unlock all bookings",
  customer_sco2pes_finalised_reports_delete_dialog_text: "Unlock all bookings between {from} (start of month) and {until} (end of month).",
  customer_sco2pes_finalised_reports_delete_dialog_alert_title: `Uploaded files`,
  customer_sco2pes_finalised_reports_delete_dialog_alert_subtitle: `The files will not be accessible anymore. Please make sure to take a backup and keep them on company’s shared storage.`,
  customer_sco2pes_finalised_reports_successfully_deleted: "Bookings unlocked successfully",
  customer_sco2pes_finalised_reports_details_dialog_title: "Report",
  customer_sco2pes_finalised_reports_details_dialog_files: "Report Files",
  customer_sco2pes_finalised_reports_details_dialog_reporting_period: "Reporting Period",
  customer_sco2pes_finalised_reports_details_dialog_no_files: "No files attached to the report",
  customer_sco2pes_finalised_reports_details_dialog_add_file: "Add File",
  customer_sco2pes_finalised_reports_details_dialog_select_file: "SELECT FILE",
  customer_sco2pes_finalised_reports_details_dialog_upload: "UPLOAD",
  customer_sco2pes_finalised_reports_details_dialog_table_title: "Title",
  customer_sco2pes_finalised_reports_details_dialog_table_created_by: "Created by",
  customer_sco2pes_finalised_reports_details_dialog_table_created_at: "Created at",
  customer_sco2pes_finalised_reports_details_dialog_no_file_selected: "No file selected",
  customer_sco2pes_finalised_reports_details_dialog_input_label_title: "Title",
  customer_sco2pes_finalised_reports_details_dialog_upload_failed: "Failed to upload finalized report file",
  customer_sco2pes_finalised_reports_details_dialog_upload_validation: "Please provide both title and file to upload",
  customer_sco2pes_finalised_reports_details_dialog_delete_file_question: "Do you really want to delete the file?",

  // Documentation Library
  customer_sco2pes_documentation_library_title: "Documentation Library",
  customer_sco2pes_documentation_library_subtitle: "Every document or file that is uploaded inside the CCF base data will be stored in this page and can be tagged,  selected, and viewed or downloaded.",
  customer_sco2pes_documentation_library_table_header_id: "ID",
  customer_sco2pes_documentation_library_table_header_title: "Title",
  customer_sco2pes_documentation_library_table_header_category: "Category",
  customer_sco2pes_documentation_library_table_header_usage: "Usage",
  customer_sco2pes_documentation_library_table_header_upload_date: "Upload Date",
  customer_sco2pes_documentation_library_category_supplier: "Supplier",
  customer_sco2pes_documentation_library_category_co2_type: "Co2 Type",
  customer_sco2pes_documentation_library_category_transport_route: "Transport Route",
  customer_sco2pes_documentation_library_category_financial_models: "Financial Model",
  customer_sco2pes_documentation_library_view_usage: "View usage in ",
  customer_sco2pes_documentation_library_download_document: "Download",
};
