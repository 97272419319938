/* eslint-disable */
// prettier-ignore

export const messages = {
  sco2pes_checklist_company_codes: "Buchungskreise",
  sco2pes_checklist_company_codes_create: "Buchungskreise anlegen",
  sco2pes_checklist_company_codes_update: "(Nur ab dem 2. Jahr) Buchungskreise aktualisieren, falls neue Unternehmenseinheiten hinzugekommen sind",
  sco2pes_checklist_suppliers: "Lieferanten",
  sco2pes_checklist_suppliers_add_new: "Neue Lieferanten mithilfe der Upload-Vorlage hinzufügen",
  sco2pes_checklist_suppliers_review: "(Nur ab dem 2. Jahr) Bestehende Lieferanten überprüfen und ggf. aktualisieren",
  sco2pes_checklist_financial_models: "Finanzmodelle",
  sco2pes_checklist_financial_models_create: "Bei Bedarf: Finanzmodell erstellen",
  sco2pes_checklist_financial_models_lock: "(Nur ab dem 2. Jahr) Sperren der aktuellen Version und Erstellen einer neuen Version",
  sco2pes_checklist_financial_models_adjust: "(Nur ab dem 2. Jahr) Bei Bedarf: Anpassen der Prozentsätze/Anteile",
  sco2pes_checklist_co2_prices_check: "Prüfen der CO2-Typen-Preise:",
  sco2pes_checklist_co2_prices_managed: "Verwaltet (Neue Version wird im Hintergrund von GC aktualisiert)",
  sco2pes_checklist_co2_prices_unmanaged: "Nicht verwaltet (Anpassen der Preise pro Einheit ab dem 2. Jahr)",
  sco2pes_checklist_supply_chain_link: "Bei Bedarf: Verknüpfen der Lieferanten und der Transportwege zu Lieferketten",
  sco2pes_checklist_co2_database: "CO<sub>2</sub>-Datenbank",
  sco2pes_checklist_co2_database_add: "Bei Bedarf: Erfassen eigener Emissionsfaktoren in \"Eigene CO2-Typen\" über manuelle Dateneingabe",
  sco2pes_checklist_co2_database_lock: "(Nur ab dem 2. Jahr) Sperren der aktuellen Version und Hinzufügen einer neuen Version der eigenen Emissionsfaktoren in \"Eigene CO2-Typen\"",
  sco2pes_checklist_unit_bookings: "Unit-Buchungen",
  sco2pes_checklist_unit_bookings_create: "Unit-Buchungen mit der Upload-Vorlage erstellen und hochladen",
  sco2pes_checklist_financial_bookings: "Finanzbuchungen",
  sco2pes_checklist_financial_bookings_options: "Es gibt zwei Möglichkeiten Finanzbuchungen in der Software zu erstellen:",
  sco2pes_checklist_financial_bookings_financial_models: "Im Abschnitt \"Finanzmodelle\"",
  sco2pes_checklist_financial_bookings_financial_bookings: "Unter Buchungen > Finanzbuchungen",
  sco2pes_checklist_commuter_surveys: "Pendlerumfragen",
  sco2pes_checklist_commuter_surveys_design: "Entwerfen und Starten von Umfragen für gewünschte Buchungskreise",
  sco2pes_checklist_commuter_surveys_check: "Überprüfen von Warnungen und Ausschließen nicht relevanter Einträge",
  sco2pes_checklist_commuter_surveys_close: "Abschluss der Umfragen für jeden Buchungskreis",
  sco2pes_checklist_reports: "Berichte",
  sco2pes_checklist_reports_plausibility_check: "Plausibilitätsprüfung: Überprüfen aller Buchungen in der Buchungssektion der Software, um sicherzustellen, dass der Bericht abgeschlossen werden kann",
  sco2pes_checklist_reports_finalize: "Fertigstellen des Emissions-Excel-Berichts (Sperren aller Buchungen)",
  sco2pes_checklist_reports_submit_data: "Falls die Berichterstattung vertraglich festgelegt ist: Unternehmensspezifische Daten zur KPI-Auswertung für das Berichtsjahr über das Support-Ticket einreichen:",
  sco2pes_checklist_reports_total_employee_count: "Gesamtzahl der Mitarbeitenden (Headcounts) für das Berichtsunternehmen",
  sco2pes_checklist_reports_total_office_space: "Bürofläche gesamt (m²)",
  sco2pes_checklist_reports_total_revenue: "Gesamtumsatz im Berichtsjahr sowie Währung",
  sco2pes_checklist_reports_submit_company_description: "Falls die Berichterstattung vertraglich festgelegt ist: Firmenbeschreibung und ggf. Reduktionsmaßnahmen zum Vorjahresvergleich über das Support-Ticket einreichen"
}
