/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_administration_users: "Usuarios",
  customer_administration_invite_user: "Invitar usuario",
  customer_administration_invite: "Invitar",
  customer_administration_profile_status: "Estado del perfil",
  customer_administration_basic_access: "Acceso Básico",
  customer_administration_administration_access: "Acceso de Administración",
  customer_administration_sco2pes_access: "Acceso a GLOBAL FOOTPRINT",
  customer_administration_compliance_access: "Acceso a GLOBAL COMPLIANCE",
  customer_administration_auditor_access: "Acceso de Auditor",
  customer_administration_completed: "Completado",
  customer_administration_invited: "Invitado",
  customer_administration_identifier: "Identificador",
  customer_administration_functions: "Funciones",
  customer_administration_view_dashboard: "Ver panel",
  customer_administration_role: "Rol",
  customer_administration_deactivate: "Desactivar",
  customer_administration_deactivated: "Desactivado",
  customer_administration_deactivate_modal_title: "Desactivar Clave API",
  customer_administration_deactivate_modal_description: "La desactivación de una Clave API no se puede deshacer. <br>¿Realmente desea desactivar la Clave API?",
  customer_administration_api_key_deactivated: "Clave API desactivada con éxito",
  customer_administration_new_api_key: "Nueva Clave API",
  customer_administration_download_api_key: "Descargar Clave API",
  customer_administration_new_api_key_description: "Las Claves API permiten que los sistemas externos se comuniquen con la API de GLOBAL CLIMATE.<br>¿Debería crearse una nueva Clave API?",
  customer_administration_download_api_key_description: "Se ha creado una nueva Clave API. <br> Descargue la Clave API y guárdela de manera segura. GLOBAL CLIMATE almacena una versión encriptada de la clave y no puede descifrar o recrear la información.",
  customer_administration_invite_user_description: "Si invita a un nuevo usuario, se enviará un correo electrónico con un enlace de registro al usuario. Ingrese el correo electrónico del usuario:",
  customer_administration_what_roles_should_get_assigned: "¿Qué roles deben asignarse al nuevo usuario?",
  customer_administration_cant_edit_own_roles: "Los usuarios no pueden editar sus propios roles.",
  customer_administration_view_users_and_api_keys: "Ver usuarios, claves API, licencias y códigos de empresa",
  customer_administration_view_and_create_support_tickets: "Ver y crear tickets de soporte",
  customer_administration_view_csrd_tool: "Ver y observar la Herramienta CSRD",
  customer_administration_view_invite_and_edit_users: "Ver, invitar y editar usuarios",
  customer_administration_view_create_and_deactivate_api_keys: "Ver, crear y desactivar claves API",
  customer_administration_view_and_manage_licenses: "Ver y ordenar licencias",
  customer_administration_view_create_and_edit_company_code: "Ver, crear y editar códigos de empresa",
  customer_administration_view_and_edit_co2_database: "Ver y editar la Base de Datos de CO<sub>2</sub>",
  customer_administration_view_and_edit_suppliers_and_transport_routes: "Ver, crear y editar Proveedores y Rutas de Transporte",
  customer_administration_view_and_edit_co2_bookings: "Ver, crear y editar Reservas de Unidades y Financieras",
  customer_administration_view_and_edit_commuter_surveys: "Ver, crear y editar Encuestas de Commuters",
  customer_administration_download_report: "Descargar Informe",
  customer_administration_view_create_and_edit_assessments: "Ver, crear y editar Calificaciones ESG",
  customer_administration_view_and_edit_partners: "Ver y editar socios",
  customer_administration_share_assessments_with_partners: "Compartir resultados de Calificaciones ESG con socios",
  customer_administration_company_details: "Detalles de la Empresa",
  customer_administration_user: "Usuario",
  customer_administration_license_required: "Licencia requerida",
  customer_administration_licenses: "Licencias",
  customer_administration_license_package: "Paquete de Licencia",
  customer_administration_license_list: "Lista de Licencias",
  customer_administration_employees: "Empleados",
  customer_administration_buy_license: "Comprar Licencia",
  customer_administration_license_status: "Estado de la Licencia",
  customer_administration_license_info: "Información de la Licencia",
  customer_administration_license_costs: "Costos de la Licencia",
  customer_administration_plus_vat: "más IVA",
  customer_administration_ordered_by: "Ordenado por",
  customer_administration_invoice_status: "Estado de la Factura",
  customer_administration_ordered: "Ordenado",
  customer_administration_active: "Activo",
  customer_administration_void: "Nulo",
  customer_administration_download_invoice: "Descargar Factura",
  customer_administration_open_payment_page: "Abrir Página de Pago",
  customer_administration_open: "Abierto",
  customer_administration_paid: "Pagado",
  customer_administration_the_license_includes: "La licencia incluye",
  customer_administration_please_validate_billing_info: "Por favor, valide y adapte la información de facturación",
  customer_administration_small: "Pequeño",
  customer_administration_medium: "Mediano",
  customer_administration_large: "Grande",
  customer_administration_invoice_email: "Correo Electrónico de Factura",
  customer_administration_tax_id: "ID Fiscal",
  customer_administration_buy_license_description_1: "Al continuar, se enviará una factura a la dirección de correo electrónico proporcionada.",
  customer_administration_buy_license_description_2: "Puede pagar a través de nuestro proveedor de servicios de pago Stripe (tarjeta de crédito) o mediante transferencia bancaria. Si transfiere el monto directamente, la licencia se desbloqueará tan pronto como se registre su pago.",
  customer_administration_thanks_for_ordering_license: "¡Gracias por ordenar una licencia de 1 año!",
  customer_administration_we_will_send_you_the_invoice: "Le enviaremos la factura al correo electrónico proporcionado ({0}), pero también puede descargarla aquí.",
  customer_administration_payment_description: "Si paga a través de nuestro proveedor de servicios de pago Stripe, podemos activar su licencia de inmediato.",
  customer_administration_activating_license_after_payment: "Si transfiere el monto directamente a la cuenta bancaria especificada en la factura, la licencia se desbloqueará tan pronto como se reciba su pago.",
  customer_administration_pay_now: "Pagar ahora",
  customer_administration_next: "Siguiente",
  customer_administration_requested_at: "Fecha de Pedido",
  customer_administration_validity_period: "Período de Validez",
  customer_administration_invalid_vat_tax_id: "ID Fiscal de IVA inválido",
  customer_administration_invitation_language: "Idioma del correo de invitación",
};
