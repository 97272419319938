/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_esg_general_information: "General Information",
  customer_esg_rating_result: "Rating Result",
  customer_esg_rating_details: "Rating Details",
  customer_esg_scorecard_not_found: "Scorecard not found!",
  customer_esg_total_score: "Total",
  customer_esg_partner_ratings: "Partner Ratings",
  customer_esg_ratings_current_state: "Current State",
  customer_esg_ratings_progress_information: "Progress Information",
  customer_esg_result: "Result",
  customer_esg_status_completed: "Completed",
  customer_esg_status_in_review: "In Review",
  customer_esg_risk_analyses: "ESG Risk Analyses",
  customer_esg_risk_analyses_overview: "Overview",
  customer_esg_risk_analyses_partners: "Partners",
  customer_esg_risk_analyses_partners_excel_export: "Export to Excel",
  customer_esg_risk_analyses_partners_added: "Partners added",
  customer_esg_risk_analyses_dashboard: "Dashboard",
  customer_esg_risk_analyses_dashboard_invitation_accepted: "Invitation Accepted",
  customer_esg_risk_analyses_dashboard_open_invitations: "Open Invitations",
  customer_esg_risk_analyses_dashboard_started_surveys: "Started Surveys",
  customer_esg_risk_analyses_dashboard_completed_surveys: "Completed Surveys",
  customer_esg_risk_analyses_information: "Information",
  customer_esg_risk_analyses_evaluation_period: "Evaluation Period",
  customer_esg_risk_analyses_deadline: "Deadline",
  customer_esg_risk_analyses_deadline_defined: "Deadline defined",
  customer_esg_risk_analyses_invitation_message: "Invitation Message",
  customer_esg_risk_analysis_invitation_message_placeholder: "Dear Partners, we are performing an ESG Risk Analysis. Please answer all questions until the {deadline}, ...",
  customer_esg_risk_analyses_invitation_message_set: "Invitation message defined",
  customer_esg_risk_analyses_topics: "Topics",
  customer_esg_risk_analyses_view_questions: "View Questions",
  customer_esg_risk_analyses_table_info: "Perform an ESG risk analysis with GLOBAL CLIMATE. In the first step, we work with you to develop a questionnaire and KPIs tailored to your needs. Through the GLOBAL SUITE, you can ask your partners to perform the ESG rating. Our analysts review the answers of your partners and release the results to you.",
  customer_esg_risk_analyses_edit_title: "Edit ESG Risk Analysis",
  customer_esg_risk_analyses_delete_partner_message: "Do you really want to remove partner '{0}' from ESG Risk Analysis?",
  customer_esg_risk_analyses_add_partner_title: "Add partner to ESG Risk Analysis",
  customer_esg_risk_analyses_start_analysis_title: "Start ESG Risk Analysis",
  customer_esg_risk_analyses_add_partner_subtitle: "Partners you add to the risk analysis will be prompted by email to respond to the ESG rating when you start the risk analysis.",
  customer_esg_risk_analyses_partner_multiselect: "Partner Multiselect",
  customer_esg_risk_analyses_distribution_of_grades: "Distribution of grades",
  customer_esg_risk_analyses_results: "Results",
  customer_esg_risk_analyses_progress_statistics: "Progress",
  customer_esg_start_esg_survey_card_title: "Start Survey",
  customer_esg_start_new_esg_survey_alert: "Click here for starting the ESG rating",
  customer_esg_start_short_esg_survey_alert: "Click here if you have already an ESG rating for sharing",
  customer_esg_start_esg_survey_button: "Start ESG Rating",
  customer_esg_start_short_esg_survey_button: "Share existing ESG rating",
  customer_esg_start_esg_survey: "Start ESG Rating",
  customer_esg_start_esg_survey_text_1: "When answering the survey questions, the progress will be saved automatically. Please answer all necessary questions.",
  customer_esg_start_esg_survey_text_2: "Your answers will be analyzed by GLOBAL CLIMATE at the end of the rating. The final result will be automatically forwarded to your partner.",
  customer_esg_start_esg_survey_text_3: "If you have any questions, you can contact us via the support ticket system.",
  customer_esg_start_esg_survey_text_4: "Do you want to start the survey? Your partner <u>{partnerName}</u> will be automatically informed that you are participating in the survey.",
  customer_esg_start_short_esg_survey_rating: "Share ESG Rating",
  customer_esg_start_short_esg_survey_rating_text_1: "If you already have an ESG rating result that you want to share with <u>{partnerName}</u>, you can use this to start a shortened questionnaire in which you upload the result.",
  customer_esg_start_short_esg_survey_rating_text_2: "Use this option only if you have already an ESG rating result. Otherwise, please start a new ESG rating via our platform.",
  customer_esg_start_short_esg_survey_rating_text_3: "When answering the survey questions, the progress will be saved automatically. Please answer all necessary questions.",
  customer_esg_start_short_esg_survey_rating_text_4: "If you have any questions, you can contact us via the support ticket system.",
  customer_esg_continue_esg_rating: "Continue ESG Rating",
  customer_esg_view_survey: "View Survey",

  // Rating Request
  customer_esg_rating_request_details_status_1: "Rating initiated",
  customer_esg_rating_request_details_status_2: "Survey in progress",
  customer_esg_rating_request_details_status_3: "Answers in review",
  customer_esg_rating_request_details_status_4: "Score shared with Partner",
  customer_esg_rating_request_submit_survey: "Submit survey",
  customer_esg_rating_request_submit_description: "After submitting the survey you will be not able to modify the answers. Do you want to submit the survey?",
  customer_esg_rating_request_submitted_message: "Your answers have been submitted. You will receive an E-Mail once the results are available.",
  customer_esg_rating_request_complete_survey_description_2: "To complete the survey, all questions must be answered without validation errors.",
  customer_esg_rating_request_uploaded_documents: "Uploaded Documents",
  customer_esg_rating_request_number_of_questions: "Number of questions",
  customer_esg_rating_request_number_of_invalid_answered_questions: "Number of questions with validation error",
  customer_esg_rating_request_and_more: "And {0} more...",
  customer_esg_rating_request_question: "Question {0}",
  customer_esg_rating_request_validity: "Validity",
  customer_esg_rating_request_scorecard: "Scorecard",
  customer_esg_rating_request_state_invitation_pending: "Invitation Pending",
  customer_esg_rating_request_state_invitation_accepted: "Invitation Accepted",
  customer_esg_rating_request_state_in_progress: "ESG Rating In Progress",
  customer_esg_rating_request_state_in_review: "ESG Rating In Review",
  customer_esg_rating_request_state_completed: "ESG Rating Completed",
};
