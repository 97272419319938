import { messages as administration } from "./administration/es";
import { messages as checklist } from "./checklist/es";
import { messages as partners } from "./partners/es";
import { messages as sco2pes } from "./sco2pes/es";
import { messages as esg } from "./esg/es";
import { messages as support } from "./support/es";
import { messages as welcome } from "./welcome/es";
import { messages as surveys } from "./surveys/es";
import { messages as faq } from "./faq/es";

export const messages = {
  ...administration,
  ...checklist,
  ...partners,
  ...sco2pes,
  ...esg,
  ...support,
  ...welcome,
  ...surveys,
  ...faq,
};
