/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_units_search: "Suche nach Name / Symbole / Beschreibung",
  system_admin_units_only_global_units: "Nur globale Einheiten",
  system_admin_units_administration: "Administration",
  system_admin_units_display_name: "Anzeigename",
  system_admin_units_symbol: "Symbol",
  system_admin_units_symbols: "Symbole",
  system_admin_units_removable: "Löschbar",
  system_admin_units_unit_deleted: "Einheit erfolgreich gelöscht",
  system_admin_units_deletion_confirmation: "Sind Sie sicher, dass Sie diese Einheit dauerhaft löschen möchten?",
  system_admin_units_deletion_disabled_desc: "Diese Einheit kann nicht entfernt werden, da der Löschbar-Schalter derzeit deaktiviert ist.",
  system_admin_units_company_info: "Firmeninfo",
  system_admin_units_removable_desc: "Wenn der 'Löschbar'-Schalter aktiviert ist, können Sie die Einheit löschen. Wenn dieser deaktiviert ist, können Sie die Einheit nicht löschen.",
  system_admin_units_unit_info: "Einheitsinfo",
  system_admin_units_conversion: "Umrechnung",
  system_admin_units_company_info_must_specified: "Firmeninfo muss angegeben werden (nur wenn die Einheit nicht global ist)",
  system_admin_units_unit_name_must_specified: "Einheit-Name muss in mindestens einer Sprache angegeben werden",
  system_admin_units_at_least_symbol_must_specified: "Mindestens 1 Symbol muss angegeben werden",
  system_admin_units_conversion_requirements_are_satisfied: "Umrechnungs-Voraussetzungen sind erfüllt",
  system_admin_units_unit_name: "Einheit-Name",
  system_admin_units_symbols_description: "Symbole dürfen nicht leer sein und die maximale Länge für jedes Symbol beträgt 32 Zeichen.",
  system_admin_units_conversion_description: "Dies ist eine Liste an Umrechnungen für diese Einheit. Ein Beispiel für eine Umrechnung wäre: Wenn die aktuelle Einheit 'kg' (Zieleinheit) ist und Sie 'gr' (Ursprungseinheit) wählen, ist der Koeffizient 1000. Das bedeutet, dass 1 kg = 1000 x 1 gr.",
  system_admin_units_conversion_to: "Zieleinheit wählen",
  system_admin_units_coefficient: "Koeffizient",
  system_admin_units_conversion_deletion_confirmation: "Sind Sie sicher, dass Sie diese Umrechnung dauerhaft löschen möchten?",
  system_admin_units_symbol_deletion_confirmation: "Sind Sie sicher, dass Sie dieses Symbol dauerhaft löschen möchten?",
  system_admin_units_symbol_tooltip_info: "Bitte geben Sie einen Text ein, der 1 bis 32 Zeichen lang sein kann und Buchstaben (A-Z, a-z), Zahlen (0-9), Sonderzeichen (/ . - _ ( ) Leerzeichen) und verschiedene nicht-ASCII-Zeichen aus aller Welt enthalten kann.",
};
