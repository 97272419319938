/* eslint-disable */
// prettier-ignore

export const messages = {
  validity_period_is_start_switch: "Is Start?",
  validity_period_start: "Start",
  validity_period_valid_from: "Valid From",
  validity_period_is_end_switch: "Is End?",
  validity_period_end: "End",
  validity_period_valid_until: "Valid Until",
  validity_add_version: "Add version",
  validity_period_initial_version: "Initial version",
  validity_period_set_starting_version_date: "Set starting version date",
  validity_period_starting_from: "Starting from",
  validity_period_starting_date: "Version Start Date",
};
