/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_distance_calc_title: "Cálculo de Distancia",
  system_admin_distance_calc_calculate: "Calcular distancias en el archivo",
  system_admin_distance_calc_upload_title: "Calcular distancias en el archivo",
  system_admin_distance_calc_upload_subtitle:
    "Lee el archivo y calcula las distancias para cada fila de Excel y luego descargará un archivo similar con las distancias",
  system_admin_distance_calc_description: `
  <p>
    Suba una lista de direcciones y el sistema calculará la distancia para cada par de origen y destino.
    <br>
    Si la columna "Distancia" ya tiene un número ingresado, entonces el sistema omitirá esa fila de Excel por completo.
  </p>
  <p>El sistema utiliza la API de Google Maps para calcular distancias.</p>
  <p>La empresa incurre en costos adicionales por cada archivo que se sube.</p>
  `,
};
