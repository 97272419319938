/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_scopes_suppliers_emission_relevant: "Emisión Relevante",
  customer_scopes_suppliers_transport_routes: "Rutas de Transporte",
  customer_scopes_suppliers_transport_route: "Ruta de Transporte",
  customer_scopes_suppliers_edit_details: "Editar Detalles",
  customer_scopes_suppliers_co2_relevant: "Relevante para CO<sub>2</sub>",

  // Welcome
  customer_sco2pes_welcome: "Bienvenido a GLOBAL FOOTPRINT",
  customer_sco2pes_welcome_message: "Bienvenido al módulo GLOBAL FOOTPRINT, la herramienta para calcular la huella de carbono corporativa (CCF) de su empresa.<br/>Con la ayuda de GLOBAL FOOTPRINT, puede mostrar y analizar fácilmente los datos que ya ha ingresado con solo un clic.<br/>Además, puede ingresar datos de forma independiente y realizar reservas para un período de informe deseado.<br/>Como resultado de la contabilidad de carbono con GLOBAL FOOTPRINT, recibe un informe de balance de gases de efecto invernadero de acuerdo con las directrices del Protocolo de Contabilidad y Reporte Corporativo de Gases de Efecto Invernadero (GHG Protocol).",
  customer_sco2pes_your_overview: "Su Resumen",
  customer_sco2pes_your_feedback: "Su Retroalimentación",
  customer_sco2pes_your_feedback_message: "Estamos desarrollando y mejorando constantemente nuestro software. Por lo tanto, agradecemos cualquier tipo de retroalimentación y sugerencias de mejora e intentamos implementarlas lo antes posible.",
  customer_sco2pes_give_feedback: "Dar Retroalimentación",
  customer_sco2pes_type_feedback: "Escriba su retroalimentación aquí...",
  customer_sco2pes_feedback_success: "¡Gracias por Compartir Sus Ideas!",

  // License info
  customer_ccf_license_required: "Acceso restringido: Se requiere licencia",
  customer_ccf_license_info: "Su empresa no tiene una licencia de GLOBAL FOOTPRINT.<br>Si está interesado en el producto, no dude en contactar al equipo de GLOBAL CLIMATE.",
  customer_sco2pes_further_info: "Puede encontrar más información aquí:",

  customer_sco2pes_scopes_info: "Información de GLOBAL FOOTPRINT",
  customer_sco2pes_scope_1_description: "El Alcance 1 incluye las emisiones directas de gases de efecto invernadero causadas directamente por una organización. Esto generalmente incluye emisiones de fuentes bajo el control de la empresa. Las emisiones típicas del Alcance 1 incluyen procesos de combustión de combustibles como gas, petróleo o carbón en instalaciones y vehículos propiedad de la empresa. Otros ejemplos son las emisiones de procesos industriales o de la incineración de residuos. Las emisiones del Alcance 1 se miden en toneladas de CO<sub>2</sub> equivalente (tCO<sub>2</sub>e).",
  customer_sco2pes_scope_2_description: "El Alcance 2 se refiere a las emisiones indirectas de gases de efecto invernadero que surgen de la adquisición y consumo de energía eléctrica, vapor o calor. Estas emisiones no se generan directamente en el sitio, sino a través de la generación de la energía utilizada por una empresa. Las emisiones del Alcance 2 surgen, por ejemplo, cuando se queman combustibles fósiles para generar electricidad en plantas de energía. Las empresas miden estas emisiones multiplicando el uso de energía comprada por factores de emisión específicos proporcionados por los proveedores de energía.",
  customer_sco2pes_scope_3_description: "El Alcance 3 incluye todas las demás emisiones indirectas de gases de efecto invernadero que resultan de las operaciones de una empresa pero están fuera de su control directo. Este alcance cubre toda la cadena de suministro y otras actividades externas, incluidas las emisiones asociadas con la extracción y fabricación de materiales, el transporte, uso o eliminación de productos, y los viajes de negocios y desplazamientos diarios de los empleados. Las emisiones del Alcance 3 a menudo son la mayor fuente de emisiones de gases de efecto invernadero de una empresa. Registrar y evaluar las emisiones del Alcance 3 requiere colaboración con proveedores y otros socios relevantes.",
  customer_sco2pes_summary_info: "Al categorizar las emisiones de gases de efecto invernadero en estos alcances, el Protocolo GHG permite a las empresas registrar y reportar sus emisiones de manera integral. Esto permite a las empresas trabajar específicamente para reducir las emisiones optimizando sus procesos internos, cambiando a energía renovable, utilizando métodos de transporte más eficientes y construyendo cadenas de suministro más sostenibles. Contribuye a la transparencia y comparabilidad de los datos de emisiones y apoya a las empresas en el desarrollo e implementación de estrategias de protección climática.",

  // CO2 database
  customer_sco2pes_co2_database_title: "Base de Datos de CO<sub>2</sub>",
  customer_sco2pes_co2_database_subtitle: "Con su base de datos individual de CO<sub>2</sub>, puede crear y gestionar sus propios tipos de CO<sub>2</sub>. Además, encontrará todos los tipos de CO<sub>2</sub> que ya se han creado para usted.<br/>Si ya conoce los detalles necesarios para la creación del tipo de CO<sub>2</sub> (como emisiones por unidad, peso por unidad, origen), puede crear sus propios tipos de CO<sub>2</sub> a través de \"NUEVO TIPO DE CO<sub>2</sub>\".",
  customer_sco2pes_co2_database_new_co2_type: "Nuevo Tipo de CO<sub>2</sub>",
  customer_sco2pes_co2_database_type_description: "Descripción del Tipo",
  customer_sco2pes_co2_database_type_name: "CO<sub>2</sub> Type name",
  customer_sco2pes_co2_database_unit: "Unidad",
  customer_sco2pes_co2_database_co2_equivalent: "Equivalente de CO<sub>2</sub>",
  customer_sco2pes_co2_database_emissions_per_unit: "Emisiones por Unidad",
  customer_sco2pes_co2_database_weight_per_unit: "Peso (kg) por Unidad",
  customer_sco2pes_co2_database_do_you_really_want_to_delete_the_co2_type: "¿Realmente desea eliminar el Tipo de CO<sub>2</sub>?",
  customer_sco2pes_co2_database_description_in_english: "Descripción en Inglés",
  customer_sco2pes_co2_database_description_in_german: "Descripción en Alemán",
  customer_sco2pes_co2_database_edit_co2_type: "Editar Tipo de CO<sub>2</sub>",
  customer_sco2pes_co2_database_co2_type_successfully_created: "Tipo de CO<sub>2</sub> creado con éxito",
  customer_sco2pes_co2_database_co2_type_successfully_deleted: "Tipo de CO<sub>2</sub> eliminado con éxito",
  customer_sco2pes_co2_database_co2_type_successfully_edited: "Tipo de CO<sub>2</sub> editado con éxito",
  customer_sco2pes_co2_database_origin_database: "Base de Datos de Origen",
  customer_sco2pes_co2_database_origin_database_reference: "Referencia de la Base de Datos de Origen",
  customer_sco2pes_co2_database_origin_database_description: "Para el mantenimiento de los Tipos de CO<sub>2</sub> y la auditoría posterior, es necesario almacenar una referencia al origen del Equivalente de CO<sub>2</sub>.",
  customer_sco2pes_co2_database_versions: "Versiones",
  customer_sco2pes_co2_database_version: "Versión",
  customer_sco2pes_co2_database_from: "Desde",
  customer_sco2pes_co2_database_until: "Hasta",
  customer_sco2pes_co2_database_dialog_date_not_valid: "<strong>Desde</strong> debe ser antes o igual que <strong>Hasta</strong>",
  customer_sco2pes_co2_database_dialog_kg_not_valid: "El Equivalente en kg debe ser 1 si la unidad es <strong>kg</strong>",
  customer_sco2pes_co2_database_version_delete_confirmation_title: "Eliminar Versión",
  customer_sco2pes_co2_database_version_delete_confirmation_message: "¿Está seguro de que desea eliminar esta versión?",
  customer_sco2pes_co2_database_no_version_message: "Se requiere al menos una versión",
  customer_sco2pes_co2_database_versions_not_valid: "Siempre debe haber exactamente una versión por período de pedido (sin superposiciones, sin huecos). La primera versión debe ser válida desde 0000-01, la última versión debe ser válida hasta 9999-12.",
  customer_sco2pes_co2_database_versions_kg_not_valid: "El Equivalente en kg debe ser 1 si la unidad es <strong>kg</strong>",
  customer_sco2pes_co2_database_new_version: "Nueva Versión",
  customer_sco2pes_co2_database_edit_version: "Editar Versión",
  customer_sco2pes_co2_database_created_at: "Creado en",
  customer_sco2pes_co2_database_versions_count: "Cantidad de Versiones",
  customer_sco2pes_co2_database_create_unit_booking: "Crear Reserva de Unidad",
  customer_sco2pes_co2_database_amount: "Cantidad",
  customer_sco2pes_co2_database_distance: "Distancia",
  customer_sco2pes_co2_database_order_id: "ID de Pedido",
  customer_sco2pes_co2_database_supplier: "Proveedor",
  customer_sco2pes_co2_database_transport_route: "Ruta de Transporte",
  customer_sco2pes_co2_database_booking_creation_success_title: "¡Reserva de CO<sub>2</sub> creada con éxito!",
  customer_sco2pes_co2_database_booking_creation_success_show_bookings: "Mostrar Reservas",
  customer_sco2pes_co2_database_distance_multiply_description: "La distancia total es el producto de la cantidad y la distancia",
  customer_sco2pes_co2_database_co2_type_desc: "Los datos relevantes para las emisiones pueden versionarse a lo largo del tiempo. Las versiones pueden crearse en la resolución temporal de meses. Las versiones deben cubrir todos los meses posibles, comenzando con \"Inicio\" hasta \"Fin\".",
  customer_sco2pes_co2_database_type_valid_versions: "El período de validez de las versiones no tiene huecos ni superposiciones",
  customer_sco2pes_co2_database_type_valid: "Todos los datos requeridos proporcionados",
  customer_sco2pes_co2_database_import_title: "Importar Tipos de CO<sub>2</sub>",
  customer_sco2pes_co2_database_import_subtitle: "Los Tipos de CO<sub>2</sub> pueden importarse a través de un archivo de Excel. Use esta plantilla de Excel para la carga de archivos:",
  customer_sco2pes_co2_database_co2_category: "Categoría de CO<sub>2</sub>",
  customer_sco2pes_co2_database_freetext_search: "Búsqueda de Texto Libre (ID/Nombre)",
  customer_sco2pes_co2_database_type_active_checkbox: "Tipo de CO<sub>2</sub> activo",
  customer_sco2pes_co2_database_documentation: "Documentación",
  customer_sco2pes_co2_database_external_co2_emissions_factor: "Base de Datos de Emisiones",
  customer_sco2pes_co2_database_manual_data_entry: "Entrada Manual de Datos",
  customer_sco2pes_co2_database_scale_factor: "Factor de Escala",
  customer_sco2pes_co2_database_emission_factor_imported: "Factor de emisión importado de la base de datos de emisiones",
  customer_sco2pes_co2_database_database: "Base de Datos",
  customer_sco2pes_co2_database_emission_factor_name: "Nombre del factor de emisión",
  customer_sco2pes_co2_database_emission_factor_value: "Valor del factor de emisión",
  customer_sco2pes_co2_database_scale_factor_warning: "Asegúrese de que el valor del Factor de Escala esté correctamente configurado. Establezca el valor de acuerdo con las unidades.",
  customer_sco2pes_co2_database_scale_factor_description: "Si la unidad del tipo de CO<sub>2</sub> no coincide con la base de datos de emisiones, se puede aplicar el factor de escala para ajustar el factor de emisión en consecuencia.",
  customer_sco2pes_co2_database_change_emission_factor: "Cambiar factor de emisión",
  customer_sco2pes_co2_database_version_emission_dialog_heading: "Factores de Emisión de CO<sub>2</sub>",
  customer_sco2pes_co2_database_scope_warning: "Tenga en cuenta que, al calcular emisiones en el Alcance 1 y/o Alcance 2, también debe crear un Tipo de CO<sub>2</sub> para el Alcance 3.3 con el fin de calcular por separado las emisiones de la cadena ascendente correspondientes. Cree otro Tipo de CO<sub>2</sub> para este propósito.",
  customer_sco2pes_co2_database_data_origin: "Origen de los Datos",
  customer_sco2pes_co2_database_view_co2_type: "Ver Tipo de CO<sub>2</sub>",

  customer_sco2pes_co2_type_tags: "Etiquetas de Tipo de CO<sub>2</sub>",
  customer_sco2pes_co2_type_tags_subtitle: "Las Etiquetas de Tipo de CO<sub>2</sub> son una forma de agregar información personalizada a cada Tipo de CO<sub>2</sub>. Le permite conectar/integrar Tipos de CO<sub>2</sub> de la base de datos de Global Climate a su propio sistema.",
  customer_sco2pes_co2_type_tags_tagging_unavailable: "El etiquetado aún no está disponible para su empresa. Primero defina algunas etiquetas.",
  customer_sco2pes_co2_type_tags_add_tag: "Agregar etiqueta",
  customer_sco2pes_co2_type_tags_enter_edit_mode: "Entrar en modo de edición",
  customer_sco2pes_co2_type_tags_enter_edit_mode_tooltip: "Sus cambios se guardarán automáticamente cuando comience a escribir en el campo de entrada.",
  customer_sco2pes_co2_type_tags_exit_edit_mode: "Salir del modo de edición",
  customer_sco2pes_co2_type_tags_edit_mode_info: "Sus cambios se guardarán automáticamente mientras escribe dentro de la tabla.",
  customer_sco2pes_co2_type_tags_deletion_confirmation: "¿Está seguro de que desea eliminar permanentemente esta Etiqueta de Tipo de CO<sub>2</sub>?",
  customer_sco2pes_co2_type_tags_edit_tag: "Editar Etiqueta",
  customer_sco2pes_co2_type_tags_search: "Buscar (ID, Nombre, Etiqueta)",

  customer_sco2pes_configuration_information: "Information",
  customer_sco2pes_configuration_contact_support: "Si no se muestran datos, comuníquese con el equipo de soporte.",
  customer_sco2pes_configuration_tag_definitions: "Definiciones de Etiquetas",
  customer_sco2pes_configuration_composite_keys: "Claves Compuestas",
  customer_sco2pes_configuration_selection_policies: "Políticas de Selección",
  customer_sco2pes_configuration_column_index: "Índice de Columna",
  customer_sco2pes_configuration_unique: "Único",
  customer_sco2pes_configuration_key_chain: "Cadena de Claves",
  customer_sco2pes_configuration_key_type: "Tipo de Clave",
  customer_sco2pes_configuration_key_name: "Nombre de Clave",
  customer_sco2pes_configuration_required_tag_defs: "Definiciones de Etiquetas Requeridas",
  customer_sco2pes_configuration_tags: "Etiquetas",
  customer_sco2pes_configuration_json_key: "Clave JSON",
  customer_sco2pes_configuration_in_use_in_automation_api: "en uso en la API de Automatización",
  customer_sco2pes_configuration_esrs_mapping: "Mapeo ESRS",
  customer_sco2pes_configuration_esrs_mapping_description_1: "Con el mapeo ESRS, las reservas del CCF pueden enriquecerse con métricas ESRS para la CSRD, que están disponibles a través de la API de Automatización V5.",
  customer_sco2pes_configuration_esrs_mapping_description_2: "Para cada métrica ESRS, se pueden configurar uno o más alcances, categorías de CO<sub>2</sub> y/o tipos de CO<sub>2</sub>. Todas las reservas que coincidan con estas configuraciones serán enriquecidas con la métrica ESRS correspondiente.",
  customer_sco2pes_configuration_esrs_mapping_description_3: "La configuración del mapeo se puede descargar y editar como un archivo de Excel. Después de la edición, el archivo se puede cargar nuevamente. Durante la carga, las columnas se validan y la configuración se guarda. En caso de errores de validación, la configuración no se guardará y se mantendrá la configuración anterior.",
  customer_sco2pes_configuration_esrs_mapping_description_4: "Nota: Para mejorar la legibilidad, el archivo de Excel descargado se enriquece con columnas adicionales. Estas columnas no son relevantes para la configuración y pueden ignorarse durante la carga.",
  customer_sco2pes_configuration_esrs_mapping_download: "Descargar Configuración de Mapeo ESRS",
  customer_sco2pes_configuration_esrs_mapping_upload: "Cargar Configuración de Mapeo ESRS",
  customer_sco2pes_configuration_esrs_mapping_subtitle: "La configuración del mapeo ESRS se puede cargar mediante un archivo de Excel. Utilice esta plantilla de Excel para la carga del archivo:",

  // External Databases
  customer_sco2pes_co2_database_own_emission_factors: "Factores de Emisión Propios",
  customer_sco2pes_co2_database_own_co2_type: "Tipo de CO<sub>2</sub> Propio",
  customer_sco2pes_co2_database_global_emission_factors: "Tipos de CO<sub>2</sub> Gestionados",
  customer_sco2pes_co2_database_external_databases: "Bases de Datos Científicas Externas",
  customer_sco2pes_co2_database_external_databases_search: "Búsqueda de Texto Libre",
  customer_sco2pes_co2_database_database_label: "Base de Datos",
  customer_sco2pes_co2_database_valid_from: "Válido Desde",
  customer_sco2pes_co2_database_valid_until: "Válido Hasta",
  customer_sco2pes_co2_database_name_description: "Nombre/Descripción",
  customer_sco2pes_co2_database_valid_period: "Validez (desde - hasta)",
  customer_sco2pes_co2_database_geography: "Geografía",
  customer_sco2pes_co2_database_actions: "Acciones",
  customer_sco2pes_co2_database_details: "Detalles",
  customer_sco2pes_co2_database_delete: "Eliminar",
  customer_sco2pes_co2_database_ecoinvent_licensing: "Los factores de Ecoinvent no se muestran por razones de licencia",

  // Bookings
  customer_sco2pes_booking_successfully_canceled: "Reserva cancelada con éxito",
  customer_sco2pes_cancel_bookings: "Cancelar reservas",
  customer_sco2pes_confirm_canceling_selected_bookings: "¿Realmente desea cancelar las {selectedBookingsCount} reservas seleccionadas?",
  customer_sco2pes_bookings_successfully_canceled: "{selectedBookingCount} reserva(s) cancelada(s) con éxito.",

  customer_sco2pes_pending_unit_bookings: "Reservas de Unidades Pendientes",
  customer_sco2pes_pending_unit_bookings_details: "Detalles de Reservas de Unidades Pendientes",
  customer_sco2pes_pending_financial_bookings: "Reservas Financieras Pendientes",
  customer_sco2pes_pending_financial_bookings_details: "Detalles de Reservas Financieras Pendientes",
  customer_sco2pes_pending_unit_bookings_search_fields: "Puede buscar dentro de los siguientes campos:<br/><ul><li>Valor de Etiqueta</li><li>ID de Pedido</li><li>Nombre y Código de la Empresa</li><li>Nombre del Tipo de CO<sub>2</sub></li><li>Nombre de la Categoría de CO<sub>2</sub></li><li>Nombre del Proveedor</li><li>ID del Acreedor</li></ul>",
  customer_sco2pes_pending_financial_bookings_search_fields: "Puede buscar dentro de los siguientes campos:<br/><ul><li>Origen</li><li>Nombre y Código de la Empresa</li><li>Nombre del Modelo Financiero</li><li>Número de Cuenta del Modelo Financiero y Número de Pedido Interno</li><li>Nombre del Proveedor</li><li>ID del Acreedor</li></ul>",
  customer_sco2pes_pending_bookings_error_filter: "Error",
  customer_sco2pes_pending_bookings_error_filter_no_error: "Procesamiento Pendiente (Aún sin errores)",
  customer_sco2pes_pending_bookings_error_filter_all_errors: "Procesado con Errores (Incluye todos los errores)",
  customer_sco2pes_pending_bookings_error_filter_error_code: "Reserva que incluye error:",

  // Unit bookings
  customer_sco2pes_unit_bookings_unit_bookings: "Reservas de Unidades",
  customer_sco2pes_unit_bookings_subtitle: "En esta área, puede crear reservas basadas en unidades de medida (por ejemplo: kWh, kg, l, ...). Para hacerlo, puede usar la \"función de carga\" para exportar la tabla de Excel proporcionada, completarla y cargarla en el software. Alternativamente, puede ingresar los montos de reserva manualmente a través de \"+ RESERVA DE UNIDAD\".",
  customer_sco2pes_unit_bookings_unit_booking: "Reserva de Unidad",
  customer_sco2pes_unit_bookings_creation_dialog_title: "Crear Reserva de Unidad",
  customer_sco2pes_unit_bookings_all_categories: "Todas las Categorías",
  customer_sco2pes_unit_bookings_select_a_category: "Por favor seleccione una categoría",
  customer_sco2pes_unit_bookings_amount: "Cantidad",
  customer_sco2pes_unit_bookings_cancel_booking_question: "¿Realmente desea cancelar la reserva?",
  customer_sco2pes_unit_bookings_show_only_my_bookings: "Mostrar solo mis reservas",
  customer_sco2pes_unit_bookings_order_id: "ID de Pedido",
  customer_sco2pes_unit_bookings_type: "Tipo",
  customer_sco2pes_unit_bookings_supplier: "Proveedor",
  customer_sco2pes_unit_bookings_category: "Categoría",
  customer_sco2pes_unit_bookings_unit_booking_details: "Detalles de la Reserva de Unidad",
  customer_sco2pes_unit_bookings_cancel_booking: "Cancelar Reserva",
  customer_sco2pes_unit_bookings_details: "Detalles",
  customer_sco2pes_unit_bookings_not_set: "No establecido",
  customer_sco2pes_unit_bookings_meta_data: "Meta Datos",
  customer_sco2pes_unit_bookings_co2_values: "Valores de CO<sub>2</sub>",
  customer_sco2pes_unit_bookings_all: "Todo",
  customer_sco2pes_unit_bookings_created_at: "Creado En",
  customer_sco2pes_unit_bookings_financial_bookings: "Reservas Financieras",
  customer_sco2pes_unit_bookings_co2_emissions_in_kg: "Emisiones en kg CO<sub>2</sub>e",
  customer_sco2pes_unit_bookings_financial_booking: "Reserva Financiera",
  customer_sco2pes_unit_bookings_financial_booking_details: "Detalles de la Reserva Financiera",
  customer_sco2pes_unit_bookings_model_description: "Descripción del Modelo",
  customer_sco2pes_unit_bookings_cancel_booking_warning: "Esta reserva está relacionada con la reserva financiera {0}.<br> Se puede cancelar desde los detalles de la reserva financiera.",
  customer_sco2pes_unit_bookings_co2_category: "Categoría de CO<sub>2</sub>",
  customer_sco2pes_unit_bookings_booking_creation_success_title: "¡Reserva de CO<sub>2</sub> creada con éxito!",
  customer_sco2pes_unit_bookings_booking_creation_success_next_booking: "Siguiente Reserva",
  customer_sco2pes_unit_bookings_booking_creation_success_show_bookings: "Mostrar Reservas",
  customer_sco2pes_unit_bookings_co2_type: "Tipo de CO<sub>2</sub>",
  customer_sco2pes_unit_bookings_auto_generated: "Generado Automáticamente",
  customer_sco2pes_unit_bookings_auto_generated_by_financial_booking: "Por reserva financiera",
  customer_sco2pes_unit_bookings_auto_generated_by_commuter_survey: "Por encuesta de viajeros",
  customer_sco2pes_unit_bookings_advance_search: "Búsqueda Avanzada",
  customer_sco2pes_unit_bookings_distance_multiply_description: "La distancia total es el producto de la cantidad y la distancia",
  customer_sco2pes_unit_bookings_copy_co2_type: "Haga clic para copiar el ID del Tipo de CO<sub>2</sub>",
  customer_sco2pes_unit_bookings_fill_the_details: "Complete los detalles",
  customer_sco2pes_unit_bookings_select_co2_type_desc: "Seleccione el Tipo de CO<sub>2</sub> que desea crear la reserva, y después continúe completando los detalles de la reserva.",
  customer_sco2pes_unit_bookings_cancel_creating_booking: "Cancelar creación de reserva",
  customer_sco2pes_unit_bookings_cancel_creating_booking_question: "¿Realmente desea cancelar la creación de la reserva?",
  customer_sco2pes_unit_bookings_select_an_order_period: "Seleccione un período de pedido",
  customer_sco2pes_unit_bookings_create: "Crear",
  customer_sco2pes_unit_bookings_clone_dialog_title: "Clonar reservas de unidades",
  customer_sco2pes_unit_bookings_clone_dialog_order_id_prefix: "Prefijo de ID de Pedido",
  customer_sco2pes_unit_bookings_clone_dialog_order_id_prefix_desc: `¡El ID de Pedido no se clonará! en su lugar, generamos nuevos para usted. Todas las reservas clonadas comenzarán con este prefijo de ID de Pedido. Por ejemplo, si ingresa "clon-", entonces el ID de pedido de las reservas clonadas será: <b>clon-01, clon-02, ..., clon-124</b>`,
  customer_sco2pes_unit_bookings_clone_dialog_increase_order_period: "Aumentar período de pedido",
  customer_sco2pes_unit_bookings_clone_dialog_increase_order_period_desc: `
  Si desea clonar las reservas en el futuro, ingrese un valor mayor o igual a 0. Este es el número
  de meses que agregamos al período de pedido de cada reserva.
  `,
  customer_sco2pes_unit_bookings_clone_dialog_count: "Reservas de unidades encontradas según el filtro aplicado",
  customer_sco2pes_unit_bookings_clone_dialog_confirmation: "¿Desea clonar {count} reservas de unidades?",
  customer_sco2pes_unit_bookings_clone_dialog_success: "Clonadas {count} reservas de unidades con éxito",
  customer_sco2pes_unit_bookings_created_after: "Creado después de",
  customer_sco2pes_unit_bookings_created_before: "Creado antes de",
  customer_sco2pes_unit_bookings_scope_warning: "Tenga en cuenta que, al calcular emisiones en el Alcance 1 y/o Alcance 2, también se debe considerar el cálculo de emisiones para el Alcance 3.3 con el fin de calcular por separado las emisiones ascendentes correspondientes. Para este propósito, agregue una entrada adicional que registre la misma cantidad al seleccionar el tipo de CO<sub>2</sub> correspondiente del Alcance 3.3.",

  // Unit bookings upload
  customer_sco2pes_unit_bookings_import: "Importar Reservas de Unidades",
  customer_sco2pes_unit_bookings_tag_description: "Etiquetado de nombres de columnas - solo requerido si se utilizan columnas en la carga de Excel:",
  customer_sco2pes_unit_bookings_tag_tooltip: "El uso de etiquetas dentro de las reservas de unidades le permite agregar información adicional a las reservas. Esta información no tiene impacto en el cálculo de emisiones. El uso de etiquetas no es obligatorio para calcular emisiones dentro de la herramienta CCF.",
  customer_sco2pes_unit_bookings_tag_1: "Etiqueta 1",
  customer_sco2pes_unit_bookings_tag_2: "Etiqueta 2",
  customer_sco2pes_unit_bookings_tag_3: "Etiqueta 3",
  customer_sco2pes_unit_bookings_limitations: "Limitaciones",
  customer_sco2pes_unit_bookings_files_maximum_size: "Los archivos no deben ser mayores de 5MB",
  customer_sco2pes_unit_bookings_required_file_type: "Debe ser de tipo xlsx (Excel).",
  customer_sco2pes_unit_bookings_required_sheet_details: "El Excel debe tener solo una hoja.",
  customer_sco2pes_unit_bookings_upload_can_not_be_cancelled: "El proceso de carga no se puede cancelar.",
  customer_sco2pes_unit_bookings_upload_file_multiple_times: "Puede cargar el mismo archivo varias veces, las reservas ya existentes serán ignoradas.",
  customer_sco2pes_unit_bookings_upload_file_not_processed: "El archivo no pudo ser procesado. Ocurrieron los siguientes errores:",
  customer_sco2pes_unit_bookings_upload_output_file_name: "resultados_carga_reserva_unidad",
  customer_sco2pes_unit_bookings_upload_output_sheet_name: "Resultados",
  customer_sco2pes_unit_bookings_import_subtitle: "Las reservas de unidades se pueden importar a través de un archivo de Excel. Use esta plantilla de Excel para cargar el archivo:",

// Financial bookings
  customer_sco2pes_financial_bookings_financial_booking: "Reserva Financiera",
  customer_sco2pes_financial_bookings_subtitle: "En esta sección puede hacer entradas basadas en datos financieros según sus modelos financieros. Para hacerlo, puede usar \"+ RESERVA FINANCIERA\" para ingresar manualmente los montos según su lista de totales y balances.",
  customer_sco2pes_financial_bookings_description: "Por favor ingrese el monto total que se ha reservado en la cuenta <b>{0}</b> (número de cuenta: <b>{1} / {2}</b>) en el período considerado según su balance de prueba / cuenta de pérdidas y ganancias.",
  customer_sco2pes_financial_bookings_description_2: "El ID de Reserva se usa para identificar de manera única la reserva.<br /> Se recomienda usar, por ejemplo, un número de factura.",
  customer_sco2pes_financial_bookings_id: "ID de Reserva",
  customer_sco2pes_financial_bookings_accounting_period: "Período Contable",
  customer_sco2pes_financial_bookings_creation_dialog_title: "Crear Reserva Financiera",
  customer_sco2pes_financial_bookings_amount: "Cantidad",
  customer_sco2pes_financial_bookings_detailed: "Detallado",
  customer_sco2pes_financial_bookings_form_description: "Por favor ingrese los gastos para las diferentes categorías en el período contable que especificó, <strong>excluyendo el impuesto sobre las ventas</strong>.",
  customer_sco2pes_financial_bookings_general_information: "Información General",
  customer_sco2pes_financial_bookings_relevant_information: "información relevante",
  customer_sco2pes_financial_bookings_creation_success_title: "Reserva creada con éxito.",
  customer_sco2pes_financial_bookings_creation_success_next_booking: "Siguiente Reserva",
  customer_sco2pes_financial_bookings_creation_success_show_bookings: "Mostrar Reservas",
  customer_sco2pes_financial_bookings_search_by_origin: "Buscar por origen",
  customer_sco2pes_financial_bookings_cancel_booking: "Cancelar Reserva",
  customer_sco2pes_financial_bookings_cancel_booking_question: "¿Realmente desea cancelar la reserva?",
  customer_sco2pes_financial_bookings_booking_successfully_canceled: "Reserva cancelada con éxito",
  customer_sco2pes_financial_bookings_duplicate_tooltip: "Duplicar",
  customer_sco2pes_financial_bookings_import_title: "Importar Reservas Financieras",
  customer_sco2pes_financial_bookings_import_subtitle: "Las reservas financieras se pueden importar a través de un archivo de Excel. Use esta plantilla de Excel para cargar el archivo:",
  customer_sco2pes_financial_bookings_company_code_not_found: "¡Código de Empresa no encontrado!",
  customer_sco2pes_financial_bookings_create_company_code_warning: "Por favor cree un código de empresa primero para habilitar las reservas financieras.",
  customer_sco2pes_financial_bookings_create_financial_model_warning: "Por favor cree un <a href='{link}'>modelo financiero</a> primero para poder agregar reservas financieras.",

  // Checklist
  customer_sco2pes_checklist_title: "Lista de Verificación",
  customer_sco2pes_handbook_title: "Manual",
  customer_sco2pes_handbook_description: "Aquí puede descargar una guía detallada del software GC para su integración y cálculo sin problemas de su Huella de Carbono Corporativa (CCF). Este manual proporciona claridad y consejos para una transición fluida de trabajar de forma independiente con la plataforma para crear su próximo informe de balance de CO<sub>2</sub>.",
  customer_sco2pes_checklist_information_description: "Esta lista de verificación está preparada para brindar asistencia en la Huella de Carbono Corporativa (CCF) de la empresa para el año de reporte. Los siguientes pasos ofrecen una transición fácil al uso del software, especialmente para los clientes del 2<sup>°</sup> año de reporte en adelante.",
  customer_sco2pes_checklist_more_info: "Más información: Capítulo {chapterNumber} Manual",
  customer_sco2pes_checklist_base_data: "Datos Base",
  customer_sco2pes_checklist_bookings: "Reservas",
  customer_sco2pes_checklist_commuter_survey: "Encuesta de Desplazamiento",
  customer_sco2pes_checklist_reporting: "Reporte",

  // Suppliers
  customer_sco2pes_suppliers_transport_routes: "Rutas de transporte",
  customer_sco2pes_suppliers_supplier: "Proveedor",
  customer_sco2pes_suppliers_suppliers: "Proveedores",
  customer_sco2pes_suppliers_subtitle: "En el contexto de las emisiones de CO<sub>2</sub>, el análisis de las cadenas de suministro juega un papel importante. Por lo tanto, para capturar completamente las emisiones de CO<sub>2</sub> de una empresa, las emisiones de los proveedores deben ser incluidas en los cálculos. Posteriormente, se crean rutas de transporte con la ayuda de los datos de sus proveedores para analizar las emisiones a lo largo de su cadena de suministro ascendente y descendente.<br /><br /> Para el cálculo de las emisiones del transporte de productos / materiales / materias primas, cargue los datos de sus proveedores según la plantilla, que está almacenada en el diálogo de importación. Alternativamente, también puede ingresar sus proveedores de manera individual en el sistema. Para ello, utilice el botón \"+ PROVEEDOR\". Para facilitar la especificación de la distancia, esta se calcula automáticamente.",
  customer_sco2pes_suppliers_supplier_details: "Detalles del proveedor",
  customer_sco2pes_suppliers_transport_type: "Tipo de transporte",
  customer_sco2pes_suppliers_distance: "Distancia",
  customer_sco2pes_suppliers_transport_route_segment: "Segmento de ruta de transporte",
  customer_sco2pes_suppliers_transport_route: "Ruta de transporte",
  customer_sco2pes_suppliers_transport_route_description: "Las rutas de transporte se utilizan para calcular las emisiones del transporte. Una ruta de transporte puede consistir en varios segmentos de diferentes tipos de transporte. Ejemplo: Un producto puede ser transportado una cierta distancia desde la ubicación A por barco hasta la ubicación B y desde allí por camión hasta la ubicación C.",
  customer_sco2pes_transport_route_documentation: "Documentación / Notas",
  customer_sco2pes_suppliers_transport_route_segment_deactivated_type: "Este tipo de CO2 está desactivado. Para nuevas reservas, se debe seleccionar un tipo de CO2 diferente.",
  customer_sco2pes_suppliers_transport_route_successfully_created: "Ruta de transporte creada con éxito",
  customer_sco2pes_suppliers_transport_route_successfully_edited: "Ruta de transporte editada con éxito",
  customer_sco2pes_suppliers_transport_route_successfully_deleted: "Ruta de transporte eliminada con éxito",
  customer_sco2pes_suppliers_creditor_number: "Número de acreedor",
  customer_sco2pes_suppliers_new_supplier: "Crear proveedor",
  customer_sco2pes_suppliers_supplier_successfully_created: "Proveedor creado con éxito",
  customer_sco2pes_suppliers_supplier_successfully_edited: "Proveedor editado con éxito",
  customer_sco2pes_suppliers_supplier_successfully_deleted: "Proveedor eliminado con éxito",
  customer_sco2pes_suppliers_do_you_really_want_to_delete_the_supplier: "¿Realmente desea eliminar al proveedor?",
  customer_sco2pes_suppliers_edit_supplier: "Editar proveedor",
  customer_sco2pes_suppliers_all: "Todos",
  customer_sco2pes_suppliers_add_address: "Agregar dirección",
  customer_sco2pes_suppliers_remove_address: "Eliminar dirección",
  customer_sco2pes_suppliers_data_completed: "Datos completos",
  customer_sco2pes_suppliers_data_uncompleted: "Datos incompletos",
  customer_sco2pes_suppliers_uncompleted_warning: "Este proveedor ha sido creado automáticamente con una reserva de CO<sub>2</sub>. Complete la información faltante.",
  customer_sco2pes_suppliers_edit: "Editar",
  customer_sco2pes_suppliers_selection_rule: "Regla de selección",
  customer_sco2pes_suppliers_new_selection_rule: "Crear regla de selección",
  customer_sco2pes_suppliers_default_transport_route_for: "Ruta de transporte predeterminada para {0}",
  customer_sco2pes_transport_route_version_validity: "Validez",
  customer_sco2pes_transport_route_version: "Versión",
  customer_sco2pes_transport_route_versions: "Versiones",
  customer_sco2pes_transport_route_versions_add_version: "Agregar versión",
  customer_sco2pes_transport_route_delete_version: "Eliminar versión",
  customer_sco2pes_transport_route_distance: "Distancia",
  customer_sco2pes_transport_route_start_address_not_completed: "Datos de la dirección de inicio no completados",
  customer_sco2pes_transport_route_destination_address_not_completed: "Datos de la dirección de destino no completados",
  customer_transport_route_validation_1: "Se proporciona el nombre y tipo de la ruta de transporte",
  customer_transport_route_validation_2: "Se proporciona un valor permitido para el estado de la ruta de transporte",
  customer_transport_route_validation_3: "Se proporcionan los datos requeridos para todas las versiones (país, ciudad para todas las direcciones requeridas, modo de transporte y distancia entre todas las direcciones requeridas)",
  customer_transport_route_version_validation_1: "<i>Desde</i> y <i>Hasta</i> deben estar establecidos",
  customer_transport_route_version_validation_2: "<i>Hasta</i> debe ser posterior a <i>Desde</i>",
  customer_transport_route_add_transit: "Agregar tránsito",
  customer_transport_route_auto_calculate_distance: "Calcular distancia",
  customer_transport_route_auto_calculate_distance_tooltip: "El cálculo automático solo es posible si se establece el <i>país</i> y <i>ciudad</i> de origen y destino, así como la <i>categoría</i> y <i>tipo</i> de transporte.",
  customer_transport_route_number_of_versions: "Número de versiones",
  customer_transport_route_versions: "Versiones",
  customer_transport_route_initial_version: "Versión inicial",
  customer_transport_route_set_default: "Establecer como predeterminado",
  customer_transport_route_unset_default: "Eliminar como predeterminado",
  customer_transport_route_distance_could_not_be_calculated_title: "No se pudo calcular la distancia",
  customer_transport_route_distance_could_not_be_calculated_desc: "No se pudo calcular la distancia. Se produjeron los siguientes errores:",
  customer_sco2pes_suppliers_import_title: "Importar proveedores",
  customer_sco2pes_suppliers_import_subtitle: "Los proveedores pueden ser importados mediante un archivo de Excel. Utilice esta plantilla de Excel para la carga del archivo:",
  customer_sco2pes_suppliers_co2_relevant_description: "Si un proveedor está marcado como no relevante para CO2, solo se tendrán en cuenta las emisiones del transporte, pero se ignorarán las emisiones de los materiales entregados. Esta configuración tiene sentido si el proveedor es una filial de su empresa y si el proveedor ya tiene en cuenta las emisiones de los materiales.",
  customer_sco2pes_suppliers_upload_output_file_name: "resultados_de_subida_proveedor",
  customer_sco2pes_transport_route_reset_to_default_name: "Restablecer al nombre predeterminado",

  // Transport Routes
  customer_sco2pes_transport_routes_supplier_name: "Nombre del proveedor",
  customer_sco2pes_transport_routes_supplier_creditor_number: "Número de acreedor del proveedor",
  customer_sco2pes_transport_routes_route_name: "Nombre de la ruta de transporte",

  // Financial models
  customer_sco2pes_financial_model: "Modelo Financiero",
  customer_sco2pes_financial_models: "Modelos Financieros",
  customer_sco2pes_financial_models_creation_success_title: "Modelo financiero creado con éxito.",
  customer_sco2pes_financial_models_update_success_title: "Modelo financiero actualizado con éxito.",
  customer_sco2pes_financial_models_subtitle: "Para crear su balance de CO<sub>2</sub> basado en datos financieros, debe ingresar sus datos financieros relevantes para CO<sub>2</sub> en este paso. Para esto, necesita información de su contabilidad y contabilidad.<br/>Nuestro enfoque se basa en un modelo matemático para calcular las emisiones de las transacciones comerciales a tener en cuenta, basado en la contabilidad financiera de su empresa. Para generar los modelos matemáticos (modelo financiero), este paso identifica sus cuentas relevantes para CO<sub>2</sub> y las transacciones comerciales registradas en ellas.<br/><br/>Los modelos financieros se pueden crear para cuentas individuales utilizando el botón \"+ MODELO FINANCIERO\".<br/><br/>Tenga en cuenta que se debe crear un modelo financiero separado para cada cuenta y el procesamiento de todas las cuentas / transacciones es necesario para completar este paso. Para obtener más información, consulte las vistas detalladas respectivas.",
  customer_sco2pes_financial_model_model_info: "Información del Modelo",
  customer_sco2pes_financial_model_account_info: "Información de la Cuenta",
  customer_sco2pes_financial_model_account: "Cuenta",
  customer_sco2pes_financial_model_supplier: "Proveedor",
  customer_sco2pes_financial_model_transport_route: "Ruta de Transporte",
  customer_sco2pes_financial_models_suppliers_or_transport_routes: "Proveedores / Rutas de Transporte",
  customer_sco2pes_financial_model_percentage: "Porcentaje",
  customer_sco2pes_financial_model_co2_type_price: "Precio del Tipo de CO<sub>2</sub> (Precio por Unidad)",
  customer_sco2pes_financial_model_price_per_unit: "Precio por Unidad",
  customer_sco2pes_financial_account_number: "Número de Cuenta",
  customer_sco2pes_financial_internal_order_number: "Número de Orden Interna",
  customer_sco2pes_financial_model_versions: "Versiones",
  customer_sco2pes_financial_model_version_hint: "Para un modelo financiero, los tipos de emisiones se pueden versionar a lo largo del tiempo. Las versiones se pueden crear en la resolución de tiempo de meses. Las versiones deben cubrir todos los meses posibles, comenzando con \"Inicio\" hasta \"Fin\".",
  customer_sco2pes_financial_emission_type: "Tipos de Emisión",
  customer_sco2pes_financial_validations: "Validaciones",
  customer_sco2pes_financial_validation_1: "Código de Empresa, Nombre (en & de) y moneda especificados",
  customer_sco2pes_financial_validation_2: "Períodos de orden de versiones sin brecha y / o superposición comenzando desde 0000-01 hasta 9999-12",
  customer_sco2pes_financial_validation_3: "Cada versión tiene al menos un tipo de emisión",
  customer_sco2pes_financial_validation_4: "Cada tipo de emisión está completamente especificado (porcentaje positivo, precio positivo, un Tipo de CO<sub>2</sub> seleccionado)",
  customer_sco2pes_financial_edit_warning: "Las reservas que se han creado con esta configuración se recalcularán y actualizarán de acuerdo con la nueva configuración.",
  customer_sco2pes_financial_edit__locking_warning: "Actualizar un modelo financiero no tendrá efecto en las reservas bloqueadas y los informes finalizados.",
  customer_sco2pes_financial_model_total_percentage_over_100: "El porcentaje total es superior al 100%",
  customer_sco2pes_financial_model_co2_type_weightless: "El Tipo de CO<sub>2</sub> seleccionado no tiene peso, por lo que seleccionar un proveedor tendría un efecto en los cálculos de emisiones de CO<sub>2</sub>.",
  customer_sco2pes_financial_model_documentation_description: "Los modelos financieros que ha creado requieren una justificación de los tipos de CO2 seleccionados, así como una justificación correspondiente de la distribución porcentual elegida en caso de una auditoría. Para una documentación completa y fácilmente accesible (incluso en caso de auditoría), puede almacenarlos aquí en forma de documentos y texto libre.",
  customer_sco2pes_financial_model_delete: "Eliminar",
  customer_sco2pes_financial_model_activation_status: "Estado de activación",
  customer_sco2pes_financial_model_scope_warning: "Tenga en cuenta que al calcular las emisiones en el Alcance 1 y/o Alcance 2, también debe tener en cuenta el cálculo de las emisiones para el Alcance 3.3 para calcular por separado las emisiones de la cadena ascendente correspondiente. Para ello, agregue otra línea con la misma distribución porcentual. Tenga en cuenta que en este caso, el porcentaje total del modelo financiero puede superar el 100%.",

// CO2 Type selection
  customer_sco2pes_co2_type_selection_deactivated: "Tipo de CO<sub>2</sub> Desactivado",
  customer_sco2pes_co2_type_selection_active: "Tipo de CO<sub>2</sub> Activo",
  customer_sco2pes_co2_type_selection_open: "Selección de Tipo de CO<sub>2</sub> Abierta",
  customer_sco2pes_co2_type_selection: "Selección de Tipo de CO<sub>2</sub>",

// Company codes
  customer_sco2pes_company_codes_company_codes: "Códigos de Empresa",
  customer_sco2pes_company_codes_subtitle: "Los códigos de empresa son unidades organizativas para las cuales se puede crear una contabilidad autónoma. Las unidades pueden ser, por ejemplo: ubicaciones, plantas, centros de costos. A partir de esto, se puede derivar qué datos se pueden evaluar en el panel de control en el futuro. Esto le brinda la oportunidad de monitorear los resultados y desarrollar medidas específicas. Cada código de empresa se identifica mediante un código de reserva único. El código de reserva puede tomarse de sus sistemas contables (SAP o similar) o asignarse manualmente. Esto se puede hacer, por ejemplo, utilizando una numeración secuencial.<br/><br/>Ejemplo de código de empresa:<br/>Nombre: Sede / Código: 01",
  customer_sco2pes_company_codes_create_company_code: "Crear Código de Empresa",
  customer_sco2pes_company_codes_successfully_created: "Código de empresa creado con éxito",
  customer_sco2pes_company_codes_successfully_deleted: "Código de empresa eliminado con éxito.",
  customer_sco2pes_company_codes_started_at: "Iniciado En",
  customer_sco2pes_company_codes_description: "Descripción",
  customer_sco2pes_company_codes_configuration: "Configuración",
  customer_sco2pes_company_codes_type: "Tipo",
  customer_sco2pes_company_codes_configurations: "Configuraciones",
  customer_sco2pes_company_codes_import_title: "Importar Códigos de Empresa",
  customer_sco2pes_company_codes_import_subtitle: "Los códigos de empresa se pueden importar a través de un archivo de Excel. Use esta plantilla de Excel para cargar el archivo:",

// Commuter surveys
  customer_sco2pes_commuter_surveys_commuter_surveys: "Encuestas de Viajeros",
  customer_sco2pes_commuter_surveys_commuter_survey: "Detalles de la Encuesta de Viajeros",
  customer_sco2pes_commuter_surveys_surveys: "Encuestas de Viajeros",
  customer_sco2pes_commuter_surveys_survey: "Encuesta de Viajeros",
  customer_sco2pes_commuter_surveys_survey_gender_neutral: "Encuesta de Viajeros",
  customer_sco2pes_commuter_surveys_created_at: "Creado En",
  customer_sco2pes_commuter_surveys_year: "Año",
  customer_sco2pes_commuter_surveys_number_of_answers: "Número de respuestas",
  customer_sco2pes_commuter_surveys_open: "Abierto",
  customer_sco2pes_commuter_surveys_cancelled: "Cancelado",
  customer_sco2pes_commuter_surveys_finalized: "Finalizado",
  customer_sco2pes_commuter_surveys_max_distance_filter: "Filtro de Distancia Máxima",
  customer_sco2pes_commuter_surveys_overview_table_description: "Una vez al año, puede utilizar una encuesta a los empleados para registrar las emisiones de CO<sub>2</sub> de los empleados de su empresa que se generan al viajar al lugar de trabajo (Alcance 3.7). Después de iniciar una encuesta, recibirá un enlace que puede compartir dentro de su empresa. Todas las respuestas de los empleados se almacenan de forma anónima.<br>Puede ver el progreso de su encuesta en cualquier momento en los detalles de la encuesta. Si hay suficientes respuestas, puede finalizar la encuesta. Los resultados se extrapolan y las emisiones de CO<sub>2</sub> resultantes se asignan automáticamente al código de empresa para el cual inició la encuesta.",
  customer_sco2pes_commuter_surveys_start_dialog_description: "Después de iniciar la encuesta, se generará un enlace que puede compartir con los empleados de su empresa.",
  customer_sco2pes_commuter_surveys_included_transportation_types_description: "Decida usted mismo qué tipos de transporte desea incluir en la encuesta.<br>Por ejemplo, si su empresa proporciona un autobús de enlace para los empleados, puede crear un Tipo de CO<sub>2</sub> separado para el Alcance 3.7 en la base de datos de CO<sub>2</sub>. Esto también estará disponible aquí cuando inicie una encuesta. Por defecto, todos los Tipos de CO<sub>2</sub> 3.7 están seleccionados.",
  customer_sco2pes_commuter_surveys_co2_type: "Tipo de CO<sub>2</sub>",
  customer_sco2pes_commuter_surveys_warnings: "Advertencias",
  customer_sco2pes_commuter_surveys_filter_answers_with_warning: "Filtrar respuestas con advertencias",
  customer_sco2pes_commuter_surveys_answer: "Respuesta",
  customer_sco2pes_commuter_surveys_answers: "Respuestas",
  customer_sco2pes_commuter_surveys_co2_emissions_in_kg: "Emisiones en kg de CO<sub>2</sub>e",
  customer_sco2pes_commuter_surveys_exclude: "Excluir",
  customer_sco2pes_commuter_surveys_include: "Incluir",
  customer_sco2pes_commuter_surveys_answer_warning: "La respuesta excede la distancia máxima",
  customer_sco2pes_commuter_surveys_add_answer_to_survey: "Agregar Respuesta a la Encuesta",
  customer_sco2pes_commuter_surveys_distance: "Distancia",
  customer_sco2pes_commuter_surveys_cancel: "Cancelar Encuesta",
  customer_sco2pes_commuter_surveys_finalize: "Finalizar Encuesta",
  customer_sco2pes_commuter_surveys_cancel_commuter_survey: "Cancelar Encuesta de Viajeros",
  customer_sco2pes_commuter_surveys_finalize_commuter_survey: "Finalizar Encuesta de Viajeros",
  customer_sco2pes_commuter_surveys_cancel_commuter_survey_confirmation: "¿Realmente desea cancelar la encuesta de viajeros?<br> Los usuarios ya no podrán responder a la encuesta de viajeros. No se calculan emisiones de CO<sub>2</sub>.",
  customer_sco2pes_commuter_surveys_finalize_commuter_survey_confirmation: "¿Realmente desea finalizar la encuesta de viajeros?",
  customer_sco2pes_commuter_surveys_finalize_commuter_survey_warning: "Los usuarios ya no podrán responder a la encuesta de viajeros. Se calculan las emisiones de CO<sub>2</sub> y se extrapolan los resultados faltantes.",
  customer_sco2pes_commuter_surveys_data: "Datos",
  customer_sco2pes_commuter_surveys_submitted_answers: "Respuestas Enviadas",
  customer_sco2pes_commuter_surveys_expected_answers: "Respuestas Esperadas",
  customer_sco2pes_commuter_surveys_co2_emissions: "Emisiones de CO<sub>2</sub>",
  customer_sco2pes_commuter_surveys_submitted_co2_emissions: "Emisiones de CO<sub>2</sub> Enviadas",
  customer_sco2pes_commuter_surveys_extrapolated_co2_emissions: "Emisiones de CO<sub>2</sub> Extrapoladas",
  customer_sco2pes_commuter_surveys_commuting_type: "Tipo de Viaje",
  customer_sco2pes_commuter_surveys_distance_submitted: "Distancia Enviada",
  customer_sco2pes_commuter_surveys_distance_extrapolated: "Distancia Extrapolada",
  customer_sco2pes_commuter_surveys_public_answer_page_description: "Gracias por participar en la encuesta de viajeros de <b>{companyName}</b> para el año <b>{year}</b>.",
  customer_sco2pes_commuter_surveys_working_days_per_week: "Días laborables por semana",
  customer_sco2pes_commuter_surveys_thank_you: "¡Gracias!",
  customer_sco2pes_commuter_surveys_public_answering_message: "Gracias por responder a la encuesta de viajeros.<br>Su huella de CO<sub>2</sub> por viajar es de {0} kg en {1}.<br><br><b>Puede cerrar esta página ahora.</b>",
  customer_sco2pes_commuter_surveys_survey_is_not_available: "La encuesta no está disponible",
  customer_sco2pes_commuter_surveys_copy_to_clipboard: "Copiar al portapapeles",
  customer_sco2pes_commuter_surveys_combined_commuter_survey_warning: "Las encuestas de viajeros que forman parte de una encuesta de viajeros combinada se pueden finalizar / cancelar con la encuesta de viajeros combinada.",
  customer_sco2pes_commuter_surveys_link_copied: "Enlace Copiado",
  customer_sco2pes_commuter_surveys_create_a_commuter_survey: "Crear Encuesta de Viajeros",
  customer_sco2pes_commuter_surveys_max_distance_filter_km: "Filtro de distancia máxima (km)",
  customer_sco2pes_commuter_surveys_included_transportation_types: "Tipos de Transporte Incluidos",
  customer_sco2pes_commuter_surveys_commuter_survey_answered_warning: "La encuesta de viajeros ya ha sido respondida en este navegador.<br> Tenga en cuenta que no puede actualizar su respuesta, en su lugar se enviará una nueva respuesta.<br>Si ha notado que ha ingresado datos incorrectos, comuníquese con su administrador del sistema.",
  customer_sco2pes_commuter_surveys_continue_to_survey: "Continuar con la Encuesta",
  customer_sco2pes_commuter_surveys_created_by: "Creado por",
  customer_sco2pes_commuter_surveys_with_warning: "Con advertencia",
  customer_sco2pes_commuter_surveys_without_warning: "Sin advertencia",
  customer_sco2pes_commuter_surveys_included: "Incluido",
  customer_sco2pes_commuter_surveys_excluded: "Excluido",
  customer_sco2pes_commuter_surveys_anonymous: "Anónimo",
  customer_sco2pes_commuter_surveys_admins: "Administradores",
  customer_sco2pes_commuter_surveys_exclude_answer_confirm: "¿Realmente desea excluir la respuesta de la encuesta?",
  customer_sco2pes_commuter_surveys_include_answer_confirm: "¿Realmente desea incluir la respuesta en la encuesta?",
  customer_sco2pes_commuter_surveys_max_distance_filter_tooltip: "Distancia máxima única para viajar. Si se excede esta distancia, las respuestas se marcan automáticamente con una advertencia.",
  customer_sco2pes_commuter_surveys_go_to_global_climate: "Ir a GLOBAL CLIMATE",
  customer_sco2pes_commuter_surveys_days: "Días Laborables",
  customer_sco2pes_commuter_surveys_included_remote_working_type: "Tipo de Trabajo Remoto Incluido",
  customer_sco2pes_commuter_surveys_included_remote_working_type_description: "Decida usted mismo qué tipo de CO<sub>2</sub> de trabajo remoto desea incluir en la encuesta.<br>Puede crear un tipo de CO<sub>2</sub> propio para el trabajo remoto con el Alcance 3.7 en la base de datos de CO<sub>2</sub> y luego estará disponible aquí.",
  customer_sco2pes_commuter_surveys_remote_working_co2_type: "Tipo de CO<sub>2</sub> de Trabajo Remoto",
  customer_sco2pes_commuter_surveys_remote_working_type: "Trabajo Remoto",
  customer_sco2pes_commuter_surveys_days_submitted: "Días Enviados",
  customer_sco2pes_commuter_surveys_days_extrapolated: "Días Extrapolados",
  customer_sco2pes_commuter_surveys_on_site: "En el Sitio",
  customer_sco2pes_commuter_surveys_remote_work: "Trabajo Remoto",
  customer_sco2pes_commuter_surveys_daily_one_way_distance: "Distancia diaria de ida",
  customer_sco2pes_commuter_surveys_one_way_distance: "Distancia de ida (km)",
  customer_sco2pes_commuter_surveys_what_means_of_transportation: "¿Qué medios de transporte utiliza en promedio?",
  customer_sco2pes_commuter_surveys_transportation_type_info: "Puede usar los controles deslizantes para especificar el porcentaje de uso de su transporte.",
  customer_sco2pes_commuter_surveys_transportation_type_example_1: "15 km de distancia de ida al lugar de trabajo <ul><li>3 meses al año en bicicleta</li><li>9 meses al año en coche</li></ul> resulta en bicicleta = 25%, coche = 75%",
  customer_sco2pes_commuter_surveys_transportation_type_example_2: "30 km de distancia de ida al lugar de trabajo <ul><li>de los cuales 12 km en autobús</li><li>de los cuales 18 km en tren</li></ul> resulta en autobús = 40 %, tren = 60 %",
  customer_sco2pes_commuter_surveys_transportation_type_example_3: "10 km de distancia de ida al lugar de trabajo <ul><li>3 meses la distancia completa en un año en bicicleta</li><li>9 meses al año<ul><li>2 km a pie</li><li>8 km en autobús</li></ul></li></ul> resulta en bicicleta = 25 %, caminando = 15 %, autobús = 60 %.",
  customer_sco2pes_commuter_surveys_show_examples: "Mostrar Ejemplos",
  customer_sco2pes_commuter_surveys_examples: "Ejemplos",
  customer_sco2pes_commuter_surveys_one_way_distance_warning: "La distancia de ida debe ser mayor a 0 cuando se trabaja en el sitio.",
  customer_sco2pes_commuter_surveys_slider_values_warning: "La suma de los porcentajes de distancia debe ser 100%. Valor actual: {0}%.",
  customer_sco2pes_commuter_surveys_combined: "Combinado",
  customer_sco2pes_commuter_surveys_organisation_unit_selection: "Selección de Unidad Organizativa",
  customer_sco2pes_commuter_surveys_commuter_behaviour: "Comportamiento del Viajero",
  customer_sco2pes_commuter_surveys_welcome_text: "Bienvenido a la encuesta de viajeros de <b>{name}</b> para el <b>{year}</b>.",
  customer_sco2pes_commuter_surveys_select_organisation_unit: "Por favor seleccione la unidad organizativa a la que pertenece:",
  customer_sco2pes_commuter_surveys_select_organisation: "Seleccionar organización",
  customer_sco2pes_commuter_surveys_please_indicate: "Por favor indique su comportamiento de viaje para el año <b>{year}</b> y la unidad organizativa seleccionada <b>{unitName}</b>",
  customer_sco2pes_commuter_surveys_expand_all: "Expandir todo",
  customer_sco2pes_commuter_surveys_collapse_all: "Colapsar todo",

  // Dashboard
  customer_sco2pes_dashboard_alert_1: "¡Atención! Las emisiones se recalculan cada quince minutos.",
  customer_sco2pes_dashboard_alert_2: "Los cambios actuales en los datos base y los datos de reserva pueden no mostrarse inmediatamente en el panel de control.",
  customer_sco2pes_dashboard_description: "Descripción",
  customer_sco2pes_dashboard_category_name: "Categoría",
  customer_sco2pes_dashboard_type_name: "Tipo",
  customer_sco2pes_dashboard_total_scope: "Alcance Total",
  customer_sco2pes_dashboard_emissions: "emisiones",
  customer_sco2pes_dashboard_detailed_co2_emissions_in_kg: "Emisiones por Alcance (detallado) en kg CO<sub>2</sub>e",
  customer_sco2pes_dashboard_total_CO2_emissions_per_scope_in_kg: "Emisiones por Alcance en kg CO<sub>2</sub>e",
  customer_sco2pes_dashboard_comparison: "Comparación",
  customer_sco2pes_dashboard_co2_in_kg: "kg CO<sub>2</sub>e",
  customer_sco2pes_dashboard_kg: "kg",
  customer_sco2pes_dashboard_start_of_month: "Inicio del mes",
  customer_sco2pes_dashboard_end_of_month: "Fin del mes",
  customer_sco2pes_dashboard_supplier: "Filtro de Proveedor",
  customer_sco2pes_dashboard_company_code: "Filtro de Código de Empresa",
  customer_sco2pes_dashboard_delete_chart: "Eliminar",
  customer_sco2pes_dashboard_chart: "Gráfico",
  customer_sco2pes_dashboard_chart_select_first_1000_company_codes: "Seleccionar los Primeros 1000 Códigos de Empresa",
  customer_sco2pes_dashboard_delete_chart_question: "¿Realmente desea eliminar el gráfico?",
  customer_sco2pes_dashboard_chart_added_successfully: "Gráfico añadido con éxito",
  customer_sco2pes_dashboard_chart_updated_successfully: "Gráfico actualizado con éxito",
  customer_sco2pes_dashboard_chart_deleted_successfully: "Gráfico eliminado con éxito",
  customer_sco2pes_dashboard_chart_title: "Título",
  customer_sco2pes_dashboard_chart_legend_position: "Posición de la Leyenda",
  customer_sco2pes_dashboard_chart_legend_position_top: "Arriba",
  customer_sco2pes_dashboard_chart_legend_position_bottom: "Abajo",
  customer_sco2pes_dashboard_chart_legend_position_left: "Izquierda",
  customer_sco2pes_dashboard_chart_legend_position_right: "Derecha",
  customer_sco2pes_dashboard_chart_move_up: "Mover arriba",
  customer_sco2pes_dashboard_chart_move_down: "Mover abajo",
  customer_sco2pes_dashboard_chart_save_required: "Por favor, proporcione todos los valores",
  customer_sco2pes_dashboard_chart_type: "Tipo de gráfico",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY: "Emisiones por empresa",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY_PER_ORDER_PERIOD: "Emisiones a lo largo del tiempo",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE_CATEGORY: "Emisiones por Categoría de Alcance GHG",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE_CATEGORY_PER_ORDER_PERIOD: "Emisiones por Categoría de Alcance GHG a lo largo del tiempo",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE: "Emisiones por Alcance GHG",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE_PER_ORDER_PERIOD: "Emisiones por Alcance GHG a lo largo del tiempo",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY_CODE: "Emisiones por Código de Empresa",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY_CODE_PER_ORDER_PERIOD: "Emisiones por Código de Empresa a lo largo del tiempo",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SUPPLIER: "Emisiones por Proveedor",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SUPPLIER_PER_ORDER_PERIOD: "Emisiones por Proveedor a lo largo del tiempo",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_CATEGORY: "Emisiones por Categoría de CO2",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_CATEGORY_PER_ORDER_PERIOD: "Emisiones por Categoría de CO2 a lo largo del tiempo",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_TYPE: "Emisiones por Tipo de CO2",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_TYPE_PER_ORDER_PERIOD: "Emisiones por Tipo de CO2 a lo largo del tiempo",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_TRANSPORT_ROUTE: "Emisiones por Ruta de Transporte",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_TRANSPORT_ROUTE_PER_ORDER_PERIOD: "Emisiones por Ruta de Transporte a lo largo del tiempo",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_UNIT: "Emisiones por Unidad",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_UNIT_PER_ORDER_PERIOD: "Emisiones por Unidad a lo largo del tiempo",
  customer_sco2pes_dashboard_create_chart: "Crear gráfico",
  customer_sco2pes_dashboard_edit_chart: "Editar gráfico",
  customer_sco2pes_dashboard_all: "Todo",
  customer_sco2pes_dashboard_export_as_excel_file: "Exportar como Archivo Excel",
  customer_sco2pes_dashboard_co2_relevant_warning: "El proveedor seleccionado ha sido explícitamente excluido del informe de CO<sub>2</sub>. Esto significa que solo se tienen en cuenta las emisiones relevantes de la ruta de transporte en el informe de CO2.<br>El proveedor puede incluirse completamente en el informe de CO<sub>2</sub> marcándolo como relevante para CO<sub>2</sub>.",
  customer_sco2pes_dashboard_no_data_available: "No hay Reservas de CO<sub>2</sub> para los filtros aplicados",
  customer_sco2pes_dashboard_customizable: "Panel de Control",
  customer_sco2pes_dashboard_company: "Empresa",
  customer_sco2pes_dashboard_holdings: "Participaciones",
  customer_sco2pes_dashboard_holding_direct_indirect: "Participación (Directa/Indirecta)",
  customer_sco2pes_dashboard_no_parent_companies: "No hay empresas matrices",
  customer_sco2pes_dashboard_no_subsidiary_companies: "No hay empresas subsidiarias",
  customer_sco2pes_dashboard_subsidiary_emissions: "Emisiones reportadas por empresas subsidiarias",
  customer_sco2pes_dashboard_parent_emissions: "Emisiones reportadas a empresas matrices",
  customer_sco2pes_dashboard_not_part_of_group: "Su empresa no forma parte de un grupo empresarial, por lo tanto, no se pueden mostrar emisiones de CO<sub>2</sub>.",
  customer_sco2pes_dashboard_show_detailed_emissions: "Mostrar emisiones detalladas",
  customer_sco2pes_dashboard_dashboard_configuration: "Configuración del Panel de Control",
  customer_sco2pes_dashboard_data_series: "Series de Datos",
  customer_sco2pes_dashboard_series_name: "Nombre de la Serie",
  customer_sco2pes_dashboard_series_colour: "Color de la Serie",
  customer_sco2pes_dashboard_apply: "Aplicar",
  customer_sco2pes_company_group_export_excel: "Exportar Excel Simplificado",
  customer_sco2pes_company_group_compare_all: "Comparar Todo",
  customer_sco2pes_company_group: "Grupo Empresarial",
  customer_sco2pes_company_group_emission_details: "Detalles de Emisiones",
  customer_sco2pes_company_group_export_including_own_company: "Exportar incluyendo la propia empresa",
  customer_sco2pes_company_group_export_without_own_company: "Exportar sin la propia empresa",
  customer_sco2pes_company_group_select_all_subsidiaries: "Seleccionar Todas las Subsidiarias",
  customer_sco2pes_company_group_own_company: "Propia Empresa",
  customer_sco2pes_company_group_subsidiary: "Subsidiaria",
  customer_sco2pes_filter_results_by_category: "Filtrar resultados por categoría",
  customer_sco2pes_financial_model_amount: "Cantidad",
  customer_sco2pes_materials_scopes_info_tooltip: "Según el Protocolo GHG, hay tres alcances diferentes utilizados para categorizar y reportar las emisiones de gases de efecto invernadero de una empresa. Estos alcances se denominan Alcance 1, Alcance 2 y Alcance 3 e incluyen diferentes fuentes de emisión y áreas de control.<br>",
  customer_sco2pes_materials_scopes_info_tooltip_more_details: "Para más detalles, haga clic en el icono de información.",
  customer_sco2pes_no_emission_equivalent_data_found: "No se encontraron emisiones por unidad",
  customer_sco2pes_suppliers_transport_route_info: "Información de la Ruta de Transporte",

  // Finalised reports
  customer_sco2pes_finalised_reports_title: "Informes Finalizados",
  customer_sco2pes_finalised_reports_subtitle: "Todos los informes finalizados se enumeran aquí. Puede descargar los informes y los cálculos de emisiones.",
  customer_sco2pes_finalised_report: "Informe Finalizado",
  customer_sco2pes_finalised_reports_finalise_report: "Finalizar informe",
  customer_sco2pes_finalised_reports_successfully_created: "El informe se ha finalizado con éxito",
  customer_sco2pes_finalised_reports_start_of_month: "Inicio del mes",
  customer_sco2pes_finalised_reports_end_of_month: "Fin del mes",
  customer_sco2pes_finalised_reports_add_dialog_title: "Finalización del informe",
  customer_sco2pes_finalised_reports_add_dialog_text: `
  Por favor, seleccione un rango de fechas, y todas las reservas de unidades y financieras serán bloqueadas.
  Por lo tanto, no serán modificables. Todos los cambios realizados en CCF no tendrán efecto en el informe.
  <b>Por favor, verifique todas las reservas desde sus respectivas páginas en el software, para asegurarse de que el informe pueda ser finalizado.</b>
`,
  customer_sco2pes_finalised_reports_add_dialog_alert_title: "Nota sobre las reservas de unidades pendientes y las reservas financieras pendientes",
  customer_sco2pes_finalised_reports_add_dialog_alert_subtitle: "Por favor, asegúrese de activar todas las reservas de unidades y financieras pendientes antes de finalizar el informe, porque después de este proceso, no se permitirá activar ninguna reserva pendiente del rango de fechas seleccionado. Además, el sistema NO almacenará información relacionada con las reservas pendientes",
  customer_sco2pes_finalised_reports_add_dialog_add_emission_export_files: "Agregar informe de emisiones en Excel para el rango de fechas seleccionado a archivos adicionales (opcional)",
  customer_sco2pes_finalised_reports_add_dialog_terms: "He leído y verificado todas las reservas y deseo proceder.",
  customer_sco2pes_finalised_reports_delete_dialog_title: "Desbloquear todas las reservas",
  customer_sco2pes_finalised_reports_delete_dialog_text: "Desbloquear todas las reservas entre {from} (inicio del mes) y {until} (fin del mes).",
  customer_sco2pes_finalised_reports_delete_dialog_alert_title: `Archivos subidos`,
  customer_sco2pes_finalised_reports_delete_dialog_alert_subtitle: `Los archivos no estarán accesibles más. Por favor, asegúrese de hacer una copia de seguridad y mantenerlos en el almacenamiento compartido de la empresa.`,
  customer_sco2pes_finalised_reports_successfully_deleted: "Reservas desbloqueadas con éxito",
  customer_sco2pes_finalised_reports_details_dialog_title: "Archivos Adicionales",
  customer_sco2pes_finalised_reports_details_dialog_files: "Report Files",
  customer_sco2pes_finalised_reports_details_dialog_reporting_period: "Reporting Period",
  customer_sco2pes_finalised_reports_details_dialog_no_files: "No hay archivos adjuntos al informe",
  customer_sco2pes_finalised_reports_details_dialog_add_file: "Agregar Archivo",
  customer_sco2pes_finalised_reports_details_dialog_select_file: "SELECCIONAR ARCHIVO",
  customer_sco2pes_finalised_reports_details_dialog_upload: "SUBIR",
  customer_sco2pes_finalised_reports_details_dialog_table_title: "Título",
  customer_sco2pes_finalised_reports_details_dialog_table_created_by: "Creado por",
  customer_sco2pes_finalised_reports_details_dialog_table_created_at: "Creado en",
  customer_sco2pes_finalised_reports_details_dialog_no_file_selected: "No se ha seleccionado ningún archivo",
  customer_sco2pes_finalised_reports_details_dialog_input_label_title: "Título",
  customer_sco2pes_finalised_reports_details_dialog_upload_failed: "Error al subir el archivo del informe finalizado",
  customer_sco2pes_finalised_reports_details_dialog_upload_validation: "Por favor, proporcione tanto el título como el archivo para subir",
  customer_sco2pes_finalised_reports_details_dialog_delete_file_question: "¿Realmente desea eliminar el archivo?",

  // Documentation Library
  customer_sco2pes_documentation_library_title: "Biblioteca de Documentación",
  customer_sco2pes_documentation_library_subtitle: "Todos los documentos o archivos que se suben dentro de los datos base CCF se almacenarán en esta página y pueden ser etiquetados, seleccionados, visualizados o descargados.",
  customer_sco2pes_documentation_library_table_header_id: "ID",
  customer_sco2pes_documentation_library_table_header_title: "Título",
  customer_sco2pes_documentation_library_table_header_category: "Categoría",
  customer_sco2pes_documentation_library_table_header_usage: "Uso",
  customer_sco2pes_documentation_library_table_header_upload_date: "Fecha de Subida",
  customer_sco2pes_documentation_library_category_supplier: "Proveedor",
  customer_sco2pes_documentation_library_category_co2_type: "Tipo de CO2",
  customer_sco2pes_documentation_library_category_transport_route: "Ruta de Transporte",
  customer_sco2pes_documentation_library_category_financial_models: "Modelos Financieros",
  customer_sco2pes_documentation_library_view_usage: "Ver uso en ",
  customer_sco2pes_documentation_library_download_document: "Descargar",
};
