/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_esg_general_information: "Información General",
  customer_esg_rating_result: "Resultado de la Calificación",
  customer_esg_rating_details: "Detalles de la Calificación",
  customer_esg_scorecard_not_found: "¡Tarjeta de puntuación no encontrada!",
  customer_esg_total_score: "Total",
  customer_esg_partner_ratings: "Calificaciones de Socios",
  customer_esg_ratings_current_state: "Estado Actual",
  customer_esg_ratings_progress_information: "Información del Progreso",
  customer_esg_result: "Resultado",
  customer_esg_status_completed: "Completado",
  customer_esg_status_in_review: "En Revisión",
  customer_esg_risk_analyses: "Análisis de Riesgos ESG",
  customer_esg_risk_analyses_overview: "Visión General",
  customer_esg_risk_analyses_partners: "Socios",
  customer_esg_risk_analyses_partners_excel_export: "Exportar a Excel",
  customer_esg_risk_analyses_partners_added: "Socios añadidos",
  customer_esg_risk_analyses_dashboard: "Panel de Control",
  customer_esg_risk_analyses_dashboard_invitation_accepted: "Invitación Aceptada",
  customer_esg_risk_analyses_dashboard_open_invitations: "Invitaciones Abiertas",
  customer_esg_risk_analyses_dashboard_started_surveys: "Encuestas Iniciadas",
  customer_esg_risk_analyses_dashboard_completed_surveys: "Encuestas Completadas",
  customer_esg_risk_analyses_information: "Información",
  customer_esg_risk_analyses_evaluation_period: "Período de Evaluación",
  customer_esg_risk_analyses_deadline: "Fecha Límite",
  customer_esg_risk_analyses_deadline_defined: "Fecha límite definida",
  customer_esg_risk_analyses_invitation_message: "Mensaje de Invitación",
  customer_esg_risk_analysis_invitation_message_placeholder: "Estimados Socios, estamos realizando un Análisis de Riesgos ESG. Por favor, respondan todas las preguntas antes del {deadline}, ...",
  customer_esg_risk_analyses_invitation_message_set: "Mensaje de invitación definido",
  customer_esg_risk_analyses_topics: "Temas",
  customer_esg_risk_analyses_view_questions: "Ver Preguntas",
  customer_esg_risk_analyses_table_info: "Realice un análisis de riesgos ESG con GLOBAL CLIMATE. En el primer paso, trabajamos con usted para desarrollar un cuestionario y KPIs adaptados a sus necesidades. A través de GLOBAL SUITE, puede pedir a sus socios que realicen la calificación ESG. Nuestros analistas revisan las respuestas de sus socios y le envían los resultados.",
  customer_esg_risk_analyses_edit_title: "Editar Análisis de Riesgos ESG",
  customer_esg_risk_analyses_delete_partner_message: "¿Realmente desea eliminar al socio '{0}' del Análisis de Riesgos ESG?",
  customer_esg_risk_analyses_add_partner_title: "Agregar socio al Análisis de Riesgos ESG",
  customer_esg_risk_analyses_start_analysis_title: "Iniciar Análisis de Riesgos ESG",
  customer_esg_risk_analyses_add_partner_subtitle: "Los socios que agregue al análisis de riesgos serán notificados por correo electrónico para responder a la calificación ESG cuando inicie el análisis de riesgos.",
  customer_esg_risk_analyses_partner_multiselect: "Selección Múltiple de Socios",
  customer_esg_risk_analyses_distribution_of_grades: "Distribución de calificaciones",
  customer_esg_risk_analyses_results: "Resultados",
  customer_esg_risk_analyses_progress_statistics: "Progreso",
  customer_esg_start_esg_survey_card_title: "Iniciar Encuesta",
  customer_esg_start_new_esg_survey_alert: "Haga clic aquí para iniciar la calificación ESG",
  customer_esg_start_short_esg_survey_alert: "Haga clic aquí si ya tiene una calificación ESG para compartir",
  customer_esg_start_esg_survey_button: "Iniciar Calificación ESG",
  customer_esg_start_short_esg_survey_button: "Compartir calificación ESG existente",
  customer_esg_start_esg_survey: "Iniciar Calificación ESG",
  customer_esg_start_esg_survey_text_1: "Al responder las preguntas de la encuesta, el progreso se guardará automáticamente. Por favor, responda todas las preguntas necesarias.",
  customer_esg_start_esg_survey_text_2: "Sus respuestas serán analizadas por GLOBAL CLIMATE al final de la calificación. El resultado final se enviará automáticamente a su socio.",
  customer_esg_start_esg_survey_text_3: "Si tiene alguna pregunta, puede contactarnos a través del sistema de tickets de soporte.",
  customer_esg_start_esg_survey_text_4: "¿Desea iniciar la encuesta? Su socio <u>{partnerName}</u> será informado automáticamente de que está participando en la encuesta.",
  customer_esg_start_short_esg_survey_rating: "Compartir Calificación ESG",
  customer_esg_start_short_esg_survey_rating_text_1: "Si ya tiene un resultado de calificación ESG que desea compartir con <u>{partnerName}</u>, puede usar esto para iniciar un cuestionario abreviado en el que cargue el resultado.",
  customer_esg_start_short_esg_survey_rating_text_2: "Use esta opción solo si ya tiene un resultado de calificación ESG. De lo contrario, inicie una nueva calificación ESG a través de nuestra plataforma.",
  customer_esg_start_short_esg_survey_rating_text_3: "Al responder las preguntas de la encuesta, el progreso se guardará automáticamente. Por favor, responda todas las preguntas necesarias.",
  customer_esg_start_short_esg_survey_rating_text_4: "Si tiene alguna pregunta, puede contactarnos a través del sistema de tickets de soporte.",
  customer_esg_continue_esg_rating: "Continuar Calificación ESG",
  customer_esg_view_survey: "Ver Encuesta",

  // Rating Request
  customer_esg_rating_request_details_status_1: "Calificación iniciada",
  customer_esg_rating_request_details_status_2: "Encuesta en progreso",
  customer_esg_rating_request_details_status_3: "Respuestas en revisión",
  customer_esg_rating_request_details_status_4: "Puntuación compartida con el Socio",
  customer_esg_rating_request_submit_survey: "Enviar encuesta",
  customer_esg_rating_request_submit_description: "Después de enviar la encuesta, no podrá modificar las respuestas. ¿Desea enviar la encuesta?",
  customer_esg_rating_request_submitted_message: "Sus respuestas han sido enviadas. Recibirá un correo electrónico una vez que los resultados estén disponibles.",
  customer_esg_rating_request_complete_survey_description_2: "Para completar la encuesta, todas las preguntas deben ser respondidas sin errores de validación.",
  customer_esg_rating_request_uploaded_documents: "Documentos Subidos",
  customer_esg_rating_request_number_of_questions: "Número de preguntas",
  customer_esg_rating_request_number_of_invalid_answered_questions: "Número de preguntas con error de validación",
  customer_esg_rating_request_and_more: "Y {0} más...",
  customer_esg_rating_request_question: "Pregunta {0}",
  customer_esg_rating_request_validity: "Validez",
  customer_esg_rating_request_scorecard: "Tarjeta de puntuación",
  customer_esg_rating_request_state_invitation_pending: "Invitación Pendiente",
  customer_esg_rating_request_state_invitation_accepted: "Invitación Aceptada",
  customer_esg_rating_request_state_in_progress: "Calificación ESG en Progreso",
  customer_esg_rating_request_state_in_review: "Calificación ESG en Revisión",
  customer_esg_rating_request_state_completed: "Calificación ESG Completada",
};
