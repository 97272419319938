/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_distance_calc_title: "Entfernungsberechnung",
  system_admin_distance_calc_calculate: "Berechnen Sie Entfernungen in der Datei",
  system_admin_distance_calc_upload_title: "Berechnen Sie Entfernungen in der Datei",
  system_admin_distance_calc_upload_subtitle: "Liest die Datei und berechnet die Abstände für jede Excel-Zeile. Anschließend laden Sie eine ähnliche Datei mit Abständen herunter",
  system_admin_distance_calc_description: `
  <p>
  Laden Sie eine Liste mit Adressen hoch und das System berechnet die Entfernung für jedes Paar aus Start- und Zielort.
  <br>
  Wenn in der Spalte „Entfernung“ bereits eine Zahl eingetragen ist, lässt das System diese Excel-Zeile ganz weg.
  </p>
  <p>Das System verwendet die Google Maps API zur Berechnung von Entfernungen.</p>
  <p>Für das Unternehmen entstehen zusätzliche Kosten für jede hochgeladene Datei.</p>
  `
};
