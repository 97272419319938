import { de } from "./de";
import { en } from "./en";
import { es } from "./es";

export const messages = {
  de,
  en,
  es,
};

/**
 * Utility function to validate the structure of the messages object.
 * If message keys are missing in any language file, it will log an error.
 */
function validateMessageStructure() {
  function compareKeys(refKeys: any, otherKeys: any, refLang: any, otherLang: any) {
    const missingInOther = [...refKeys].filter((k) => !otherKeys.has(k));
    const missingInRef = [...otherKeys].filter((k) => !refKeys.has(k));

    if (missingInOther.length > 0) {
      console.error(`❌ Keys missing in ${otherLang}:`, missingInOther);
    }
    if (missingInRef.length > 0) {
      console.error(`❌ Keys missing in ${refLang}:`, missingInRef);
    }

    return missingInOther.length === 0 && missingInRef.length === 0;
  }

  function getNestedKeys(obj: any, prefix = "") {
    let keys = new Set();

    for (const key in obj) {
      const newKey = prefix ? `${prefix}.${key}` : key;
      keys.add(newKey);

      if (typeof obj[key] === "object" && obj[key] !== null) {
        const nestedKeys = getNestedKeys(obj[key], newKey);
        nestedKeys.forEach((k) => keys.add(k));
      }
    }

    return keys;
  }

  if (!messages.en || !messages.de || !messages.es) {
    console.error("Error: Missing language keys (en, de, es) in messages.json");
  }

  const enKeys = getNestedKeys(messages.en);
  const deKeys = getNestedKeys(messages.de);
  const esKeys = getNestedKeys(messages.es);

  if (!compareKeys(enKeys, deKeys, "en", "de") || !compareKeys(enKeys, esKeys, "en", "es")) {
    return;
  }

  console.log("✅ All languages have matching keys.");
}

// Run validation
validateMessageStructure();
