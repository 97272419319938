import { messages as auth } from "./public/auth/es";
import { messages as common } from "./common/es";
import { messages as systemAdmin } from "./protected/system-admin/es";
import { messages as customer } from "./protected/customer/es";
import { messages as profile } from "./protected/profile/es";
import { messages as navigation } from "./protected/navigation/es";
import { messages as components } from "./components/es";
import { messages as countries } from "./country/es";

export const es = {
  ...components,
  ...countries,
  ...auth,
  ...common,
  ...systemAdmin,
  ...customer,
  ...profile,
  ...navigation,
};
