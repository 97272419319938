/* eslint-disable */
// prettier-ignore

export const messages = {
  profile_edit_profile: "Edit Profile",
  profile_change_password: "Change Password",
  profile_current_password: "Current password",
  profile_new_password: "New password",
  profile_new_password_repeat: "Repeat new password",
  profile_password_successfully_changed: "Password changed successfully.",
  account_selection_title: "Account selection",
  account_selection_desc: "Please select the account you want to log-in:",
  profile_2fa: "Two-Factor Authentication",
  profile_activation: "Activation",
  profile_2fa_activation_description: "Two-Factor Authentication (2FA) is a security protocol that adds an extra layer of protection to online accounts. Instead of relying solely on a password, 2FA requires users to provide a second form of identification.",
  profile_2fa_activate: "Activate 2FA",
  profile_2fa_activated_successfully: "Two-Factor authentication is activated successfully.",
  profile_2fa_disable: "Disable 2FA",
  profile_2fa_reset: "Reset 2FA",
  profile_2fa_reset_description: "Resetting your Two-Factor Authentication (2FA) allows you to reinforce the security of your account without disabling the added layer of protection. Consider resetting 2FA in the following situations:",
  profile_2fa_reset_desc_list_item_1: "Lost or Inaccessible Authentication Device:",
  profile_2fa_reset_desc_list_item_1_desc: "If the device used for 2FA is lost, stolen, or inaccessible, resetting ensures that only authorized devices have access to your account.",
  profile_2fa_reset_desc_list_item_2: "Change in Authentication App:",
  profile_2fa_reset_desc_list_item_2_desc: "If you've changed your authentication app, reset 2FA to update and secure the new application linked to your account.",
  profile_2fa_please_check: "Please check your 6-digit code in your Authenticator app.",
  profile_2fa_disable_2fa: "Disable Two-Factor Authentication",
  profile_2fa_reset_2fa: "Reset Two-Factor Authentication",
  profile_2fa_reset_2fa_description: "This operation invalidates your current recovery code and 2FA device. You will be asked to start activating 2FA from beginning. Are you sure?",
  profile_2fa_enter_recovery_code: "Please enter the recovery code that you stored somewhere safe.",
  profile_2fa_input_recovery_code: "Input recovery code",
  profile_2fa_disable_confirmation: "This operation cannot be undone. Are you sure?",
  profile_2fa_disabled_successfully: "Two-Factor Authentication disabled from your profile successfully.",
  profile_2fa_method: "Method",
  profile_2fa_scan: "Scan",
  profile_2fa_verify: "Verify",
  profile_2fa_verify_description: "Enter the one-time code from your Authenticator app.",
  profile_2fa_backup_codes: "Backup Codes",
  profile_2fa_choose_verification_method: "Choose verification method",
  profile_2fa_choose_verification_method_description: "Please select the verification method below, if you don't have the app you can download it on the App store or Play store on your mobile device.",
  profile_2fa_scan_qr_code: "Scan QR Code",
  profile_2fa_scan_qr_code_description: "Use your mobile device to scan the QR Code below with your preferred authentication app. This will generate a 6-digit code for verification. If you don't have an authentication app, you can download one from your device's app store. This extra step ensures added security for your account. Thank you for prioritizing the safety of your information!",
  profile_2fa_recovery_code: "Recovery Code",
  profile_2fa_recovery_code_description: "Use this recovery code if you lose your device. Please save this code or print this recovery code and keep in a safe place, <b>you only can see this recovery code once</b>.",
  profile_2fa_recovery_code_check: "I have already copied the recovery code somewhere secure.",
  profile_2fa_recovery_code_copied_success: "Recovery Code copied!",
  profile_2fa_confirm_exit: "Are you sure want to exit from Two-Factor Authentication setup?",
};
