/* eslint-disable */
// prettier-ignore

export const messages = {
  validity_period_is_start_switch: "¿Es el inicio?",
  validity_period_start: "Inicio",
  validity_period_valid_from: "Válido desde",
  validity_period_is_end_switch: "¿Es el final?",
  validity_period_end: "Fin",
  validity_period_valid_until: "Válido hasta",
  validity_add_version: "Agregar versión",
  validity_period_initial_version: "Versión inicial",
  validity_period_set_starting_version_date: "Establecer fecha de inicio de la versión",
  validity_period_starting_from: "A partir de",
  validity_period_starting_date: "Fecha de inicio de la versión",
};
