/* eslint-disable */
// prettier-ignore

export const messages = {
  // Header and footer
  common_global_climate_platform: "PLATAFORMA GLOBAL",
  common_imprint: "Impresión",
  common_terms_of_services: "Términos y Condiciones",
  common_data_security: "Seguridad de Datos",

  // Buttons
  common_button_close: "Cerrar",
  common_button_yes: "Sí",
  common_button_continue: "Continuar",
  common_button_no: "No",
  common_button_ok: "Aceptar",
  common_button_back: "Atrás",
  common_button_submit: "Enviar",
  common_button_cancel: "Cancelar",
  common_button_download: "Descargar",
  common_button_upload: "Subir",
  common_button_create: "Crear",
  common_button_finalise: "Finalizar",
  common_button_save: "Guardar",
  common_button_add: "Agregar",
  common_button_start: "Iniciar",
  common_button_start_date: "Fecha de inicio",
  common_button_end_date: "Fecha de finalización",
  common_button_unlock: "Desbloquear",
  common_button_send: "Enviar",
  common_button_next: "Siguiente",
  common_button_import: "Importar",
  common_button_more: "Mostrar más",
  common_button_less: "Mostrar menos",
  common_button_select: "Seleccionar",
  common_button_unselect: "Deseleccionar",
  common_button_lock: "Bloquear",
  common_button_see_details: "Ver detalles",
  common_button_not_now: "Ahora no",
  common_button_details: "Detalles",
  common_button_finish: "Terminar",
  common_button_clone: "Clonar",
  common_button_export_as_excel: "Exportar como Excel",
  common_button_compare_emission: "Comparar Emisión",
  common_button_generate: "Generar",
  common_button_deactivate_selected_co2_types: "Desactivar tipos de CO<sub>2</sub> seleccionados",
  common_button_activate_selected_co2_types: "Activar tipos de CO<sub>2</sub> seleccionados",
  common_button_copy_error_message: "Copiar mensaje de error",
  common_button_open_in_new_tab: "Abrir en una nueva pestaña",
  common_button_changelog: "Registro de cambios",

  // Distance Calculator
  common_distance_calculator_origin: "Origen",
  common_distance_calculator_destination: "Destino",
  common_distance_road: "Carretera",
  common_distance_rail: "Ferrocarril",
  common_distance_sea: "Mar",
  common_distance_air: "Aire",

  // Inputs / tables
  common_general_no_data: "No se encontraron datos",
  common_general_type_for_search: "Empiece a escribir para buscar",
  common_general_email: "Correo electrónico",
  common_general_password: "Contraseña",
  common_general_company: "Empresa",
  common_general_co2_type_bucket: "Cubo de Tipo de CO<sub>2</sub>",
  common_general_editor: "Editor",
  common_general_date: "Fecha",
  common_general_state: "Estado",
  common_general_failed_login_attempts: "Intentos fallidos de inicio de sesión",
  common_general_number_of_partners: "Socios invitados",
  common_general_quantity: "Cantidad",
  common_general_status: "Estado",
  common_general_category: "Categoría",
  common_general_currency: "Moneda",
  common_general_percentage: "Porcentaje",
  common_general_account_number: "Número de cuenta",
  common_general_internal_order_number: "Número de orden interna",
  common_general_name: "Nombre",
  common_general_names: "Nombres",
  common_general_code: "Código",
  common_general_model: "Modelo",
  common_general_first_name: "Nombre",
  common_general_last_name: "Apellido",
  common_general_creator: "Creador",
  common_general_creation_date: "Fecha de creación",
  common_general_comment: "Comentario",
  common_general_address: "Dirección",
  common_general_profile: "Perfil",
  common_general_mail_language: "Idioma del correo",
  common_general_all: "Todos",
  common_general_active: "Activo",
  common_general_deactivated: "Desactivado",
  common_general_loading_data: "Cargando datos...",
  common_general_no_data_available: "No hay datos disponibles",
  common_general_not_set: "No establecido",
  common_general_company_code: "Código de la empresa",
  common_general_company_name: "Nombre de la empresa",
  common_general_company_codes: "Códigos de la empresa",
  common_general_order_period: "Período de pedido",
  common_general_period: "Período",
  common_general_scope: "Alcance",
  common_general_from: "Desde",
  common_general_until: "Hasta",
  common_general_versions_count: "Cantidad de versiones",
  common_general_started: "Iniciado",
  common_general_deadline_at: "Fecha límite en",
  common_general_evaluation_period: "Período de evaluación",
  common_general_total: "Total",
  common_general_general: "General",
  common_general_environment: "Medio ambiente",
  common_general_social: "Social",
  common_general_governance: "Gobernanza",
  common_general_year: "Año",
  common_general_validity: "Validez",
  common_general_valid_from: "Válido desde",
  common_general_valid_until: "Válido hasta",
  common_general_scorecard: "Tarjeta de puntuación",
  common_general_requested_at: "Solicitado en",
  common_general_requested_by: "Solicitado por",
  common_general_select_type: "Seleccionar tipo de CO<sub>2</sub>",
  common_general_selected_type: "Tipo de CO<sub>2</sub> seleccionado",
  common_general_select: "Seleccionar",
  common_general_created_at: "Creado en",
  common_general_type: "Tipo",
  common_general_created_by: "Creado por",
  common_general_financial_models: "Modelos financieros",
  common_general_supplier: "Proveedor",
  common_general_suppliers: "Proveedores",
  common_general_materials: "Materiales",
  common_general_amount: "Cantidad",
  common_general_row: "Fila",
  common_general_template: "Plantilla",
  common_general_icon: "Icono",
  common_general_accounts: "Cuentas",
  common_general_valid: "Válido",
  common_general_invalid: "Inválido",
  common_general_item_selected: "Elemento seleccionado",
  common_general_items_selected: "Elementos seleccionados",
  common_general_companies_selected: "Empresas seleccionadas",
  common_general_upload_file: "Subir archivo",
  common_general_upload_file_or_drag_drop_file: "Archivo (haga clic o arrastre y suelte)",
  common_general_set_starting_version_date: "Establecer fecha de inicio de la versión",
  common_file_upload_drop_file_here: "Suelte el archivo aquí",
  common_general_lock_version: "Bloquear versión",
  common_general_lock_version_recommendation: "Se recomienda bloquear una versión después de la creación de un informe para asegurar que las emisiones calculadas no cambien.",
  common_general_lock_version_question: "¿Realmente desea bloquear la versión?",
  common_general_lock_version_warning: "Una vez que una versión está bloqueada, <b>no es posible modificar los datos de esa versión</b> así como el <b>mes de inicio de la versión de seguimiento</b>.",
  common_general_locked_version_description: "Bloquear una versión solo es posible si existe una versión de seguimiento y si los datos de la versión que se va a bloquear están completos.",
  common_general_version_adding_description: "Al agregar una nueva versión, se copiarán los datos de la última versión. Los datos de una versión pueden modificarse después de agregar la nueva versión.",
  common_general_information: "Información",
  common_general_title: "Título",
  common_general_description: "Descripción",
  common_general_value: "Valor",
  common_general_deadline: "Fecha límite",
  common_general_yes: "Sí",
  common_general_no: "No",
  common_general_or: "O",
  common_general_drag_drop: "Arrastre y suelte su archivo .xlsx",
  common_general_choose_file: "Elegir archivo",
  common_general_file_selected: "Archivo seleccionado",
  common_general_row_success_import: "{0} fila(s) importada(s) con éxito",
  common_general_checks: "Verificaciones",
  common_number_of_employees: "Número de empleados",
  common_general_managed: "Gestionado",
  common_general_co2_type: "Tipo de CO<sub>2</sub>",
  common_general_financial_model: "Modelo financiero",
  common_general_geographies: "Geografías",
  common_general_documentation: "Documentación",
  common_general_documentation_description: "Para una documentación completa y fácilmente accesible (incluso en caso de auditoría), puede almacenarla aquí en forma de documentos y texto libre.",
  common_general_documentation_files: "Archivos de documentación",
  common_general_files: "Archivos",
  common_general_reset: "Restablecer",
  common_general_disable: "Desactivar",
  common_general_co2_category_deactivated: "Categoría de CO<sub>2</sub> desactivada",
  common_general_click_to_copy_id: "Haga clic para copiar ID",
  common_general_id_copied: "ID copiado!",
  common_general_co2_type_tag: "Etiqueta de tipo de CO<sub>2</sub>",
  common_general_tag: "Etiqueta",
  common_general_tags: "Tags",
  common_general_click_to_see: "Haga clic para ver detalles",
  common_general_you: "Usted",
  common_general_view_more: "Ver más",
  common_general_error_message: "Mensaje de error",
  common_general_row_number: "Número de fila",
  common_general_message: "Mensaje",
  common_general_file_processed: "El archivo ha sido procesado",
  common_general_download_results: "Descargar resultados",
  common_general_results_not_downloaded: "Resultados no descargados",
  common_general_results_not_downloaded_message: "Los resultados no fueron descargados. ¿Realmente desea continuar sin descargar los resultados de la carga?",
  common_general_show_active: "Mostrar activos",
  common_general_problems: "Problemas",
  common_general_issues: "Problemas",
  common_general_overview: "Visión general",
  common_general_external_ids: "IDs externos",
  common_general_sender_info: "Información del remitente",
  common_general_sender: "Remitente",
  common_general_recipient: "Destinatario",
  common_general_started_at: "Iniciado en",
  common_general_created_after: "Creado después de",
  common_general_created_before: "Creado antes de",
  common_general_items_per_page: "Elementos por página",
  common_general_jump_to_page: "Saltar a la página",
  common_general_drag_drop_file: "Arrastra y suelta tu archivo",
  common_general_click_here: "Haz clic aquí",

  // Not found
  common_general_errors: "Error(es)",
  common_not_found_text: "No encontrado",
  common_not_found_button: "Ir a la plataforma",
  common_not_in_use: "no utilizado",
  common_upload_size_error: "El archivo es demasiado grande. Por favor, seleccione un archivo menor de 5 Megabytes.",
  common_no_file_uploaded: "No se ha subido ningún archivo",

  // Actions
  common_general_actions: "Acciones",
  common_general_search: "Buscar",
  common_general_free_text_search: "Búsqueda de texto libre",
  common_general_edit: "Editar",
  common_general_exit: "Cerrar sesión",

  // Address
  common_industry: "Industria",
  common_street: "Calle",
  common_house_number: "Número de casa",
  common_postal_code: "Código postal",
  common_city: "Ciudad",
  common_country: "País",
  common_origin: "Origen",
  common_destination: "Destino",
  common_transit: "Parada/Tránsito",
  common_geography: "Geografía",

  // Already logged in dialog
  common_already_logged_in: "Ya ha iniciado sesión.",
  common_general_to_platform: "Ir a la plataforma",

  // Delete swal dialog
  common_duplicate_dialog_title: "Duplicar",
  common_duplicate_dialog_description: "¿Realmente desea duplicar el elemento?",
  common_duplicate_dialog_confirm_button: "Sí, duplicar!",

  // Delete swal dialog
  common_delete_dialog_title: "Eliminación",
  common_delete_dialog_description: "¿Realmente desea eliminar el elemento?",
  common_delete_dialog_confirm_button: "Eliminar",

  // Units
  common_units: "Unidades",
  common_units_unit: "Unidad",
  common_units_kg: "kg",

  // Input rules
  common_input_rule_required: "Requerido",
  common_input_rule_file_required: "Debe subir el archivo",
  common_input_rule_number_must_be_number: "La entrada debe ser un número",
  common_input_rule_number_must_be_positive: "La entrada debe ser positiva",
  common_input_rule_number_must_not_be_negative: "La entrada no debe ser negativa",
  common_input_rule_number_greater_than_equal_and_less_than_equal: "La entrada debe estar entre {0} y {1}",
  common_input_rule_number_greater_than_and_less_than_equal: "La entrada debe ser mayor que {0} y menor o igual a {1}",
  common_input_rule_space_not_allowed: "Espacio no permitido",
  common_input_rule_exceeds_maximum_length: "Excede la longitud máxima de {0} caracteres",
  common_input_rule_bellow_minimum_length: "Debe tener al menos {0} caracteres",
  common_input_rule_length_between: "Debe tener entre {0} y {1} caracteres",
  common_input_rule_at_least_one_letter: "Debe tener al menos una letra",
  common_input_rule_at_least_one_number: "Debe tener al menos un número",
  common_input_rule_at_least_one_special_character: "Debe tener al menos un carácter especial (!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~ )",
  common_input_rule_email_must_match_pattern: "Debe ser un correo electrónico",
  common_input_rule_order_period_must_match_pattern: "Debe ser de formato: YYYY-MM",
  common_input_rule_order_period_invalid_year: "El año debe ser mayor que 2000",
  common_input_rule_order_period_invalid_month: "El mes debe estar entre 01 y 12",
  common_input_rule_passwords_do_not_match: "Las contraseñas no coinciden",
  common_input_rule_version_period_pattern: "Debe ser de patrón YYYY-MM",
  common_input_rule_must_satisfy_symbol_pattern: "Debe cumplir con el patrón de símbolo",
  common_input_must_be_phone_number: "Debe ser un número de teléfono",

  // Common State
  common_enum_accepted: "Aceptado",
  common_enum_active: "Activo",
  common_enum_approved_by_customer: "Aprobado por el cliente",
  common_enum_approved_by_system_admin: "Aprobado por el administrador del sistema",
  common_enum_closed: "Cerrado",
  common_enum_company_admin: "Administrador de la empresa",
  common_enum_company_user: "Usuario",
  common_enum_completed: "Completado",
  common_enum_uncompleted: "Incompleto",
  common_enum_relevant: "Relevante",
  common_enum_not_relevant: "No relevante",
  common_enum_connected: "Conectado",
  common_enum_created: "Creado",
  common_enum_activated: "Activado",
  common_enum_deactivated: "Desactivado",
  common_enum_expired: "Expirado",
  common_enum_in_progress: "En progreso",
  common_enum_in_review: "En revisión",
  common_enum_not_started: "No iniciado",
  common_enum_not_in_use: "No en uso",
  common_enum_all_except_deactivated: "Todos excepto desactivados",
  common_enum_not_defined: "No definido",
  common_enum_on_hold: "En espera",
  common_enum_queued_for_distance_calculation: "En cola para cálculo de distancia",
  common_enum_error_in_distance_calculation: "Error en el cálculo de distancia",
  common_enum_open: "Abierto",
  common_enum_ordered: "Ordenado",
  common_enum_paid: "Pagado",
  common_enum_pending: "Pendiente",
  common_enum_released: "Liberado",
  common_enum_ccf_user: "Usuario de GLOBAL FOOTPRINT",
  common_enum_compliance_user: "Usuario de GLOBAL COMPLIANCE",
  common_enum_system_admin: "Administrador del sistema",
  common_enum_auditor: "Auditor",
  common_enum_uploaded: "Subido",
  common_enum_void: "Nulo",
  common_enum_waiting_for_customer: "Esperando al cliente",
  common_enum_waiting_for_support: "Esperando soporte",
  common_enum_cancelled: "Cancelado",
  common_enum_finalized: "Finalizado",
  common_enum_success: "Éxito",
  common_enum_failed: "Fallido",
  common_enum_ignored: "Ignorado",
  common_enum_archived: "Archivado",
  common_enum_upstream: "Río arriba",
  common_enum_downstream: "Río abajo",
  common_enum_updating: "Actualizando",
  common_enum_locked: "Bloqueado",

  // File upload
  common_upload_in_progress: "Subida en progreso... ¡Por favor, no cierre esta página!",
  common_file_not_processed: "El archivo no pudo ser procesado",
  common_file_not_processed_description: "El archivo no pudo ser procesado. Ocurrieron los siguientes errores",
  common_rows_with_errors_description: "Ocurrieron los siguientes errores de fila",
  common_file_download_sample: "Descargar archivo de muestra",
  common_data_upload: "Subida de datos",
  common_file_upload_only_single_file_supported: "Solo se puede seleccionar un archivo.",
  common_file_upload_wrong_file_type: "Tipo de archivo incorrecto. Solo se admite Excel (.xlsx).",
  common_file_upload_review_excel_data: "Por favor, revise los datos extraídos del archivo de Excel",
  common_file_could_not_processed: "El archivo no pudo ser procesado. Ocurrieron los siguientes errores",
  common_file_please_correct_the_file: "Por favor, corrija el archivo de Excel antes de volver a subirlo.",
  common_file_upload_wrong_documentation_file_type: "Wrong file type. Only .jpeg, .jpg, .png, .pdf, .docx, .doc are supported.",

  // Common texts
  common_please_wait: "Por favor, espere...",
  common_more: "más...",
  common_general_copied: "Copiado",

  // Others
  common_component_type_not_supported: "Tipo {0} no soportado.",
  common_unsaved_title: "Cambios no guardados",
  common_unsaved_message: "Hay cambios no guardados, ¿realmente desea cerrar el diálogo?",
  common_explanation_video: "Video explicativo",

  // Survey welcome
  common_survey_welcome_title: "¡Está invitado a la encuesta!",
  common_survey_welcome_already_have_an_account: "¿Ya tiene una cuenta?",
  common_survey_welcome_survey_deadline: "La fecha límite de la encuesta es:",
  common_survey_welcome_invitation_code_used: "<b>El código de invitación a la encuesta ya fue utilizado</b>, no puede registrarse más con este enlace.",
  common_survey_welcome_go_to_login: "Ir a la página de inicio de sesión",
  common_survey_welcome_content_info_1_esg: "<b>{partnerName}</b> está realizando un <b>Análisis de Riesgo ESG</b>. En el proceso de este <b>{partnerName}</b> le pide como proveedor que responda una encuesta.",
  common_survey_welcome_content_info_1_lksg: "<b>{partnerName}</b> está realizando un <b>Análisis de Riesgo LkSG</b> para cumplir con sus requisitos legales. En el proceso de este <b>{partnerName}</b> le pide como proveedor que responda una encuesta.",
  common_survey_welcome_content_info_1_fallback: "<b>{partnerName}</b> pide a todos los proveedores que respondan una encuesta.",
  common_survey_welcome_content_info_2: "Para poder participar en la encuesta, debe estar registrado o iniciar sesión en la GLOBAL SUITE.",
  common_survey_welcome_invitation_code_not_found: "¡Código de invitación no encontrado!",
  common_survey_welcome_check_invitation_code: "Verifique el código de invitación y vuelva a intentarlo.",
  common_survey_welcome_registration_completed: "Registro completado con éxito",
  common_general_licenses: "Licencias",
  common_auditing_audits: "Auditorías",
  common_news_new_update: "Nueva actualización",

  // Translated text field
  common_general_languages: "IDIOMAS",
  common_general_language_selection_info: "Debe seleccionarse al menos un idioma. Por defecto, el idioma de entrada se basa en el idioma predeterminado del usuario. Si el idioma predeterminado del usuario es inglés, la entrada será en inglés.",

  // Language names
  common_language_in: "en",
  common_language_english: "Inglés",
  common_language_german: "Alemán",
  common_language_spanish: "Español"
};
