export interface News {
  id: string;
  createdAt: number;
  publishedAt: number;
  title: {
    en: string;
    de: string;
  };
  description: {
    en: string;
    de: string;
  };
  tags: NewsTags[];
  isDraft: boolean;
}

export interface NewsInput extends Omit<News, "id" | "createdAt" | "publishedAt" | "isDraft"> {
  publishDate: string | null;
  isPublished: boolean;
}

export enum NewsTags {
  GENERAL = "GENERAL",
  MAINTENANCE = "MAINTENANCE",
  ALERT = "ALERT",
  CCF = "CCF",
  CSRD = "CSRD",
  LKSG = "LKSG",
  ESG = "ESG",
  PCF = "PCF",
  AUTOMATION_API = "AUTOMATION_API",
}

export interface NewsSummary {
  tag: NewsTags;
  count: number;
}
