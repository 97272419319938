/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_tag_definitions: "Tag-Definitionen",
  system_admin_tag_definition: "Tag-Definition",
  system_admin_tag_definition_all_except: "Alle außer",
  system_admin_tag_definition_only_for: "Nur für",
  system_admin_tag_definition_deleted: "Tag-Definition erfolgreich gelöscht",
  system_admin_tag_definition_deletion_confirmation: "Sind Sie sicher, dass Sie diese Tag-Definition dauerhaft löschen möchten?",
  system_admin_tag_definition_info: "Tag-Definitionsinformation",
  system_admin_tag_definition_name: "Tag-Definition-Name",
  system_admin_tag_definition_entities: "Entitäten",
  system_admin_tag_definition_for_entities: "Für Entitäten",
  system_admin_tag_definition_for_companies: "Für Unternehmen",
  system_admin_tag_definition_validation_regex: "Validierungsregex",
  system_admin_tag_definition_created: "Tag-Definition erfolgreich erstellt.",
  system_admin_tag_definition_updated: "Tag-Definition erfolgreich aktualisiert.",
  system_admin_tag_definition_selection: "Auswahl",
  system_admin_tag_definition_managed_description: "Wenn auf \"On\" gesetzt, haben nur Support-Benutzer oder Systemadministratoren die Möglichkeit, Tags zu erstellen. Diese Einstellung beschränkt die Tag-Erstellung auf autorisiertes Personal und gewährleistet einen kontrollierten und verwalteten Tagging-Prozess.",
  system_admin_tag_definition_entities_definition: "Dieses Feld ermöglicht es Ihnen, den anwendbaren Entitätstyp für das Tag auszuwählen.",
  system_admin_tag_definition_companies_definition: "Dieses Feld ermöglicht es Ihnen, die Unternehmen anzugeben, für die das Tag relevant ist. Es bietet Flexibilität bei der Definition des Geltungsbereichs des Tags basierend auf den ausgewählten Unternehmen."
};
