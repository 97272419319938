import Vue from "vue";
import VueI18n from "vue-i18n";
import { messages } from "./src/messages";
import { de, en, es } from "vuetify/src/locale";

Vue.use(VueI18n);

const i18nMessages = {
  en: {
    ...messages.en,
    $vuetify: en,
  },
  de: {
    ...messages.de,
    $vuetify: de,
  },
  es: {
    ...messages.es,
    $vuetify: es,
  },
};

const i18n = new VueI18n({
  locale: "de", // set locale
  fallbackLocale: "en",
  messages: i18nMessages, // set locale messages
});

export { i18n, messages };
