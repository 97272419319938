import { messages as auth } from "./public/auth/en";
import { messages as common } from "./common/en";
import { messages as systemAdmin } from "./protected/system-admin/en";
import { messages as customer } from "./protected/customer/en";
import { messages as profile } from "./protected/profile/en";
import { messages as navigation } from "./protected/navigation/en";
import { messages as components } from "./components/en";
import { messages as  countries } from "./country/en";

export const en = {
  ...components,
  ...countries,
  ...auth,
  ...common,
  ...systemAdmin,
  ...customer,
  ...profile,
  ...navigation,
};
