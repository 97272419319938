export interface Expression {
  id: string;
  name: string;
  formula: string;
}

export interface SurveyTemplate {
  id: string;
  name: string;
  description?: string;
  forms: Form[];
}

export interface Survey {
  id: string;
  state: SurveyState;
  name: string;
  description?: string;
  forms: Form[];
  answers: Answer[];
  errors: SurveyValidationError[];
  visibilities: SurveyInputVisibility[];
}

export interface SurveyValidationError {
  inputId: string;
  errors: string[];
}

export interface SurveyInputVisibility {
  inputId: string;
  visible: boolean;
}

export interface SurveyResult {
  achievedScore: number;
  maxPossibleScore: number;
  grade: SurveyGrade;
  evaluationPeriod: number;
  items: SurveyResultItem[];
  validFrom: string;
  validUntil: string;
}

export enum SurveyGrade {
  A_PLUS = "A_PLUS",
  A = "A",
  A_MINUS = "A_MINUS",
  B_PLUS = "B_PLUS",
  B = "B",
  B_MINUS = "B_MINUS",
  C_PLUS = "C_PLUS",
  C = "C",
  C_MINUS = "C_MINUS",
  D_PLUS = "D_PLUS",
  D = "D",
  D_MINUS = "D_MINUS",
}

export enum SurveyResultCategory {
  ENVIRONMENT = "ENVIRONMENT",
  SOCIAL = "SOCIAL",
  GOVERNANCE = "GOVERNANCE",
}

export interface SurveyResultItem {
  category: SurveyResultCategory;
  achievedScore: number;
  maxPossibleScore: number;
}

export interface SurveyKPI {
  id: string;
  title: string;
  value?: number;
  expression: Expression;
  calculatedValue?: number;
  errors?: string[];
}

export interface CustomerSurvey {
  id: string;
  name: string;
  state: SurveyState;
  description?: string;
  info?: string;
  forms: Form[];
  answers: Answer[];
  errors: SurveyValidationError[];
  visibilities: SurveyInputVisibility[];
  result?: SurveyResult;
  kpis?: SurveyKPI[];
}

export interface Form {
  id: string;
  title: string;
  description?: string;
  pages: Page[];
}

export interface Page {
  id: string;
  title: string;
  description?: string;
  questions: Question[];
}

export interface Question {
  id: string;
  title: string;
  description?: string;
  inputs: InputField[];
}

export interface InputField {
  id: string;
  inputType: InputFieldType;
  title?: string;
  label?: string;
  tooltip?: string;
  required: boolean;

  titleCols?: number;
  inputCols?: number;
  choiceCols?: number;

  // IF type == TEXT
  textSuggestions?: string[];
  maxLength?: number;
  textArea?: boolean;

  // IF type == NUMBER
  numberSuggestions?: number[];
  wholeNumber?: boolean;
  minValue?: number;
  maxValue?: number;

  // IF type == DATE
  dateFormat?: string;

  // IF type == FILE
  maxFiles?: number;
  mediaTypes?: string[];

  // IF type == MULTIPLE_CHOICE
  minSelections?: number;
  maxSelections?: number;
  choices?: AvailableChoice[];
}

export interface AvailableChoice {
  id: string;
  label: string;
}

export enum InputFieldType {
  NUMBER = "NUMBER",
  TEXT = "TEXT",
  DATE = "DATE",
  FILE = "FILE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
}

export interface Answer {
  inputId: string;
  // One property not null depending on input field type
  numberValue?: number;
  textValue?: string;
  dateValue?: string;
  selectedChoiceIds?: string[];
  uploadedFiles?: {
    id: string;
    name: string;
  }[];
}

export enum SurveyState {
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  COMPLETED = "COMPLETED",
}

export interface SurveyMetadata {
  title: string;
  initiator: {
    name?: string;
  };
  participant: {
    name?: string;
    email?: string;
  };
  createdAt: number;
  startedAt?: number;
  deadlineAt?: number;
}
