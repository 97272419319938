/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_survey_surveys: "Encuestas",
  system_admin_survey_details: "Detalles de la Encuesta",
  system_admin_survey_state: "Estado",
  system_admin_survey_state_in_progress: "En Progreso",
  system_admin_survey_state_in_review: "En Revisión",
  system_admin_survey_state_completed: "Completado",
  system_admin_survey_company: "Empresa",
  system_admin_survey_survey: "Encuesta",
  system_admin_survey_result: "Resultado",
  system_admin_survey_events: "Eventos",
  system_admin_survey_evaluation_period: "Período de Evaluación",
  system_admin_survey_survey_page: "Página de la Encuesta",
  system_admin_survey_finalise_survey: "Finalizar Encuesta",
  system_admin_survey_calculated_result: "El siguiente resultado ha sido calculado:",

  system_admin_survey_edit_kpi_value: "Editar Valor del KPI",
  system_admin_survey_kpi: "KPI",
  system_admin_survey_expression: "Expresión",
  system_admin_survey_expression_parameters: "Parámetros de la Expresión",
  system_admin_survey_expression_result: "Resultado de la Expresión",
  system_admin_survey_expression_errrors: "Errores",
  system_admin_survey_edit_kpi_info: "Los valores calculados pueden ser sobrescritos.",
  system_admin_survey_kpi_value: "Valor del KPI",

  system_admin_survey_edit_score_value: "Editar Valor de la Puntuación",
  system_admin_survey_category: "Categoría",
  system_admin_survey_max_score: "Puntuación Máxima",
  system_admin_survey_edit_score_info:
    "Los valores calculados pueden ser sobrescritos.<br /> Algunas puntuaciones necesitan ser aprobadas manualmente, por ejemplo, si se necesita verificar una carga de archivo.",

  system_admin_survey_customers_must_submit_answers: "Los clientes deben enviar todas las respuestas primero",

  system_admin_survey_kpis: "KPIs",
  system_admin_survey_with_errors: "Con errores",
  system_admin_survey_without_errors: "Sin errores",
  system_admin_survey_no_kpis: "Sin KPIs",
  system_admin_survey_kpis_title: "Título",
  system_admin_survey_kpis_expression: "Expresión",
  system_admin_survey_kpis_calculated_value: "Valor Calculado",
  system_admin_survey_kpis_value: "Valor",
  system_admin_survey_kpis_errors: "Errores",

  system_admin_survey_scores: "Puntuaciones",
  system_admin_survey_approved: "Aprobado",
  system_admin_survey_not_approved: "No aprobado",
  system_admin_survey_no_scores: "Sin puntuaciones",
  system_admin_survey_scores_category: "Categoría de Puntuación",
  system_admin_survey_scores_expression: "Expresión",
  system_admin_survey_scores_calculated_value: "Valor Calculado",
  system_admin_survey_scores_score: "Puntuación",
  system_admin_survey_scores_errors: "Errores",
  system_admin_survey_scores_approved: "Aprobado",
};
