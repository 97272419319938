import { messages as companies } from "./companies/es";
import { messages as co2Database } from "./co2-database/es";
import { messages as esgRatings } from "./esg-ratings/es";
import { messages as sco2pesFinancial } from "./sco2pes-financial/es";
import { messages as esgRiskAnalyses } from "./esg-risk-analyses/es";
import { messages as survey } from "./survey/es";
import { messages as users } from "./users/es";
import { messages as esgSelfAssessment } from "./esg-self-assessment/es";
import { messages as units } from "./units/es";
import { messages as integration } from "./integration/es";
import { messages as distanceCalc } from "./distance-calc/es";
import { messages as news } from "./news/es";

export const messages = {
  ...companies,
  ...co2Database,
  ...esgRatings,
  ...esgRiskAnalyses,
  ...sco2pesFinancial,
  ...survey,
  ...users,
  ...esgSelfAssessment,
  ...units,
  ...integration,
  ...distanceCalc,
  ...news,
};
