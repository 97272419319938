/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_support_support_tickets: "Tickets de Soporte",
  customer_support_new_ticket_title: "Nuevo Ticket de Soporte",
  customer_support_no_tickets_created: "No se han creado tickets de soporte",
  customer_support_search_by_search_term: "Búsqueda de texto libre",
  customer_support_ticket_number: "Número de Ticket",
  customer_support_subject: "Asunto",
  customer_support_message: "Escribe tu mensaje",
  customer_support_user: "Usuario",
  customer_support_company: "Empresa",
  customer_support_selected_file_size_must_be_less_than_1mb: "El tamaño del archivo seleccionado debe ser menor a 1 MB",
  customer_support_selected_file_size_must_be_less_than_5mb: "El tamaño del archivo seleccionado debe ser menor a 5 MB",
  customer_support_ticket_successfully_created: "Ticket creado con éxito",
  customer_support_ticket_successfully_closed: "Ticket cerrado con éxito",
  customer_support_ticket_state_successfully_updated: "Estado del ticket actualizado con éxito",
  customer_support_create_ticket_dialog_title: "Crear Ticket de Soporte",
  customer_support_new_ticket_description: "Si desea enviarnos algún problema o sugerencia, complete el formulario. Nuestro equipo de soporte le responderá lo antes posible y le informará por correo electrónico cuando su ticket haya sido respondido.",
  customer_support_create_ticket_modal_text: "Nuestro soporte le responderá lo antes posible. Le informaremos por correo electrónico cuando su ticket haya sido respondido.",
  customer_support_do_you_really_want_to_close_the_support_ticket: "¿Realmente desea cerrar el ticket de soporte?",
  customer_support_attachment_file: "Archivo Adjunto",
  customer_support_closed_the_ticket_on: "cerró el ticket el",
  customer_support_send_message: "Enviar Mensaje",
  customer_support_close_ticket: "Cerrar ticket",
  customer_support_show_all: "Mostrar Todo",
  customer_support_waiting_for_support: "Esperando soporte",
  customer_support_waiting_for_customer: "Esperando al cliente",
  customer_support_closed: "Cerrado",
  customer_support_info: "Si tiene alguna pregunta, siempre puede enviar un ticket de soporte. Estaremos encantados de procesarlo para usted durante nuestro horario de atención de lunes a viernes entre las 9:00 a.m. y las 5:00 p.m.",
  customer_support_close_ticket_description: "Si no tiene más consultas, puede cerrar el ticket.",
  customer_support_send_only: "Enviar solo",
  customer_support_send_only_desc: "Enviar mensaje sin cambiar el estado",
  customer_support_send_and_update: "Enviar y actualizar estado",
  customer_support_send_and_update_desc: 'Enviar mensaje y actualizar estado automáticamente a "esperando al cliente"',
  customer_support_send_and_close: "Enviar y cerrar ticket",
  customer_support_send_and_close_desc: "Enviar mensaje y cerrar ticket",
  customer_support_close_ticket_desc: "Cerrar ticket sin enviar un mensaje al cliente",
};
