/* eslint-disable */
// prettier-ignore

export const messages = {
  system_admin_distance_calc_title: "Distance Calculation",
  system_admin_distance_calc_calculate: "Calculate distances in file",
  system_admin_distance_calc_upload_title: "Calculate distances in file",
  system_admin_distance_calc_upload_subtitle: "Reads the file and calculates distances for each excel row and then you will download a similar file with distances",
  system_admin_distance_calc_description: `
  <p>
    Upload a list of addresses and the system will calculate the distance for each pair of origin and destination.
    <br>
    If the column "Distance" has already a number entered, then the system will omit that Excel row altogether.
  </p>
  <p>System uses Google Maps API to calculate distances.</p>
  <p>Company incurs additional costs for each file that is uploaded.</p>
  `
};
